// import React, { useState, useEffect } from "react";
// import { motion } from "framer-motion";
// import { useGlobalContext } from "../../context";
// import { Navbar, Button, Dropdown, Select, Recharts, Skeleton, Geochart } from "../../components";
// import localData from "../../localData";
// import { useLocation, useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from "uuid";
// // import AnalyticsDetails from "./analytics-details/AnalyticsDetails";
// import AnalyticsChart from "./analytics-details/AnalyticsChart";
// import AnalyticsTable from "./analytics-table/AnalyticsTable";

// export default function Analytics() {
//     const oneDayTime = 60*60*24
//     const oneWeekTime = oneDayTime * 7
//     const oneMonthTime = oneDayTime * 30
//     const oneYearTime = oneMonthTime * 12
//     useEffect(() => {
//         document.title = "Analytics";

//         const metaDescription = document.querySelector('meta[name="description"]');
//         metaDescription.content =
//             "Explore insightful analytics to track and analyze data for informed decision-making.";
//     }, []);

//     const { fade } = useGlobalContext().animations;
//     const {
//         getDisconnectAnalyticsAccount,
//         getConnectAnalyticsAccount,
//         requestedData: {
//             isAnalyticsConnected,
//             analyticsProfileData: { isFetched, accountSummaries },
//             analyticsProjectData: { mapData, tableData },
//         },
//         isLoading,
//         setIsLoading,
//         getLoadAnalyticsProfile,
//         getLoadAnalyticsProject,
//         getReturnAnalyticsProject,
//         analyticsAccount,
//         setAnalyticsAccount,
//         analyticsProperties,
//         setAnalyticsProperties,
//     } = useGlobalContext();
//     const { disconnect, link, unlink } = localData.svgs;
//     const { preloaderBig, preloader } = localData.images;

//     const [analyticsDataDate, setAnalyticsDataDate] = useState({
//         startDate: "2024-01-30",
//         endDate: "2024-02-05",
//     })
//     const [selectedProperty, setSelectedProperty] = useState(null)
//     const [chartLines, setChartLines] = useState([])
//     const [chartData, setChartData] = useState([])
//     const [dateList, setDateList] = useState([])
//     const [dateOptions, setDateOptions] = useState([
//         {
//             isActive: false,
//             title: "Last 7 Days",
//             value: "7days",
//             id: uuidv4(),
//         },
//         {
//             isActive: false,
//             title: "Last 14 Days",
//             value: "14days",
//             id: uuidv4(),
//         },
//         {
//             isActive: true,
//             title: "Last 28 Days",
//             value: "28days",
//             id: uuidv4(),
//         },
//         {
//             isActive: false,
//             title: "Last 3 Months",
//             value: "3months",
//             id: uuidv4(),
//         },
//     ])

//     useEffect(() => {
//         const dateSelected = dateOptions.find(item=> item.isActive)
//         const periodSelected = dateSelected.value
//         const newDate = getDatePeriodParams(periodSelected)
//         setAnalyticsDataDate(newDate)
//         if (isFetched) return;
//         getLoadAnalyticsProfile((accountSummaries) => {
//             const filteredAnalyticsAccount = filterAnalyticsAccount(accountSummaries);
//             setAnalyticsAccount(filteredAnalyticsAccount);

//             const filteredAnalyticsProperties = filterAnalyticsProperties(
//                 accountSummaries[0].propertySummaries
//             );
//             if(!filteredAnalyticsProperties)
//                 return;
//             setAnalyticsProperties(filteredAnalyticsProperties);
//             const activePropertyId = filteredAnalyticsProperties[0].property.replace(/\D/g, "")
//             if(activePropertyId === selectedProperty)
//                 return
//             setSelectedProperty(activePropertyId)
//             getLoadAnalyticsProject(activePropertyId, newDate);
//         });
//     }, []);

//     const filterAnalyticsAccount = (requestedAccounts) => {
//         const filteredAnalyticsAccounts = requestedAccounts.map((item, index) => {
//             return {
//                 isActive: index === 0,
//                 title: item.displayName,
//                 account: item.account,
//                 propertySummaries: item.propertySummaries,
//                 id: uuidv4(),
//             };
//         });
//         return filteredAnalyticsAccounts;
//     };

//     const filterAnalyticsProperties = (requestedProperties) => {
//         if(!requestedProperties)
//             return;
//         const filteredAnalyticsProperties = requestedProperties.map((item, index) => {
//             return {
//                 isActive: index === 0,
//                 title: item.displayName,
//                 parent: item.parent,
//                 property: item.property,
//                 propertyType: item.propertyType,
//                 id: uuidv4(),
//                 // propertyIndex: index.toString(),
//             };
//         });
//         return filteredAnalyticsProperties;
//     };

//     const analyticsAccountChange = (activeOption) => {
//         const filteredAnalyticsProperties = filterAnalyticsProperties(activeOption.propertySummaries);
//         if(!filteredAnalyticsProperties)
//             return;
//         setAnalyticsProperties(filteredAnalyticsProperties);
//         const activePropertyId = filteredAnalyticsProperties[0].property.replace(/\D/g, "")
//         if(activePropertyId === selectedProperty)
//             return
//         setSelectedProperty(activePropertyId)
//         getLoadAnalyticsProject(activePropertyId, analyticsDataDate);
//     };

//     const analyticsPropertyChange = (activeOption) => {
//         const activePropertyId = activeOption.property.replace(/\D/g, "")
//         if(activePropertyId === selectedProperty)
//             return
//         setSelectedProperty(activePropertyId)
//         getLoadAnalyticsProject(activePropertyId, analyticsDataDate);
//     };
//     const analyticsPeriodChange = (activeOption) => {
//         const newDate = getDatePeriodParams(activeOption.value)
//         if(newDate.startDate !== analyticsDataDate.startDate){
//             setAnalyticsDataDate(newDate)
//             if(selectedProperty){
//                 getLoadAnalyticsProject(selectedProperty, newDate);
//             }
//         }
//     };

//     // =====================================

//     const defaultData = [
//         ["Country", "Popularity"],
//         ["Germany", 200],
//         ["United States", 300],
//         ["Brazil", 400],
//         ["Canada", 500],
//         ["France", 600],
//         ["RU", 700],
//     ];

//     const [formatedMapData, setFormatedMapData] = useState([]);

//     const formatMapData = () => {
//         const data = mapData.rows;
//         const tempMapData = [["Country", "Popularity"]];
//         data.forEach((item) => {
//             tempMapData.push([item.dimensionValues[0].value, parseFloat(item.metricValues[0].value)]);
//         });
//         setFormatedMapData(tempMapData);
//     };

//     useEffect(() => {
//         formatMapData();
//     }, [mapData]);

//     useEffect(() => {
//         const mainAnalyticsData = tableData.rows
//         if(!mainAnalyticsData.length) return
//         runDataComparisonQueries(mainAnalyticsData)
//     }, [tableData])

//     const handleConnectAnalytics = () => {
//         window.location.href =
//             process.env.REACT_APP_CONNECT_ANALYTICS ||
//             "https://accounts.google.com/o/oauth2/v2/auth?client_id=181319171049-0tr7qeq96po3appjavs3gv96h5ciuvv8.apps.googleusercontent.com&redirect_uri=https://hub.blockchain-ads.com/analytics&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&access_type=offline&prompt=consent";
//     };

//     const runDataComparisonQueries = async (mainData) => {
//         const datesList = analyticsDateCompare(analyticsDataDate)
//         let top5Dimensions = []
//         mainData.forEach((row, i) =>{
//             if(i < 5){
//                 top5Dimensions.push(mainData[i].dimensionValues[0].value)
//             }
//         })
//         // setChartLines(top5Dimensions)
//         let chartRows = []
//         setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: true }));
//         for(let i in datesList){
//             const res = await getReturnAnalyticsProject(selectedProperty, {
//                 endDate: datesList[i].endDate,
//                 startDate: datesList[i].startDate
//             })
//             if(!res){
//                 chartRows.push([])
//                 continue
//             }
//             chartRows.push(res.tableData.rows)
//         }
//         setChartData(chartRows)
//         setDateList(datesList)
//         setChartLines(top5Dimensions)
//         setIsLoading((prev) => ({ ...prev, analyticsProjectIsLoading: false }));
//     }
//     const getDatePeriodParams = (periodSelected) => {
//         let periodTime = 0
//         if(periodSelected === "7days"){
//             periodTime = oneWeekTime * 1000
//         }else if(periodSelected === "14days"){
//             periodTime = (oneWeekTime * 2) * 1000
//         }else if(periodSelected === "28days"){
//             periodTime = (oneWeekTime * 4) * 1000
//         }else if(periodSelected === "3months"){
//             periodTime = (oneMonthTime * 3) * 1000
//         }
//         const currentDateTime  = new Date().getTime()
//         const startDateTime  = currentDateTime - periodTime
//         return {
//             startDate: getDateFromTimeStamp(startDateTime),
//             endDate: getDateFromTimeStamp(currentDateTime)
//         }
//     }
//     const analyticsDateCompare = (params) => {
//         let startDateTime = new Date(params.startDate).getTime() / 1000
//         let endDateTime = new Date(params.endDate).getTime() / 1000
//         let diff = endDateTime - startDateTime

//         let datesList = []
//         let dateDevider = 0
//         let dateRemainer = 0
//         if(diff <= oneWeekTime){
//           dateDevider = oneDayTime
//           dateRemainer = 60*60*12
//         }else if(diff > oneWeekTime && diff <= (oneWeekTime * 2)){
//           dateDevider = oneDayTime * 2
//           dateRemainer = oneDayTime
//         }else if(diff > (oneWeekTime * 2) && diff <= (oneWeekTime * 6)){
//           dateDevider = oneWeekTime
//           dateRemainer = oneDayTime * 3
//         }else if(diff > (oneWeekTime * 6) && diff <= (oneMonthTime * 3)){
//           dateDevider = oneMonthTime
//           dateRemainer = oneWeekTime
//         }
//         while(diff > dateDevider){
//             datesList.push({
//               startDate: getDateFromTimeStamp(startDateTime * 1000),
//               endDate: getDateFromTimeStamp((startDateTime + dateDevider) * 1000),
//               label: getDateLabel((startDateTime + dateDevider) * 1000, dateDevider)
//             })
//             startDateTime += dateDevider
//             diff -= dateDevider
//           }
//         if(diff > dateRemainer){
//             datesList.push({
//               startDate: getDateFromTimeStamp(startDateTime * 1000),
//               endDate: params.endDate,
//               label: getDateLabel(endDateTime * 1000, dateDevider)
//             })
//         }
//         return datesList
//     }
//     const getDateFromTimeStamp = (timestamp) => {
//         const newDate = new Date(timestamp)
//         const year = newDate.getFullYear()
//         let month = newDate.getMonth() + 1
//         let day = newDate.getDate()
//         month = month > 9 ? month : '0'+month
//         day = day > 9 ? day : '0'+day
//         return `${year}-${month }-${day}`
//     }
//     const getDateLabel = (timestamp, dateDevider) => {
//         const newDate = new Date(timestamp)
//         const year = newDate.getFullYear()
//         const month = newDate.getMonth()
//         const day = newDate.getDate()
//         const months = [
//             "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
//         ]
//         if(dateDevider <= oneYearTime){
//             return `${months[month]}. ${day}`
//         }
//         return `${months[month]}. ${day}, ${year}`
//     }

//     const handleDisconnectAnalytics = () => {
//         setAnalyticsProperties([]);
//         getDisconnectAnalyticsAccount();
//     };

//     return (
//         <motion.main {...fade} className="main analytics-page">
//             <Navbar title="Analytics" />

//             <div className="row">
//                 <h2 className="title display-2">Google Analytics Reports</h2>

//                 <Button
//                     color={`${isAnalyticsConnected ? "danger" : "success"}`}
//                     startIcon={isAnalyticsConnected ? unlink : link}
//                     variant="contained"
//                     onClick={() =>
//                         isAnalyticsConnected ? handleDisconnectAnalytics() : handleConnectAnalytics()
//                     }
//                     endIcon={
//                         isLoading.analyticsDisconnectIsLoading || isLoading.analyticsConnectIsLoading ? (
//                             <span className="endIcon">
//                                 <img src={preloaderBig} />
//                             </span>
//                         ) : null
//                     }
//                    // disabled={isLoading.analyticsDisconnectIsLoading || isLoading.analyticsConnectIsLoading}
//                   disabled={true}
//                 >
//                     {isAnalyticsConnected ? "Disconnect Google Analytics" : "Connect Google Analytics"}
//                 </Button>
//             </div>
//             <div className="row">
//                 <div className="wrapper">
//                     <Select
//                         {...{
//                             items: dateOptions,
//                             setItems: setDateOptions,
//                             placeholder: "Select Period",
//                             label: "Period",
//                             variant: "outlined",
//                             color: "dark",
//                             className: "analytics-properties",
//                             isLoading: isLoading.analyticsProfileIsLoading,
//                             callback: analyticsPeriodChange,
//                         }}
//                     />
//                     <Select
//                         {...{
//                             items: analyticsAccount,
//                             setItems: setAnalyticsAccount,
//                             placeholder: "Select Account",
//                             label: "Account",
//                             variant: "outlined",
//                             color: "dark",
//                             className: "analytics-properties",
//                             isLoading: isLoading.analyticsProfileIsLoading,
//                             callback: analyticsAccountChange,
//                         }}
//                     />
//                     <Select
//                         {...{
//                             items: analyticsProperties,
//                             setItems: setAnalyticsProperties,
//                             placeholder: "Select Property",
//                             label: "Property",
//                             variant: "outlined",
//                             color: "dark",
//                             className: "analytics-properties",
//                             isLoading: isLoading.analyticsProfileIsLoading,
//                             callback: analyticsPropertyChange,
//                         }}
//                     />
//                 </div>
//             </div>
//             <AnalyticsChart dateList={dateList} lines={chartLines} chartData={chartData}/>
//             {/* <AnalyticsDetails /> */}
//             <br />
//             <br />
//             <br />

//             <AnalyticsTable />
//             <br />
//             <br />

//             <Geochart data={formatedMapData} isLoading={isLoading.analyticsProjectIsLoading} />
//         </motion.main>
//     );
// }

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import {
  Navbar,
  Button,
  Dropdown,
  Select,
  Recharts,
  Skeleton,
  Geochart,
  Calendar,
  Modal,
} from "../../components";
import localData from "../../localData";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AnalyticsGraph from "./analytics-graph/AnalyticsGraph";
import AnalyticsTable from "./analytics-table/AnalyticsTable";
import useFormatter from "../../hooks/useFormatter";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import Joyride, { STATUS } from "react-joyride";
import { Grid } from "@mui/material";

export default function Analytics() {
  useEffect(() => {
    document.title = "Analytics";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content =
      "Explore insightful analytics to track and analyze data for informed decision-making.";
  }, []);

  const { fade } = useGlobalContext().animations;
  const {
    getDisconnectAnalyticsAccount,
    getConnectAnalyticsAccount,
    requestedData,
    setRequestedData,
    requestedData: {
      isAnalyticsConnected,
      analyticsProfileData: { isFetched, accountSummaries },
      analyticsProjectData: { mapData, tableData, transformedData },
      userProfileData: { allOrganization, currentOrganization },
    },
    isLoading,
    getLoadAnalyticsProfile,
    getLoadAnalyticsProject,
    analyticsState,
  } = useGlobalContext();
  const { formatDate, formatDateToYYYYMMDD } = useFormatter();

  // useEffect(() => {
  //     console.log(analyticsProjectData);
  // }, [analyticsProjectData]);

  const {
    analyticsAccount,
    setAnalyticsAccount,
    analyticsProperties,
    setAnalyticsProperties,
    selectionRange,
    setSelectionRange,
    activeAnalytic,
    setActiveAnalytic,
    setPage,
  } = analyticsState;
  const { disconnect, link, unlink } = localData.svgs;
  const { preloaderBig, preloader, textPreloader } = localData.images;

  const cleanReports = () => {
    setAnalyticsAccount([]);
    setAnalyticsProperties([]);
    setRequestedData((prev) => ({
      ...prev,
      analyticsProjectData: {
        mapData: {
          rows: [],
        },
        tableData: {
          rows: [],
        },
        transformedData: [],
      },
    }));
  };

  useEffect(() => {
    if (!requestedData.userProfileData.currentOrganization.id) return;
    // if (isFetched) return;
    getLoadAnalyticsProfile(
      (accountSummaries) => {
        cleanReports();
        if (!accountSummaries) return;
        const filteredAnalyticsAccount =
          filterAnalyticsAccount(accountSummaries);
        setAnalyticsAccount(filteredAnalyticsAccount);

        const filteredAnalyticsProperties = filterAnalyticsProperties(
          accountSummaries[0].propertySummaries
        );
        setAnalyticsProperties(filteredAnalyticsProperties);
        getLoadAnalyticsProject(
          filteredAnalyticsProperties[0].property.replace(/\D/g, "")
        );
      },
      () => {
        cleanReports();
      }
    );
  }, [requestedData.userProfileData.currentOrganization]);

  const filterAnalyticsAccount = (requestedAccounts) => {
    const filteredAnalyticsAccounts = requestedAccounts.map((item, index) => {
      return {
        isSelected: index === 0,
        title: item.displayName,
        account: item.account,
        propertySummaries: item.propertySummaries,
        id: uuidv4(),
      };
    });
    return filteredAnalyticsAccounts;
  };

  const filterAnalyticsProperties = (requestedProperties) => {
    const filteredAnalyticsProperties = requestedProperties.map(
      (item, index) => {
        return {
          isSelected: index === 0,
          title: item.displayName,
          parent: item.parent,
          property: item.property,
          propertyType: item.propertyType,
          id: uuidv4(),
          // propertyIndex: index.toString(),
        };
      }
    );
    return filteredAnalyticsProperties;
  };

  const analyticsAccountChange = (activeOption) => {
    const filteredAnalyticsProperties = filterAnalyticsProperties(
      activeOption.propertySummaries
    );
    setAnalyticsProperties(filteredAnalyticsProperties);
    getLoadAnalyticsProject(
      filteredAnalyticsProperties[0].property.replace(/\D/g, "")
    );
  };

  const analyticsPropertyChange = (activeOption) => {
    getLoadAnalyticsProject(activeOption.property.replace(/\D/g, ""));
  };

  // =====================================

  const defaultData = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
  ];

  const [formatedMapData, setFormatedMapData] = useState([]);

  const formatMapData = () => {
    const data = mapData.rows;
    const tempMapData = [["Country", "Users"]];
    data.forEach((item) => {
      tempMapData.push([
        item.dimensionValues[0].value,
        parseFloat(item.metricValues[0].value),
      ]);
    });
    setFormatedMapData(tempMapData);
  };

  useEffect(() => {
    formatMapData();
  }, [mapData]);

  const handleConnectAnalytics = () => {
    window.location.href =
      // process.env.REACT_APP_CONNECT_ANALYTICS ||
      // "https://accounts.google.com/o/oauth2/v2/auth?client_id=181319171049-t8050hd7pu5i5gg9c6bgm9401v5gsesl.apps.googleusercontent.com&redirect_uri=https://blockchain-ads.netlify.app/analytics&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&access_type=offline&prompt=consent"; //Netlify test
      "https://accounts.google.com/o/oauth2/v2/auth?client_id=181319171049-t8050hd7pu5i5gg9c6bgm9401v5gsesl.apps.googleusercontent.com&redirect_uri=https://hub.blockchain-ads.com/analytics&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&response_type=code&access_type=offline&prompt=consent"; // hub.blockchain updated url
  };

  const handleDisconnectAnalytics = () => {
    setAnalyticsProperties([]);
    getDisconnectAnalyticsAccount();
  };

  const handleCalendar = (range) => {
    const { startDate, endDate } = range;
    localStorage.setItem(
      "analyticsSelectionRange",
      JSON.stringify({
        startDate: formatDateToYYYYMMDD(startDate),
        endDate: formatDateToYYYYMMDD(endDate),
      })
    );
    setSelectionRange(range);
    getLoadAnalyticsProject(
      analyticsProperties
        .find((item) => item.isSelected)
        .property.replace(/\D/g, "")
    );
    setPage(1);
  };
  const [displayDisclosureModal, setDisplayDisclosureModal] = useState("none");

  const [isOwnOrganization, setIsOwnOrganization] = useState(false);

  useEffect(() => {
    let isOwn = false;
    if (allOrganization.length) {
      isOwn =
        allOrganization.find((item) => item.id == currentOrganization.id)
          .adminID === requestedData.userProfileData.userId;
    }
    setIsOwnOrganization(isOwn);
  }, [allOrganization]);

  return (
    <motion.main {...fade} className="main analytics-page">
      <AnalyticsJoyride />
      {/* <Navbar title="Analytics" /> */}

      <div className="analytics-page-header">
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Grid sx={{display: "flex", flexWrap: "wrap"}}>
        <Grid sx={{display: "flex", gap: "10px", flexWrap: "wrap"}}>
          <Grid sx={{display: "flex", gap: "10px", flexWrap: "wrap"}} id="analytics-tutorial-3">
            <Select
              {...{
                items: analyticsAccount,
                setItems: setAnalyticsAccount,
                placeholder: "Select Account",
                label: "Account",
                variant: "outlined",
                color: "dark",
                className: "analytics-properties",
                isLoading: isLoading.analyticsProfileIsLoading,
                callback: analyticsAccountChange,
              }}
            />
            <Select
              {...{
                items: analyticsProperties,
                setItems: setAnalyticsProperties,
                placeholder: "Select Property",
                label: "Property",
                variant: "outlined",
                color: "dark",
                className: "analytics-properties",
                isLoading: isLoading.analyticsProfileIsLoading,
                callback: analyticsPropertyChange,
              }}
            />
          </Grid>
          <Calendar
            className="analytics-calendar"
            _selectionRange={selectionRange}
            callback={handleCalendar}
          />
          </Grid>
          </Grid>
          <Grid>
            <span
              data-tooltip-id="tooltip-analytics"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <span>Your account only.</span>
              )}
            >
              <Button
                id="analytics-tutorial-2"
                color={`${isAnalyticsConnected ? "danger" : "success"}`}
                startIcon={isAnalyticsConnected ? unlink : link}
                variant="contained"
                onClick={() =>
                  isAnalyticsConnected
                    ? handleDisconnectAnalytics()
                    : handleConnectAnalytics()
                }
                endIcon={
                  isLoading.analyticsDisconnectIsLoading ||
                  isLoading.analyticsConnectIsLoading ? (
                    <span className="endIcon">
                      <img src={preloaderBig} />
                    </span>
                  ) : null
                }
                disabled={
                  isLoading.analyticsDisconnectIsLoading ||
                  isLoading.analyticsConnectIsLoading
                }
              >
                {isAnalyticsConnected
                  ? "Disconnect Google Analytics"
                  : "Connect Google Analytics"}
              </Button>
              <Modal
          Toggler={({ onClick }) => (
            // <Button
            //     name="Disclosure"
            //     onClick={onClick}
            //     style={{ display: "block", marginLeft: "auto" }}
            //     variant="contained"
            //     className="disclosure-button"
            //     color='light'
            // />
            // <div
            //     className="link link-light disclosure-btn"
            //     onClick={onClick}
            //     style={{ marginLeft: "auto" }}
            // >
            //     DISCLOSURE
            // </div>
            <div className="link link-primary disclosure-btn" onClick={onClick}>
              *Disclosure
            </div>
          )}
          Child={DiscloserModalChild}
          // modalCloseCallback={modalCloseCallback}
          display={displayDisclosureModal}
          setDisplay={setDisplayDisclosureModal}
          className="modal-dialog-centered modal-md disclosure-modal"
        />
            </span>
          </Grid>
        </Grid>
      </div>

      <div className="analytics-page-body">
        <div className="row">
          <h2 className="title display-2">Google Analytics Reports</h2>

          {!isOwnOrganization && (
            <Tooltip id="tooltip-analytics" className="custom-tooltip" />
          )}
        </div>
        {/* <h2
          // className="analytics-title"
          className={`analytics-title ${
            currentOrganization.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
              ? "text-purple"
              : isOwnOrganization
              ? "text-success"
              : ""
          }`}
        >
          {isLoading.userProfileIsLoading ? (
            <img src={textPreloader} />
          ) : (
            currentOrganization.name
          )}
        </h2> */}
        <br />

        <AnalyticsGraph />
        <br />
        <br />
        <br />
        <br />
        <br />

        <AnalyticsTable />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Geochart
          data={formatedMapData}
          isLoading={isLoading.analyticsProjectIsLoading}
        />
      </div>
    </motion.main>
  );
}

const DiscloserModalChild = ({ setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const { close } = localData.svgs;

  return (
    <>
      <div className="modal-header">
        <Button
          className="btn-close"
          variant="circle"
          color="light"
          size="sm"
          icon={close}
          onClick={closeModal}
        />
      </div>
      <div className="modal-body" style={{ padding: "0px 1rem 20px" }}>
        <h5 className="modal-title">Disclosure</h5>
        <p className="modal-text text-style-1 text-center">
          hub.blockchain-ads use and transfer of information received from
          Google APIs to any other app will add here to{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            target="_blank"
            className="link link-primary"
          >
            Google API Services User Data Policy
          </a>{" "}
          , including the Limited Use requirements.
        </p>
      </div>
    </>
  );
};

const AnalyticsJoyride = () => {
  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Connect the Google Analytics 4 account to view website analytics data
          directly on the platform.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Connecting Google Analytics",
    },

    {
      content: (
        <h2 className="joyride-subtitle">
          Click here to connect the account. You will be prompted to log in to
          Google.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#analytics-tutorial-2",
      title: "Starting the Connection",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Select the appropriate account and property to view data.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#analytics-tutorial-3",
      title: "Selecting Account and Property",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          Google Analytics data will appear in the graphs and tables below after
          successful connection.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Viewing Analytics Data",
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "analyticsTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("analyticsTutorial"))
      return;

    setTimeout(() => {
      setSteps((prev) => ({ ...prev, run: true }));
    }, 500);
  }, []);

  return (
    <Joyride
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
        buttonNext: {},

        buttonBack: {},
        buttonClose: {},
      }}
    />
  );
};

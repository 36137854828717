import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Field, Loading } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';

export default function Members({}) {
  const {
    getInviteUser,
    getInvitedUsers,
    isLoading,
    requestedData,
    successAlert,
    errorAlert,
    invitedUsers,
    deleteOrgInvite,
    getOrgMembersRequest,
    organizationMembers
  } = useGlobalContext();
  const { preloaderBig } = localData.images;

  const [state, setState] = useState({
    inviteeEmail: "",
  });

  console.log(organizationMembers, "invitedUsers");

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getInviteUser(state.inviteeEmail, () => setState({ inviteeEmail: "" }));
    // getInviteUser({...state,profile_image: profileImage})
  };

  useEffect(() => {
    if (requestedData.userProfileData.currentOrganization.id) {
        getOrgMembersRequest();
    }
  }, [requestedData.userProfileData.currentOrganization.id]);

  const handleRemoveInvite = (userEmail) => {
      console.log(userEmail, "handleRemoveInvite");
      deleteOrgInvite(userEmail)
  }

  return (
    <div className="invite">
        <div className="form-header">
          <h2 className="form-title">Organization Members</h2>
        </div>
        <div className="form-header">
          <br />
          {organizationMembers.isLoading ? <CircularProgress color="success" /> :  <TableContainer component={Paper} sx={{ maxWidth: 750 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Organzation Member ID</TableCell>
                  <TableCell>Organzation Member Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(organizationMembers.data || []).map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.memberId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.memberName}
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      <Button
                          className="submit-btn"
                          name="remove"
                          color="danger"
                          variant="contained"
                          size="sm"
                          style={{borderRadius: "20px", marginTop: "0"}}
                          onClick={() => handleRemoveInvite(item)}
                        />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> }
         
        </div>
        <br />
        <br />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import localData from "../../../localData";
import {
  Button,
} from "../../../components";
import { BarChart, LineChart } from "../../../components";
import { useGlobalContext } from "../../../context";
import { Grid } from "@mui/material";
import { Card, CardContent, Typography, Box, Icon } from "@mui/material";
import useFormatter from "../../../hooks/useFormatter";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import PaidIcon from "@mui/icons-material/Paid";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

const activeData = {
  clicks: {
    isActive: true,
    propertyName: "clicks",
    displayName: "clicks",
    normalizeName: "normalizeClicks",
    color: "#1a73e8",
    colorDefault: "#1a73e8",
    isRightAxis: "right"
  },
  impressions: {
    isActive: true,
    propertyName: "impressions",
    displayName: "impressions",
    normalizeName: "normalizeImpressions",
    color: "#d93025",
    colorDefault: "#d93025",
    isRightAxis: "right"
  },
  amount_spent: {
    isActive: false,
    propertyName: "amount_spent",
    displayName: "ad spend",
    normalizeName: "normalizeAmount_spent",
    color: "#f9ab00",
    colorDefault: "#f9ab00",
    isRightAxis: "right"
  },
  conversions: {
    isActive: false, // Active card
    propertyName: "conversions",
    displayName: "conversions",
    normalizeName: "normalizeConversions",
    color: "#1e8e3e",
    colorDefault: "#1e8e3e",
    isRightAxis: "right"
  },
}


const hoverData = {
  impressions: {
    isActive: false,
    propertyName: "impressions",
    displayName: "impressions",
    normalizeName: "normalizeImpressions",
    color: "lightgray",
    isRightAxis: false
  },
  amount_spent: {
    isActive: false,
    propertyName: "amount_spent",
    displayName: "ad spend",
    normalizeName: "normalizeAmount_spent",
    color: "lightgray",
    isRightAxis: true
  },
  clicks: {
    isActive: false,
    propertyName: "clicks",
    displayName: "clicks",
    normalizeName: "normalizeClicks",
    color: "lightgray",
    isRightAxis: false
  },
  conversions: {
    isActive: true, // Active card
    propertyName: "conversions",
    displayName: "conversions",
    normalizeName: "normalizeConversions",
    color: "lightgray",
    isRightAxis: false
  },
}

export default function CampaignsGraph() {
  const {
    requestedData: {
      userProfileData,
      campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
    },
    isLoading: { campaignDataIsLoading, userProfileIsLoading },
    formatNumber,
    getDateName,
    activeCampaign,
    markedCampaigns,
    isDarkModeEnabled,
    campaignsState,
  } = useGlobalContext();
  const { campaignSelectionRange } = campaignsState;

  const { formatCurrency, removeAlias, formatDateToMMDD, addCommas } =
    useFormatter();

  const { user, textPreloader } = localData.images;
  const { eye, transactions, coins, lineChart, barChart } = localData.svgs;

  const [graphBtns, setGraphBtns] = useState(activeData);  

  const handleActiveChartBtn = (e) => {
    const name = e.currentTarget.dataset.name;    

    setGraphBtns((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        isActive: !prevState[name].isActive,
        isHoverActive: true,
        hoverEnabled: false,
        color: prevState[name].colorDefault, // Reset to default color when inactive
      },
    }));
  };
  
  const handleMouseHover = (propertyName, isHovering) => {
    setGraphBtns((prevState) => {
      const property = prevState[propertyName];
  
      if (property.isActive) {
        return prevState;
      }

      if(isHovering){
        return {
          ...prevState,
          [propertyName]: {
            ...property,
            color: "lightgray",
            isActive: true,
            isHoverActive: false
          },
        };    
      }
    });
  };
  

  const handleMouseLeave = (propertyName, isActive) => {
    setTimeout(() => {
      if(!isActive){
        setGraphBtns((prevState) => {
          const property = prevState[propertyName];
            return {
              ...prevState,
              [propertyName]: {
                ...property,
                color: "red",
                isActive: false
              },
            };    
        });
      } else {
        setGraphBtns((prevState) => {
            return {
              ...prevState
            };    
        });
      }
    }, 500)
  }

  const defaultTotal = {
    impressions: 0,
    amount_spent: 0,
    clicks: 0,
    CPM: 0,
    CPC: 0,
  };
  const [total, setTotal] = useState(defaultTotal);
  const [filteredGraphData, setFilteredGraphData] = useState([]);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filterMultiGraphData = () => {
    if (!currentOrganizationCampaigns.length) {
      setFilteredGraphData([]);
      setTotal(defaultTotal);
      return;
    }
    if (!markedCampaigns.length) {
      setFilteredGraphData([]);
      setTotal(defaultTotal);
      return;
    }

    const { startDate, endDate } = campaignSelectionRange;

    const array = [];
    const object = {
      impressions: 0,
      amount_spent: 0,
      clicks: 0,
      CPM: 0,
      CPC: 0,
      conversions: 0,
    };

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const dateString = formatDate(date);
      const aggregatedData = {
        name: getDateName({ date: dateString }),
        impressions: 0,
        amount_spent: 0,
        clicks: 0,
        conversions: 0,
        CPM: 0,
        CPC: 0,
        AllCPM: 0,
        AllCPC: 0,
      };
      let dataFound = false;
      markedCampaigns.forEach((campaign) => {
        const { progressionData } = campaign;
        
        if (progressionData && progressionData[dateString]) {
          dataFound = true;
          const dataForDate = progressionData[dateString];          
          if (graphBtns.impressions.isActive)
            aggregatedData.impressions += dataForDate.impressions || 0;
          if (graphBtns.amount_spent.isActive)
            aggregatedData.amount_spent += dataForDate.amount_spent || 0;
          if (graphBtns.clicks.isActive)
            aggregatedData.clicks += dataForDate.clicks || 0;
          if (graphBtns.conversions.isActive)
            aggregatedData.conversions += dataForDate.conversions || 0;
          // if (graphBtns.CPM.isActive) aggregatedData.CPM += dataForDate.cpm || 0;
          // if (graphBtns.CPC.isActive) aggregatedData.CPC += dataForDate.cpc || 0;

          object.impressions += dataForDate.impressions;
          object.amount_spent += dataForDate.amount_spent;
          object.clicks += dataForDate.clicks;
          object.conversions += dataForDate.conversions;
          // object.CPM += dataForDate.cpm;
          // object.CPC += dataForDate.cpc;
        }
      });

      if (dataFound) {
        // if (graphBtns.CPM.isActive) {
        //     aggregatedData.AllCPM =
        //         (parseInt(aggregatedData.amount_spent) / aggregatedData.impressions) * 1000 || 0;
        // }
        // if (graphBtns.CPC.isActive) {
        //     aggregatedData.AllCPC = parseInt(aggregatedData.amount_spent) / aggregatedData.clicks || 0;
        // }
                
        const normalizer = () => {
          aggregatedData.normalizeImpressions = aggregatedData.impressions 
          aggregatedData.normalizeAmount_spent = aggregatedData.amount_spent
          aggregatedData.normalizeClicks = aggregatedData.clicks
          aggregatedData.normalizeConversions = aggregatedData.conversions;
          aggregatedData.normalizeCPM = Math.log10(aggregatedData.CPM + 1);
          aggregatedData.normalizeCPC = Math.log10(aggregatedData.CPC + 1);
        };
        normalizer();        
        array.push(aggregatedData);
      } else {
        const emptyData = {
          name: formatDateToMMDD(date),
          empty: 0,
        };
        array.push(emptyData);
      }
    }
    setTotal(object);
    setFilteredGraphData(array);
  };

  // useEffect(() => {
  //     if (!currentOrganizationCampaigns.length) return;
  //     if (!activeCampaign) return;
  //     const tempActiveGraphCampaign = currentOrganizationCampaigns.find(
  //         (item) => item.id === activeCampaign.id
  //     );
  //     setActiveGraphCampaign(tempActiveGraphCampaign);
  // }, [activeCampaign, currentOrganizationCampaigns]);

  useEffect(() => {
    // if (!markedCampaigns.length) {
    //     filterGraphData();
    // } else {

    filterMultiGraphData();
    // }
  }, [
    markedCampaigns,
    activeCampaign,
    graphBtns,
    currentOrganizationCampaigns,
  ]);

  const [isLineChartActive, setIsLineChartActive] = useState(true);

  const getDisplayText = (propertyName) => {
    switch (propertyName) {
      case "clicks":
        return "Clicks";
      case "amount_spent":
        return "Ad Spend";
      case "impressions":
        return "Impressions";
      case "conversions":
        return "Conversions";
      default:
        return "Unknown Metric";
    }
  };

  const getIcon = (propertyName) => {
    switch (propertyName) {
      case "clicks":
        return <AdsClickIcon sx={{ color: "#4caf50", fontSize: 28 }} />;
      case "amount_spent":
        return <PaidIcon sx={{ color: "#4caf50", fontSize: 28 }} />;
      case "impressions":
        return <QueryStatsIcon sx={{ color: "#4caf50", fontSize: 28 }} />;
      case "conversions":
        return (
          <ConnectWithoutContactIcon sx={{ color: "#4caf50", fontSize: 28 }} />
        );
      default:
        return "Unknown Metric";
    }
  };

  
  const getValue = (propertyName) => {
    switch (propertyName) {
      case "clicks":
        return addCommas(total?.clicks?.toFixed(2)) || "0";
      case "amount_spent":
        return formatCurrency(total?.amount_spent, 2);
      case "impressions":
        return addCommas(Math.round(total?.impressions)) || "0";
      case "conversions":
        return total?.conversions || "0";
      default:
        return "Unknown Metric";
    }
  };

  const [isHover, setIsHover] = useState(false)

  return (
    <div className="campaigns-graph shadow">
      <Grid container spacing={1} sx={{ padding: "0", margin: "0" }}>
        {Object.values(graphBtns).map((item, index) => {
          return (
            <>
              <Grid className="graph-card-container">
                <Card
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 2,
                    borderRadius: index === 0 ? "20px 0 0 0" : 0,
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    border: !item.isActive && "1px solid lightgray",
                    background: item.isActive && `${item.color}`,
                    color: item.isActive && `#ffff`,
                    height: "110px",
                    width: "180px"
                  }}
                  className="graph-card"
                  key={index}
                  onClick={handleActiveChartBtn}
                  data-name={item.propertyName}
                  // onMouseEnter={() => handleMouseHover(item.propertyName)}
                  // onMouseLeave={() => handleMouseLeave(item.propertyName)}
                >
                  <CardContent sx={{ padding: 0 }}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      gutterBottom
                      sx={{
                        color: item.isActive && `#ffff`,
                      }}
                    >
                      {getDisplayText(item.propertyName)}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {campaignDataIsLoading ? (
                        <img src={textPreloader} className="text-preloader" />
                      ) : (
                        getValue(item.propertyName, total)
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          );
        })}
      </Grid>
      <div className="campaigns-graph-inner">
        <div className={`chart-wrapper `}>
          {campaignDataIsLoading && (
            <div className="loader">
              <div className="message">Loading...</div>
              <img src={textPreloader} />
            </div>
          )}
          <div className="row row-bottom">
            <div className="col col-left btn-group">
              <Button
                variant="circle"
                icon={lineChart}
                size="sm"
                color={isLineChartActive ? "dark" : "light"}
                onClick={() => setIsLineChartActive(true)}
              />
              <Button
                variant="circle"
                icon={barChart}
                size="sm"
                color={isLineChartActive ? "light" : "dark"}
                onClick={() => setIsLineChartActive(false)}
              />
            </div>
          </div>
          {/* {isLineChartActive ? ( */}
          <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <LineChart
            isTooltipFormatted={markedCampaigns.length > 1 ? true : false}
            data={filteredGraphData}
            // defaultData={[
            //     {
            //         empty: 0,
            //         name: "",
            //     },
            //     // {
            //     //     empty: 0,
            //     //     name: "Campaign progression is empty",
            //     // },
            // ]}
            lines={Object.values(graphBtns)}
            isLoading={campaignDataIsLoading}
            isHover={isHover}
          />
          </div>
          {/* ) : (
                        <BarChart
                            data={filteredGraphData}
                            defaultData={[
                                {
                                    empty: 0,
                                    name: "Campaign progression is empty",
                                },
                            ]}
                            bars={Object.values(graphBtns)}
                            isLoading={campaignDataIsLoading}
                        />
                    )} */}
        </div>
      </div>
    </div>
  );
}

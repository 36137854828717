export const PRProductsCrypto = [
  {
    media: "Tapchibitcoin",
    monthlyTraffic: "",
    reach: 1500000,
    price: 3125,
    isSelected: false,
    id: "tapchibitcoin",
  },
  {
    media: "Coincodex",
    monthlyTraffic: "",
    reach: 1500000,
    price: 3125,
    isSelected: false,
    id: "coincodex",
  },
  {
    media: "Gritdaily",
    monthlyTraffic: "",
    reach: 1500000,
    price: 2850,
    isSelected: false,
    id: "gritdaily",
  },
  {
    media: "Crypto.news",
    monthlyTraffic: "",
    reach: 1500000,
    price: 2500,
    isSelected: false,
    id: "crypto.news",
  },
  {
    media: "Cryptonewsflash",
    monthlyTraffic: "",
    reach: 1500000,
    price: 2125,
    isSelected: false,
    id: "cryptonewsflash",
  },
  {
    media: "BSC News",
    monthlyTraffic: "",
    reach: 1500000,
    price: 1875,
    isSelected: false,
    id: "bsc-news",
  },
  {
    media: "BlockchainMagazine",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1750,
    isSelected: false,
    id: "blockchainmagazine",
  },
  {
    media: "Cryptowisser",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1700,
    isSelected: false,
    id: "cryptowisser",
  },
  {
    media: "Blockchain.news",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1625,
    isSelected: false,
    id: "blockchain.news",
  },
  {
    media: "alexablockchain",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1500,
    isSelected: false,
    id: "alexablockchain",
  },
  {
    media: "Digital Coin Price",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1450,
    isSelected: false,
    id: "digital-coin-price",
  },
  {
    media: "Telegaon",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1450,
    isSelected: false,
    id: "telegaon",
  },
  {
    media: "Coinwire",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1375,
    isSelected: false,
    id: "coinwire",
  },
  {
    media: "Timesofcasino",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1375,
    isSelected: false,
    id: "timesofcasino",
  },
  {
    media: "Tintucbitcoin",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1300,
    isSelected: false,
    id: "tintucbitcoin",
  },
  {
    media: "Coincu",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1200,
    isSelected: false,
    id: "coincu",
  },
  {
    media: "WalletInvestor",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1150,
    isSelected: false,
    id: "walletinvestor",
  },
  {
    media: "Bitcoinik",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1150,
    isSelected: false,
    id: "bitcoinik",
  },
  {
    media: "0xzx",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1050,
    isSelected: false,
    id: "0xzx",
  },
  {
    media: "TheCoinRepublic",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1000,
    isSelected: false,
    id: "thecoinrepublic",
  },
  {
    media: "ETHNews",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1000,
    isSelected: false,
    id: "ethnews",
  },
  {
    media: "Coinguitar",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1000,
    isSelected: false,
    id: "coinguitar",
  },
  {
    media: "virlan",
    monthlyTraffic: "",
    reach: 1000000,
    price: 1000,
    isSelected: false,
    id: "virlan",
  },
  {
    media: "Bitcoininsider",
    monthlyTraffic: "",
    reach: 500000,
    price: 875,
    isSelected: false,
    id: "bitcoininsider",
  },
  {
    media: "Use the Bitcoin",
    monthlyTraffic: "",
    reach: 500000,
    price: 875,
    isSelected: false,
    id: "use-the-bitcoin",
  },
  {
    media: "BitcoinNews.ch",
    monthlyTraffic: "",
    reach: 500000,
    price: 875,
    isSelected: false,
    id: "bitcoinnews.ch",
  },
  {
    media: "TokenTroop",
    monthlyTraffic: "",
    reach: 500000,
    price: 875,
    isSelected: false,
    id: "tokentroop",
  },
  {
    media: "NFT News Today",
    monthlyTraffic: "",
    reach: 500000,
    price: 800,
    isSelected: false,
    id: "nft-news-today",
  },
  {
    media: "Coinscreed",
    monthlyTraffic: "",
    reach: 500000,
    price: 800,
    isSelected: false,
    id: "coinscreed",
  },
  {
    media: "TheCoinrise",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "thecoinrise",
  },
  {
    media: "Crypto Economy",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "crypto-economy",
  },
  {
    media: "CoinChapter",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "coinchapter",
  },
  {
    media: "Coinworldstory",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "coinworldstory",
  },
  {
    media: "Thecryptoupdates.com",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "thecryptoupdates.com",
  },
  {
    media: "Theblockopedia.com",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "theblockopedia.com",
  },
  {
    media: "Prices.org",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "prices.org",
  },
  {
    media: "TheMarketPeriodical",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "themarketperiodical",
  },
  {
    media: "Visionary Finance",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "visionary-finance",
  },
  {
    media: "CryptoBrowser",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "cryptobrowser",
  },
  {
    media: "AirdropsMob",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "airdropsmob",
  },
  {
    media: "Coinfunda",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "coinfunda",
  },
  {
    media: "blockcrux.com",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "blockcrux.com",
  },
  {
    media: "Coingabbar",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "coingabbar",
  },
  {
    media: "Vietnamese Insider",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "vietnamese-insider",
  },
  {
    media: "Cryptodnes",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "cryptodnes",
  },
  {
    media: "Timestabloid",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "timestabloid",
  },
  {
    media: "topcom",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "topcom",
  },
  {
    media: "defidraft.com",
    monthlyTraffic: "",
    reach: 250000,
    price: 700,
    isSelected: false,
    id: "defidraft.com",
  },
  {
    media: "Kriptoup",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "kriptoup",
  },
  {
    media: "ulaska.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "ulaska.com",
  },
  {
    media: "blockscroll.org",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockscroll.org",
  },
  {
    media: "coincript.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coincript.com",
  },
  {
    media: "coinmes.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinmes.com",
  },
  {
    media: "cripplly.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cripplly.com",
  },
  {
    media: "cryptoate.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptoate.com",
  },
  {
    media: "cryptocurrencyfinancial.org",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptocurrencyfinancial.org",
  },
  {
    media: "geekocoin.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "geekocoin.net",
  },
  {
    media: "kryptoscroll.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "kryptoscroll.com",
  },
  {
    media: "pentaxcoin.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "pentaxcoin.com",
  },
  {
    media: "newsflurry.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "newsflurry.com",
  },
  {
    media: "altpoint.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "altpoint.co",
  },
  {
    media: "bitcrux.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "bitcrux.net",
  },
  {
    media: "bitscoop.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "bitscoop.net",
  },
  {
    media: "blockcruck.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockcruck.com",
  },
  {
    media: "blockhubs.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockhubs.co",
  },
  {
    media: "blocknow.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blocknow.net",
  },
  {
    media: "blockorn.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockorn.co",
  },
  {
    media: "blockphere.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockphere.com",
  },
  {
    media: "blockpost.xyz ",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockpost.xyz-",
  },
  {
    media: "blockreach.net ",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockreach.net-",
  },
  {
    media: "blockspy.org",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockspy.org",
  },
  {
    media: "blockstory.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockstory.co",
  },
  {
    media: "btccrux.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "btccrux.com",
  },
  {
    media: "btcdaily.org",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "btcdaily.org",
  },
  {
    media: "btcinsider.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "btcinsider.net",
  },
  {
    media: "btcjournal.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "btcjournal.co",
  },
  {
    media: "btcsonic.xyz",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "btcsonic.xyz",
  },
  {
    media: "btcstory.xyz",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "btcstory.xyz",
  },
  {
    media: "coinblast.co ",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinblast.co-",
  },
  {
    media: "coinjet.info ",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinjet.info-",
  },
  {
    media: "coinnewspan.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinnewspan.com",
  },
  {
    media: "coinnoble.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinnoble.com",
  },
  {
    media: "coinolly.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinolly.com",
  },
  {
    media: "cryptopost.us",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptopost.us",
  },
  {
    media: "cryptopress.uk",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptopress.uk",
  },
  {
    media: "cryptoprint.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptoprint.co",
  },
  {
    media: "cryptoscrollen.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptoscrollen.com",
  },
  {
    media: "cryptowires.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptowires.net",
  },
  {
    media: "defilust.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "defilust.com",
  },
  {
    media: "nftscreen.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "nftscreen.co",
  },
  {
    media: "defijournal.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "defijournal.net",
  },
  {
    media: "coinkindle.org ",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinkindle.org-",
  },
  {
    media: "coinspit.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "coinspit.co",
  },
  {
    media: "cryptomanias.org",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptomanias.org",
  },
  {
    media: "cryptoroof.org",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptoroof.org",
  },
  {
    media: "cryptothrive.news",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptothrive.news",
  },
  {
    media: "thebuzzuniverse.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "thebuzzuniverse.com",
  },
  {
    media: "thecoinstudy.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "thecoinstudy.com",
  },
  {
    media: "therobusthealth.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "therobusthealth.com",
  },
  {
    media: "topwealthyways.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "topwealthyways.com",
  },
  {
    media: "wealthpills.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "wealthpills.com",
  },
  {
    media: "zecripto.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "zecripto.com",
  },
  {
    media: "easyfinancetips.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "easyfinancetips.com",
  },
  {
    media: "financialspeaks.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "financialspeaks.com",
  },
  {
    media: "thefinanceglobe.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "thefinanceglobe.com",
  },
  {
    media: "bestknownfinance.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "bestknownfinance.com",
  },
  {
    media: "greencrestcapital.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "greencrestcapital.com",
  },
  {
    media: "cryptosisnews.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptosisnews.com",
  },
  {
    media: "techbunch.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "techbunch.co",
  },
  {
    media: "techexplore.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "techexplore.co",
  },
  {
    media: "techfluxnews.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "techfluxnews.com",
  },
  {
    media: "techshelf.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "techshelf.net",
  },
  {
    media: "techbeam.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "techbeam.co",
  },
  {
    media: "techvibe9.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "techvibe9.com",
  },
  {
    media: "pressbase.co",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "pressbase.co",
  },
  {
    media: "dubaiuncovered.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "dubaiuncovered.com",
  },
  {
    media: "futuretechdubai.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "futuretechdubai.com",
  },
  {
    media: "dubaigeek9.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "dubaigeek9.com",
  },
  {
    media: "BSCBuzz.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "bscbuzz.com",
  },
  {
    media: "blockscooop.com",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "blockscooop.com",
  },
  {
    media: "Cryptobulletin.net",
    monthlyTraffic: "",
    reach: 150000,
    price: 500,
    isSelected: false,
    id: "cryptobulletin.net",
  },
];

import localData  from "../localData"

const {
    coinTelegraph,
    coinDesk,
    thedefiant,
    bitcoinLogo,
    beInCrypto,
    decryptLogoPng,
} = localData.images;

export const PRProducts = [
    {
        media: "Cointelegraph",
        monthlyTraffic: "4.1M+",
        reach: "4.1M+",
        price: 14000,
        isSelected: false,
        id: "cointelegraph-7710",
        logo: coinTelegraph
    },
    {
        media: "CoinDesk",
        monthlyTraffic: "5.4M +",
        reach: "5.4M+",
        price: 15000,
        isSelected: false,
        id: "coindesk-2691",
        price_secondary: 9000,
        logo: coinDesk
    },
    {
        media: "BeInCrpyto",
        monthlyTraffic: 25000000,
        reach: "2.4M+",
        price: 5000,
        isSelected: false,
        id: "beincrpyto-1384",
        price_secondary: 50000,
        logo: beInCrypto
    },
    {
      media: "Cointelegraph Lite",
      monthlyTraffic: "",
      reach: "741K+",
      price: 6000,
      isSelected: false,
      id: "cointelegraph-lite-3178",
      price_secondary: 3300,
    },
    {
      media: "Bitcoin.com",
      monthlyTraffic: "",
      reach: "1M+",
      price: 3750,
      isSelected: false,
      id: "bitcoincom-5693",
      price_secondary: 1500,
      logo: bitcoinLogo
    },
    {
      media: "Decrypt",
      monthlyTraffic: "",
      reach: "886K+",
      price: 1500,
      isSelected: false,
      id: "decrypt-4545",
      price_secondary: 500,
      logo: decryptLogoPng
    },
    {
      media: "Cryptopotato",
      monthlyTraffic: "",
      reach: "996K+",
      price: 1500,
      isSelected: false,
      id: "cryptopotato-7179",
      price_secondary: 500,
    },
    {
      media: "thedefiant.io",
      monthlyTraffic: "",
      reach: "413K+",
      price: 1500,
      isSelected: false,
      id: "thedefiantio-9742",
      price_secondary: 500,
      logo: thedefiant
    },
    {
      media: "coingape.com",
      monthlyTraffic: "",
      reach: "447K+",
      price: 1500,
      isSelected: false,
      id: "coingapecom-5338",
      price_secondary: 450,
    },
    {
      media: "newsbtc.com",
      monthlyTraffic: "",
      reach: "347K+",
      price: 1500,
      isSelected: false,
      id: "newsbtccom-348",
      price_secondary: 400,
    },
    {
      media: "cryptodaily.co.uk",
      monthlyTraffic: "",
      reach: "315K+",
      price: 1500,
      isSelected: false,
      id: "cryptodailycouk-8233",
      price_secondary: 400,
    },
    {
      media: "cryptoslate.com",
      monthlyTraffic: "",
      reach: "120K+",
      price: 1500,
      isSelected: false,
      id: "cryptoslatecom-6089",
      price_secondary: 580,
    },
    {
      media: "thedefiant.io",
      monthlyTraffic: "",
      reach: "255K+",
      price: 1500,
      isSelected: false,
      id: "thedefiantio-9742",
      price_secondary: 360,
    },
    {
      media: "dailyhodl.com",
      monthlyTraffic: "",
      reach: "205K+",
      price: 1500,
      isSelected: false,
      id: "dailyhodlcom-2793",
      price_secondary: 360,
    },
    {
      media: "bitcoinist.com",
      monthlyTraffic: "",
      reach: "40K+",
      price: 1500,
      isSelected: false,
      id: "bitcoinistcom-2244",
      price_secondary: 420,
    },
    {
      media: "coinpedia.org",
      monthlyTraffic: "",
      reach: "208K+",
      price: 1500,
      isSelected: false,
      id: "coinpediaorg-5482",
      price_secondary: 420,
    },
    {
      media: "blockonomi.com",
      monthlyTraffic: "",
      reach: "7K+",
      price: 1500,
      isSelected: false,
      id: "blockonomicom-9435",
      price_secondary: 420,
    },
    {
      media: "coinedition.com",
      monthlyTraffic: "",
      reach: "153K+",
      price: 1500,
      isSelected: false,
      id: "coineditioncom-7135",
      price_secondary: 190,
    },
    {
      media: "cryptowisser.com",
      monthlyTraffic: "",
      reach: "260K+",
      price: 1000,
      isSelected: false,
      id: "cryptowissercom-5663",
      price_secondary: 190,
    },
    {
      media: "coincheckup.com",
      monthlyTraffic: "",
      reach: "127K+",
      price: 1000,
      isSelected: false,
      id: "coincheckupcom-1202",
      price_secondary: 280,
    },
    {
      media: "cryptonewsflash.com",
      monthlyTraffic: "",
      reach: "157K+",
      price: 1000,
      isSelected: false,
      id: "cryptonewsflashcom-7073",
      price_secondary: 260,
    },
    {
      media: "cryptonews.net",
      monthlyTraffic: "",
      reach: "400K+",
      price: 1000,
      isSelected: false,
      id: "cryptonewsnet-5758",
      price_secondary: 250,
    },
    {
      media: "bravenewcoin.com",
      monthlyTraffic: "",
      reach: "197K+",
      price: 1000,
      isSelected: false,
      id: "bravenewcoincom-397",
      price_secondary: 190,
    },
    {
      media: "coinspeaker.com",
      monthlyTraffic: "",
      reach: "426K+",
      price: 1000,
      isSelected: false,
      id: "coinspeakercom-6928",
      price_secondary: 190,
    },
    {
      media: "analyticsinsight.net",
      monthlyTraffic: "",
      reach: "269K+",
      price: 1000,
      isSelected: false,
      id: "analyticsinsightnet-5558",
      price_secondary: 160,
    },
    {
      media: "thecryptobasic.com",
      monthlyTraffic: "",
      reach: "338K+",
      price: 1000,
      isSelected: false,
      id: "thecryptobasiccom-9442",
      price_secondary: 180,
    },
    {
      media: "cryptomode.com",
      monthlyTraffic: "",
      reach: "462K+",
      price: 1000,
      isSelected: false,
      id: "cryptomodecom-5512",
      price_secondary: 140,
    },
    {
      media: "thecryptotime.com",
      monthlyTraffic: "",
      reach: "499K+",
      price: 1000,
      isSelected: false,
      id: "thecryptotimecom-8599",
      price_secondary: 180,
    },
    {
      media: "bitcoininsider.org",
      monthlyTraffic: "",
      reach: "264K+",
      price: 1000,
      isSelected: false,
      id: "bitcoininsiderorg-8617",
      price_secondary: 75,
    },
    {
      media: "timestabloid.com",
      monthlyTraffic: "",
      reach: "176K+",
      price: 500,
      isSelected: false,
      id: "timestabloidcom-295",
      price_secondary: 100,
    },
    {
      media: "coingabbar.com",
      monthlyTraffic: "",
      reach: "281K+",
      price: 500,
      isSelected: false,
      id: "coingabbarcom-6621",
      price_secondary: 100,
    },
    {
      media: "supercryptonews.com",
      monthlyTraffic: "",
      reach: "76K+",
      price: 500,
      isSelected: false,
      id: "supercryptonewscom-6035",
      price_secondary: 120,
    },
    {
      media: "livebitcoinnews.com",
      monthlyTraffic: "",
      reach: "201K+",
      price: 500,
      isSelected: false,
      id: "livebitcoinnewscom-9670",
      price_secondary: 100,
    },
    {
      media: "coinpaper.io",
      monthlyTraffic: "",
      reach: "7K+",
      price: 500,
      isSelected: false,
      id: "coinpaperio-845",
      price_secondary: 140,
    },
    {
      media: "zycrypto.com",
      monthlyTraffic: "",
      reach: "202K+",
      price: 500,
      isSelected: false,
      id: "zycryptocom-2217",
      price_secondary: 160,
    },
    {
      media: "coinworldstory.com",
      monthlyTraffic: "",
      reach: "226K+",
      price: 500,
      isSelected: false,
      id: "coinworldstorycom-6960",
      price_secondary: 130,
    },
    {
      media: "cryptoknowmics.com",
      monthlyTraffic: "",
      reach: "305K+",
      price: 500,
      isSelected: false,
      id: "cryptoknowmicscom-1465",
      price_secondary: 100,
    },
    {
      media: "thenewscrypto.com",
      monthlyTraffic: "",
      reach: "217K+",
      price: 500,
      isSelected: false,
      id: "thenewscryptocom-3266",
      price_secondary: 170,
    },
    {
      media: "coindoo.com",
      monthlyTraffic: "",
      reach: "219K+",
      price: 500,
      isSelected: false,
      id: "coindoocom-5745",
      price_secondary: 160,
    },
    {
      media: "thecryptoupdates.com",
      monthlyTraffic: "",
      reach: "415K+",
      price: 500,
      isSelected: false,
      id: "thecryptoupdatescom-2758",
      price_secondary: 130,
    },
    {
      media: "theblockopedia.com",
      monthlyTraffic: "",
      reach: "87K+",
      price: 500,
      isSelected: false,
      id: "theblockopediacom-4585",
      price_secondary: 130,
    },
    {
      media: "benzinga.com",
      monthlyTraffic: "",
      reach: "246K+",
      price: 500,
      isSelected: false,
      id: "benzingacom-9091",
      price_secondary: 40,
    },
    {
      media: "digitaljournal.com",
      monthlyTraffic: "",
      reach: "293K+",
      price: 500,
      isSelected: false,
      id: "digitaljournalcom-9036",
      price_secondary: 25,
    },
    {
      media: "techbullion.com",
      monthlyTraffic: "",
      reach: "486K+",
      price: 500,
      isSelected: false,
      id: "techbullioncom-5103",
      price_secondary: 40,
    },
    {
      media: "apnews.com",
      monthlyTraffic: "",
      reach: "214K+",
      price: 500,
      isSelected: false,
      id: "apnewscom-2831",
      price_secondary: 110,
    },
    {
      media: "defidraft.com",
      monthlyTraffic: "",
      reach: "121K+",
      price: 500,
      isSelected: false,
      id: "defidraftcom-2048",
      price_secondary: 50,
    },
    {
      media: "blockcrux.com",
      monthlyTraffic: "",
      reach: "58K+",
      price: 500,
      isSelected: false,
      id: "blockcruxcom-6093",
      price_secondary: 80,
    },
    {
      media: "coinmiller.com",
      monthlyTraffic: "",
      reach: "233K+",
      price: 500,
      isSelected: false,
      id: "coinmillercom-1482",
    },
    {
      media: "altpoint.co",
      monthlyTraffic: "",
      reach: "29K+",
      price: 500,
      isSelected: false,
      id: "altpointco-5574",
    },
    {
      media: "bitcrux.net",
      monthlyTraffic: "",
      reach: "127K+",
      price: 500,
      isSelected: false,
      id: "bitcruxnet-9551",
    },
    {
      media: "bitscoop.net",
      monthlyTraffic: "",
      reach: "228K+",
      price: 500,
      isSelected: false,
      id: "bitscoopnet-9562",
    },
    {
      media: "blockcruck.com",
      monthlyTraffic: "",
      reach: "425K+",
      price: 500,
      isSelected: false,
      id: "blockcruckcom-9591",
    },
    {
      media: "blockhubs.co",
      monthlyTraffic: "",
      reach: "30K+",
      price: 500,
      isSelected: false,
      id: "blockhubsco-7110",
    },
    {
      media: "blocknow.net",
      monthlyTraffic: "",
      reach: "476K+",
      price: 500,
      isSelected: false,
      id: "blocknownet-6068",
    },
    {
      media: "blockorn.co",
      monthlyTraffic: "",
      reach: "253K+",
      price: 500,
      isSelected: false,
      id: "blockornco-3887",
    },
    {
      media: "blockphere.com",
      monthlyTraffic: "",
      reach: "499K+",
      price: 500,
      isSelected: false,
      id: "blockpherecom-7048",
    },
    {
      media: "blockpost.xyz",
      monthlyTraffic: "",
      reach: "476K+",
      price: 500,
      isSelected: false,
      id: "blockpostxyz-8424",
    },
    {
      media: "blockreach.net",
      monthlyTraffic: "",
      reach: "44K+",
      price: 500,
      isSelected: false,
      id: "blockreachnet-3504",
    },
    {
      media: "blockscroll.org",
      monthlyTraffic: "",
      reach: "138K+",
      price: 500,
      isSelected: false,
      id: "blockscrollorg-2897",
    },
    {
      media: "blockspy.org",
      monthlyTraffic: "",
      reach: "103K+",
      price: 500,
      isSelected: false,
      id: "blockspyorg-8907",
    },
    {
      media: "blockstory.co",
      monthlyTraffic: "",
      reach: "67K+",
      price: 500,
      isSelected: false,
      id: "blockstoryco-1444",
    },
    {
      media: "btccrux.com",
      monthlyTraffic: "",
      reach: "74K+",
      price: 500,
      isSelected: false,
      id: "btccruxcom-7830",
    },
    {
      media: "btcdaily.org",
      monthlyTraffic: "",
      reach: "81K+",
      price: 500,
      isSelected: false,
      id: "btcdailyorg-8382",
    },
    {
      media: "btcinsider.net",
      monthlyTraffic: "",
      reach: "93K+",
      price: 500,
      isSelected: false,
      id: "btcinsidernet-6570",
    },
    {
      media: "btcjournal.co",
      monthlyTraffic: "",
      reach: "293K+",
      price: 500,
      isSelected: false,
      id: "btcjournalco-767",
    },
    {
      media: "btcsonic.xyz",
      monthlyTraffic: "",
      reach: "387K+",
      price: 500,
      isSelected: false,
      id: "btcsonicxyz-8029",
    },
    {
      media: "btcstory.xyz",
      monthlyTraffic: "",
      reach: "289K+",
      price: 500,
      isSelected: false,
      id: "btcstoryxyz-7454",
    },
    {
      media: "coincript.com",
      monthlyTraffic: "",
      reach: "431K+",
      price: 500,
      isSelected: false,
      id: "coincriptcom-1865",
    },
    {
      media: "coinjet.info",
      monthlyTraffic: "",
      reach: "450K+",
      price: 500,
      isSelected: false,
      id: "coinjetinfo-7144",
    },
    {
      media: "coinkindle.org",
      monthlyTraffic: "",
      reach: "410K+",
      price: 500,
      isSelected: false,
      id: "coinkindleorg-5796",
    },
    {
      media: "coinmes.com",
      monthlyTraffic: "",
      reach: "420K+",
      price: 500,
      isSelected: false,
      id: "coinmescom-2964",
    },
    {
      media: "coinnewspan.com",
      monthlyTraffic: "",
      reach: "256K+",
      price: 500,
      isSelected: false,
      id: "coinnewspancom-8369",
    },
    {
      media: "coinnoble.com",
      monthlyTraffic: "",
      reach: "393K+",
      price: 500,
      isSelected: false,
      id: "coinnoblecom-3582",
    },
    {
      media: "coinolly.com",
      monthlyTraffic: "",
      reach: "413K+",
      price: 500,
      isSelected: false,
      id: "coinollycom-8315",
    },
    {
      media: "coinspit.co",
      monthlyTraffic: "",
      reach: "376K+",
      price: 500,
      isSelected: false,
      id: "coinspitco-7498",
    },
    {
      media: "cripplly.com",
      monthlyTraffic: "",
      reach: "5K+",
      price: 500,
      isSelected: false,
      id: "crippllycom-5780",
    },
    {
      media: "cryptoate.com",
      monthlyTraffic: "",
      reach: "496K+",
      price: 500,
      isSelected: false,
      id: "cryptoatecom-7301",
    },
    {
      media: "cryptocurrencyfinancial.org",
      monthlyTraffic: "",
      reach: "388K+",
      price: 500,
      isSelected: false,
      id: "cryptocurrencyfinancialorg-1822",
    },
    {
      media: "cryptomanias.org",
      monthlyTraffic: "",
      reach: "404K+",
      price: 500,
      isSelected: false,
      id: "cryptomaniasorg-5370",
    },
    {
      media: "cryptopost.us",
      monthlyTraffic: "",
      reach: "385K+",
      price: 500,
      isSelected: false,
      id: "cryptopostus-6346",
    },
    {
      media: "cryptopress.uk",
      monthlyTraffic: "",
      reach: "258K+",
      price: 500,
      isSelected: false,
      id: "cryptopressuk-8116",
    },
    {
      media: "cryptoprint.co",
      monthlyTraffic: "",
      reach: "43K+",
      price: 500,
      isSelected: false,
      id: "cryptoprintco-1719",
    },
    {
      media: "cryptoroof.org",
      monthlyTraffic: "",
      reach: "203K+",
      price: 500,
      isSelected: false,
      id: "cryptorooforg-6342",
    },
    {
      media: "cryptoscrollen.com",
      monthlyTraffic: "",
      reach: "375K+",
      price: 500,
      isSelected: false,
      id: "cryptoscrollencom-9209",
    },
    {
      media: "cryptothrive.news",
      monthlyTraffic: "",
      reach: "491K+",
      price: 500,
      isSelected: false,
      id: "cryptothrivenews-7317",
    },
    {
      media: "cryptowires.net",
      monthlyTraffic: "",
      reach: "435K+",
      price: 500,
      isSelected: false,
      id: "cryptowiresnet-3939",
    },
    {
      media: "defilust.com",
      monthlyTraffic: "",
      reach: "322K+",
      price: 500,
      isSelected: false,
      id: "defilustcom-7342",
    },
    {
      media: "easyfinancetips.com",
      monthlyTraffic: "",
      reach: "326K+",
      price: 500,
      isSelected: false,
      id: "easyfinancetipscom-1722",
    },
    {
      media: "energizium.com",
      monthlyTraffic: "",
      reach: "211K+",
      price: 500,
      isSelected: false,
      id: "energiziumcom-2329",
    },
    {
      media: "financialspeaks.com",
      monthlyTraffic: "",
      reach: "424K+",
      price: 500,
      isSelected: false,
      id: "financialspeakscom-3387",
    },
    {
      media: "geekocoin.net",
      monthlyTraffic: "",
      reach: "108K+",
      price: 500,
      isSelected: false,
      id: "geekocoinnet-4066",
    },
    {
      media: "kryptoscroll.com",
      monthlyTraffic: "",
      reach: "176K+",
      price: 500,
      isSelected: false,
      id: "kryptoscrollcom-9410",
    },
    {
      media: "nftscreen.co",
      monthlyTraffic: "",
      reach: "218K+",
      price: 500,
      isSelected: false,
      id: "nftscreenco-702",
    },
    {
      media: "pentaxcoin.com",
      monthlyTraffic: "",
      reach: "113K+",
      price: 500,
      isSelected: false,
      id: "pentaxcoincom-7244",
    },
    {
      media: "thecoinstudy.com",
      monthlyTraffic: "",
      reach: "69K+",
      price: 500,
      isSelected: false,
      id: "thecoinstudycom-9050",
    },
    {
      media: "thefinanceglobe.com",
      monthlyTraffic: "",
      reach: "329K+",
      price: 500,
      isSelected: false,
      id: "thefinanceglobecom-1710",
    },
    {
      media: "therobusthealth.com",
      monthlyTraffic: "",
      reach: "293K+",
      price: 500,
      isSelected: false,
      id: "therobusthealthcom-2838",
    },
    {
      media: "topwealthyways.com",
      monthlyTraffic: "",
      reach: "67K+",
      price: 500,
      isSelected: false,
      id: "topwealthywayscom-5914",
    },
    {
      media: "zecripto.com",
      monthlyTraffic: "",
      reach: "132K+",
      price: 500,
      isSelected: false,
      id: "zecriptocom-548",
    },
    {
      media: "blockpost.xyz",
      monthlyTraffic: "",
      reach: "384K+",
      price: 500,
      isSelected: false,
      id: "blockpostxyz-8424",
    },
    {
      media: "blockreach.net",
      monthlyTraffic: "",
      reach: "421K+",
      price: 500,
      isSelected: false,
      id: "blockreachnet-3504",
    },
    {
      media: "coinblast.co",
      monthlyTraffic: "",
      reach: "112K+",
      price: 500,
      isSelected: false,
      id: "coinblastco-8486",
    },
    {
      media: "coinjet.info",
      monthlyTraffic: "",
      reach: "313K+",
      price: 500,
      isSelected: false,
      id: "coinjetinfo-7144",
    },
    {
      media: "coinkindle.org",
      monthlyTraffic: "",
      reach: "263K+",
      price: 500,
      isSelected: false,
      id: "coinkindleorg-5796",
    },
    {
      media: "thebuzzuniverse.com",
      monthlyTraffic: "",
      reach: "297K+",
      price: 500,
      isSelected: false,
      id: "thebuzzuniversecom-7954",
    },
    {
      media: "wealthpills.com",
      monthlyTraffic: "",
      reach: "326K+",
      price: 500,
      isSelected: false,
      id: "wealthpillscom-7417",
    },
    {
      media: "bestknownfinance.com",
      monthlyTraffic: "",
      reach: "304K+",
      price: 500,
      isSelected: false,
      id: "bestknownfinancecom-7410",
    },
    {
      media: "newsflurry.com",
      monthlyTraffic: "",
      reach: "164K+",
      price: 500,
      isSelected: false,
      id: "newsflurrycom-4170",
    },
    {
      media: "ulaska.com",
      monthlyTraffic: "",
      reach: "431K+",
      price: 500,
      isSelected: false,
      id: "ulaskacom-6511",
      price_secondary: 40,
    },
    {
      media: "greencrestcapital.com",
      monthlyTraffic: "",
      reach: "15K+",
      price: 500,
      isSelected: false,
      id: "greencrestcapitalcom-6862",
    },
    {
      media: "cryptosisnews.com",
      monthlyTraffic: "",
      reach: "110K+",
      price: 500,
      isSelected: false,
      id: "cryptosisnewscom-3018",
    },
    {
      media: "defijournal.net",
      monthlyTraffic: "",
      reach: "141K+",
      price: 500,
      isSelected: false,
      id: "defijournalnet-8879",
    },
    {
      media: "techbunch.co",
      monthlyTraffic: "",
      reach: "146K+",
      price: 500,
      isSelected: false,
      id: "techbunchco-657",
    },
    {
      media: "techexplore.co",
      monthlyTraffic: "",
      reach: "401K+",
      price: 500,
      isSelected: false,
      id: "techexploreco-2463",
    },
    {
      media: "techfluxnews.com",
      monthlyTraffic: "",
      reach: "284K+",
      price: 500,
      isSelected: false,
      id: "techfluxnewscom-3931",
    },
    {
      media: "techshelf.net",
      monthlyTraffic: "",
      reach: "405K+",
      price: 500,
      isSelected: false,
      id: "techshelfnet-3188",
    },
    {
      media: "techbeam.co",
      monthlyTraffic: "",
      reach: "359K+",
      price: 500,
      isSelected: false,
      id: "techbeamco-6593",
    },
    {
      media: "techvibe9.com",
      monthlyTraffic: "",
      reach: "274K+",
      price: 500,
      isSelected: false,
      id: "techvibe9com-4409",
    },
    {
      media: "pressbase.co",
      monthlyTraffic: "",
      reach: "485K+",
      price: 500,
      isSelected: false,
      id: "pressbaseco-5314",
    },
    {
      media: "dubaiuncovered.com",
      monthlyTraffic: "",
      reach: "332K+",
      price: 500,
      isSelected: false,
      id: "dubaiuncoveredcom-8777",
    },
    {
      media: "futuretechdubai.com",
      monthlyTraffic: "",
      reach: "347K+",
      price: 500,
      isSelected: false,
      id: "futuretechdubaicom-5811",
    },
    {
      media: "dubaigeek9.com",
      monthlyTraffic: "",
      reach: "227K+",
      price: 500,
      isSelected: false,
      id: "dubaigeek9com-8993",
    },
    {
      media: "bscbuzz.com",
      monthlyTraffic: "",
      reach: "222K+",
      price: 500,
      isSelected: false,
      id: "bscbuzzcom-2612",
    },
    {
      media: "blockscooop.com",
      monthlyTraffic: "",
      reach: "320K+",
      price: 500,
      isSelected: false,
      id: "blockscooopcom-8708",
    },
    {
      media: "cryptobulletin.net",
      monthlyTraffic: "",
      reach: "326K+",
      price: 500,
      isSelected: false,
      id: "cryptobulletinnet-8073",
    },
  ];  
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Button, TableSkeleton, ConfirmationDialog } from "../../../components";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import { auth } from "../../../config/firebase";
import useFormatter from "../../../hooks/useFormatter";
import { toast } from "react-toastify";



export default function Payouts() {
    const {
        getPublisherPayouts,
        approvePayout,
        rejectPayout,
        paidPayout
    } = useFetch();
    const [publisherPayouts, setPublisherPayouts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const { addCommasKeepMinus, formatDate } = useFormatter();    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (!auth.currentUser) return;
                const raw = {
                    uuid: auth.currentUser.uid,
                };
                const result = await getPublisherPayouts(() => {}, raw);
                setPublisherPayouts(result.res_data);
            } catch (error) {
                console.error("Error fetching publisher payouts:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []); // Empty depend
    const { textPreloader } = localData.images;
    const openModal = (type, item) => {
        setShowModal(true);
        setActionType(type)
        setCurrentItem(item)
    }
    const closeModal = () => {
        setShowModal(false);
        setActionType(null)
        setCurrentItem(null)
    }

    const handleConfirm = async () => {
        if (!auth.currentUser) return;
        let raw = {
            uuid: auth.currentUser.uid,
        };
        const data = {
            withdrawalId: currentItem.id,
            publisherId: currentItem.user_id,
            uuid: auth.currentUser.uid,
        }
        try {
            let res
            if (actionType === 'approve') {
                res = await approvePayout(() => {}, data)
                
            }
            if (actionType === 'decline') {
                res = await rejectPayout(() => {}, data)
            }
            if (actionType === 'paid') {
                res = await paidPayout(() => {}, data)
            }
            if (!res || res?.res_sts == false) {
                toast.error(res?.res_msg || 'Oops looks like something went wrong');
            } else {
                toast.success('Payout updated');
            }
            const result = await getPublisherPayouts(() => {}, raw);
            setPublisherPayouts(result.res_data);
            closeModal()
            
        } catch (e) {
            toast.error(e?.message || 'Oops looks like something went wrong');

            closeModal()
        }
    }


    return (
        <>
            <div className="modal-dialog-scrollable accounts modal-body" style={{ padding: "0px 1rem 20px" }}>
                <div className="search-bar-wrapper">
                    <h5 className="modal-title display-2">PAYOUTS</h5>
                </div>
                <div className="scroll">
                    { isLoading ? (
                        <TableSkeleton message="Loading..." icon={textPreloader} />
                    ) : (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>#</th>
                                    <th style={{ width: 0 }}>Publisher Name</th>
                                    <th style={{ width: 0 }}>Email Address</th>
                                    <th style={{ width: 0 }}>Amount</th>
                                    <th style={{ width: 0 }}>Created At</th>
                                    <th style={{ width: 0 }}>Status</th>
                                    <th style={{ width: 0 }}>External ID</th>
                                    <th style={{ width: 0 }}>Address</th>
                                    <th style={{ width: 0, textAlign: 'center' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!publisherPayouts.length ? (
                                    <tr>
                                        <td>
                                            <div className="no-data">No Data To Show!</div>
                                        </td>
                                    </tr>
                                ) : (
                                    publisherPayouts.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.contact_name}</td>
                                                <td>{item.email_address}</td>
                                                <td>${addCommasKeepMinus(item.amount) || 0}</td>
                                                <td>{formatDate(new Date(item.created_at))}</td>
                                                <td className="table-td-status">
                                                    <div
                                                        data-tooltip-id="status-tooltip"
                                                        className={`status ${item.payment_status}`}
                                                    >
                                                        {item.payment_status}
                                                    </div>
                                                </td>
                                                <td>{item.external_id}</td>
                                                <td>{item.wallet_address}</td>
                                                <td>
                                                    {item.payment_status === 'pending' ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                size="sm"
                                                                onClick={() => openModal('approve', item)}

                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button
                                                                style={{marginLeft: 20}}
                                                                variant="outlined"
                                                                color="secondary"
                                                                size="sm"
                                                                onClick={() => openModal('paid', item)}

                                                            >
                                                                Mark as paid
                                                            </Button>
                                                            <Button
                                                                style={{marginLeft: 20}}
                                                                variant="outlined"
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() => openModal('decline', item)}
                                                            >
                                                                Decline
                                                            </Button>
                                                        </div>
                                                    ) : ('')}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    )}
                    <Tooltip id="tooltip-organization-details" className="custom-tooltip" />
                </div>
                <br />
                <br />
            </div>
            <br />
            <br />
            <ConfirmationDialog
                isOpen={showModal}
                onClose={closeModal}
                onConfirm={handleConfirm}
                message="Are you sure you want to proceed?"
            />
        </>
    );
}
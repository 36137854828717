import React from 'react';
import './ConfirmationModal.css';
import { Button } from '..';
export default function ConfirmationDialog({ isOpen, onClose, onConfirm, message }) {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
    <div className="confirmation-modal">
      <p>{message}</p>
      <div className="confirmation-modal-buttons">
      <Button
            variant="outlined"
            color="primary"
            size="sm"
            onClick={onConfirm}

        >
            Confirm
        </Button>
        <Button
            variant="outlined"
            color="danger"
            size="sm"
            onClick={onClose}
        >
            Cancel
        </Button>
      </div>
    </div>
  </div>
  );
}
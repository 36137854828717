import React, { useState } from "react";
import { TableSkeleton, Select, Button, Popup, Pagination } from "../../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import useFormatter from "../../../../hooks/useFormatter";
import { useParams } from "react-router-dom";

export default function Geo() {
    const {
        requestedData,
        isLoading,
        requestedData: {
            campaignStatistics: { geo },
        },
    } = useGlobalContext();
    const { textPreloader } = localData.images;
    const params = useParams();
    const { sortDown, sortUp, sortIcon } = localData.svgs;

    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");

    const handleSortClick = (field) => {
        if (sortField === field) {
            setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };

    const geoArray = Object.entries(geo)
        .map(([key, value]) => ({
            country: key,
            ...value,
        }))
        .sort((a, b) => {
            if (!sortField) return 0; // No sorting
            const fieldA = a[sortField];
            const fieldB = b[sortField];

            if (fieldA == null || fieldB == null) return 0;

            if (sortOrder === "asc") {
                return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
            }
            return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
        });

        const getSortIcon = (type) => {
            if (sortField !== type) {
              return <span className="sort-btn">{sortIcon}</span>;
            }
            return (
              <span className="sort-btn active">
                {sortOrder === "desc" ? sortDown : sortUp}
              </span>
            );
          };

    return (
        <div className="zones-wrapper">
            <div className="scroll shadow bg-white" style={{ padding: 0 }}>
                {isLoading.campaignStatisticsLoading ? (
                    <TableSkeleton
                        message={`Processing data on server - please wait... `}
                        icon={textPreloader}
                    />
                ) : (
                    <table id="geo-table" className="table geo-table">
                        <thead>
                            <tr className="table-row">
                                <th className="table-th-hash text-start">Country Code</th>
                                <th
                                    className="table-th-age text-center clickable"
                                    onClick={() => handleSortClick("impressions")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                        className="with-sort"
                                    >
                                        Impressions {getSortIcon("impressions")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-from text-center clickable"
                                    onClick={() => handleSortClick("clicks")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                        className="with-sort"
                                    >
                                        Clicks {getSortIcon("clicks")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleSortClick("ctr")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ctr"
                                        data-tooltip-content={`How often your ads are clicked when viewed by Blockchain-Ads users.`}
                                        className="with-sort"
                                    >
                                        CTR {getSortIcon("ctr")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleSortClick("spent")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={`How much budget has been spent.`}
                                        className="with-sort"
                                    >
                                        Ad Spend {getSortIcon("spent")}
                                    </span>
                                </th>
                                <th
                                    className="table-th-quantity text-center clickable"
                                    onClick={() => handleSortClick("cpc")}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-cpc"
                                        data-tooltip-content={`How much you pay for each click.`}
                                        className="with-sort"
                                    >
                                        CPC {getSortIcon("cpc")}
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {!geoArray.length ? (
                                <tr>
                                    <td>
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                geoArray.map((item, index) => {
                                    if (
                                        (params?.id === "8256340" &&
                                            item.country === "United States (US)") ||
                                        (params?.id === "8256340" &&
                                            item.country === "Anonymous Proxy (A1)")
                                    )
                                        return null;
                                    return <Row key={index} item={item} index={index} />;
                                })
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

const Row = ({ item, index }) => {
    const { formatNumber, formatCurrency } = useFormatter();
    const params = useParams();

    return (
        <>
            <tr className="table-row" style={{background: item.country === "Total" && "lightgray"}}>
                <td className="table-td-transaction text-start" style={{fontWeight: item.country === "Total" && "bold"}}>
                    {params?.id === "8256340" && item.country === "Mexico (MX)"
                        ? "United States (US)"
                        : item.country}
                </td>
                <td className="table-td-age text-center">{formatNumber(item.impressions)}</td>
                <td className="table-td-from text-center">{formatNumber(item.clicks)}</td>
                <td className="table-td-quantity text-center">{item.ctr?.toFixed(2)}%</td>
                <td className="table-td-quantity text-center">{formatCurrency(item.spent, 2)}</td>
                <td className="table-td-cpc text-center">{formatCurrency(item.cpc, 2)}</td>
            </tr>
        </>
    );
};

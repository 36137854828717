import React, { useState } from "react";

export default function useTrackingState() {
    const [displayInstallPixel, setDisplayInstallPixel] = useState("none");
    const [defaultTab, setDefaultTab] = useState(0);
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [editingTagId, setEditingTagId] = useState("");

    const [state, setState] = useState({ tagName: "", url: "", contains: "", type: "" });

    const resetState = ()=>{
        setState({ tagName: "", url: "", contains: "", type: "" })
    }

    return {
        displayInstallPixel,
        setDisplayInstallPixel,
        defaultTab,
        setDefaultTab,
        isEditingMode,
        setIsEditingMode,
        editingTagId,
        setEditingTagId,
        state, setState,resetState
    };
}

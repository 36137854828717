import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Button, Field, TableSkeleton } from "../../../components";
import localData from "../../../localData";
import useFetch from "../../../hooks/useFetch";
import { auth } from "../../../config/firebase";
import useFormatter from "../../../hooks/useFormatter";
import { toast } from "react-toastify";



export default function PublisherBalances() {
    const {
        getPublisherBalances,
        updateBalance
    } = useFetch();
    const [publisherBalances, setPublisherBalances] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newBalance, setNewBalance] = useState(null);
    const { addCommasKeepMinus, formatDate } = useFormatter();
    const [activeDetails, setActiveDetails] = useState({});

    const { budget, arrowUp, arrowDown } = localData.svgs;
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (!auth.currentUser) return;
                const raw = {
                    uuid: auth.currentUser.uid,
                };
                const result = await getPublisherBalances(() => {}, raw);
                console.log(result)
                setPublisherBalances(result.res_data);
            } catch (error) {
                console.error("Error fetching publisher balances:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []); // Empty depend
    const { textPreloader } = localData.images;
    const openModal = (item) => {
        setShowModal(true);
        setCurrentItem(item)
    }
    const closeModal = () => {
        setShowModal(false);
        setCurrentItem(null)
        setNewBalance(null)
    }

    const handleConfirm = async () => {
        if (!auth.currentUser) return;
        let raw = {
            uuid: auth.currentUser.uid,
        };
        const data = {
            publisherId: currentItem.user_id,
            amount: newBalance,
            uuid: auth.currentUser.uid,
        }
        try {
            await updateBalance(() => {}, data);
            const result = await getPublisherBalances(() => {}, raw);
            setPublisherBalances(result.res_data);
            closeModal()
            toast.success('Balance updated');
        } catch (e) {
            toast.error(e?.message || 'Oops looks like something went wrong');

            closeModal()
        }
    }

    const handleInputChange = (event) => {
        setNewBalance(event.target.value)
    };


    return (
        <>
            <div className="modal-dialog-scrollable accounts modal-body" style={{ padding: "0px 1rem 20px" }}>
                <div className="search-bar-wrapper">
                    <h5 className="modal-title display-2">PUBLISHER BALANCES</h5>
                </div>
                <div className="scroll">
                    { isLoading ? (
                        <TableSkeleton message="Loading..." icon={textPreloader} />
                    ) : (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>#</th>
                                    <th style={{ width: 0 }}>Publisher Name</th>
                                    <th style={{ width: 0 }}>Email</th>
                                    <th style={{ width: 0 }}>Balance</th>
                                    <th style={{ width: 0 }}>Pending withdrawals</th>
                                    <th style={{ width: 0, textAlign: 'center' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!publisherBalances.length ? (
                                    <tr>
                                        <td>
                                            <div className="no-data">No Data To Show!</div>
                                        </td>
                                    </tr>
                                ) : (
                                    publisherBalances.map((item, index) => {
                                        return (
                                            <>
                                            <tr>
                                                <td> <Button
                                                    variant="circle"
                                                    size="sm"
                                                    color="secondary"
                                                    icon={activeDetails.id === item.id ? arrowUp : arrowDown}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setActiveDetails(activeDetails.id === item.id ? {} : { id: item.id });
                                                    }}
                                                /></td>
                                                <td>{item.name}</td>
                                                <td>{item.email_address}</td>
                                                <td>${addCommasKeepMinus(item.balance) || 0}</td>
                                                <td>${addCommasKeepMinus(item.pending) || 0}</td>
                                                <td>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => openModal(item)}

                                                >
                                                    Adjust
                                                </Button>
                                                </td>
                                            </tr>
                                            {activeDetails.id === item.id && item.sites.length > 0 && (
                                                <>
                                                    <tr className="light-table-heads">
                                                        <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>#</th>
                                                        <th style={{ width: 0 }}>Name</th>
                                                        <th style={{ width: 0 }}>Contact</th>
                                                        <th style={{ width: 0 }}>Email</th>
                                                        <th style={{ width: 0 }}>Website</th>
                                                        <th></th>
                                                    </tr>
                                                    {item.sites.map((subItem, subIndex) => {
                                                        return (
                                                            <tr key={subIndex} className="details-tr">
                                                                <td>{ subIndex+1 }</td>
                                                                <td>{ subItem.name }</td>
                                                                <td>{ subItem.contact }</td>
                                                                <td>{ subItem.email }</td>
                                                                <td>{ subItem.website }</td>
                                                                <td>
                                                                </td> 
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                            )}
                                            </>
                                            
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    )}
                    <Tooltip id="tooltip-organization-details" className="custom-tooltip" />
                </div>
                <br />
                <br />
            </div>
            <br />
            <br />
            <Modal isOpen={showModal} onClose={closeModal}>
                <div className="confirmation-modal" style={{ width: '100%', padding: '20px', maxWidth: '400px', textAlign: 'center' }}>
                    <p>Adjust publisher balance</p>
                    <Field
                        type="number"
                        label="Balance"
                        color="secondary"
                        placeholder=""
                        value={currentItem?.balance || 0}
                        required={true}
                        name="priceAmount"
                        size="sm"
                        callback={handleInputChange}
                        startIcon={budget}
                />
                <div className="confirmation-modal-buttons">
                    <Button
                        variant="outlined"
                        color="primary"
                        size="sm"
                        onClick={handleConfirm}

                    >
                        Confirm
                    </Button>
                    <Button
                        style={{marginLeft: 20}}
                        variant="outlined"
                        color="danger"
                        size="sm"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </div>
                </div>
                
            </Modal>
        </>
    );
}

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content-user" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
};

import React, { useState, useEffect } from "react";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import { Button, CopyTextButton, Search, TableSkeleton, Field } from "..";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../hooks/useFormatter";
import useSort from "../../hooks/useSort";
import useAccountsSort from "./useAccountsSort";
import Switch from "@mui/material/Switch";
import { getAllByAltText } from "@testing-library/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useFetch from "../../hooks/useFetch";
const { close, arrowDown, arrowUp, eyeSolid, ServerDown, ServerLeave } =
  localData.svgs;

export default function Accounts({ setIsOpen }) {
  const closeModal = () => {
    setIsOpen(false);
  };
  const { textPreloader } = localData.images;

  const {
    requestedData: {
      userProfileData: { allOrganization },
    },
    requestedData,
    setRequestedData,
    authUser,
    setAuthUser,
    errorAlert,
  } = useGlobalContext();

  const [accountOrganization, setAccountOrganization] = useState(null);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  useEffect(() => {
    const organization = allOrganization.find(
      (item) => item.adminID === requestedData.userProfileData.userId
    );
    setAccountOrganization(organization);
    setFilteredOrganizations(allOrganization);
  }, [requestedData]);

  console.log("allOrganization", allOrganization);

  const { handleOnClick, getSortIcon } = useAccountsSort({
    requestedData,
    setRequestedData,
  });

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isOrganizationsLoading, setIsOrganizationsLoading] = useState(false);
  const [isQueryExist, setIsQueryExist] = useState(false);

  const handleFind = (query) => {
    let queryItems = allOrganization.filter((item) =>
      item.id.startsWith(query)
    );

    if (!queryItems.length) {
      queryItems = allOrganization.filter((item) =>
        item.adminName.startsWith(query)
      );
    }

    if (!queryItems.length) {
      queryItems = allOrganization.filter((item) =>
        item.adminEmail.startsWith(query)
      );
    }

    if (!queryItems.length) return errorAlert("Organization not exist.");
    setIsQueryExist(true);
    setIsOrganizationsLoading(true);

    setFilteredOrganizations(queryItems);

    setTimeout(() => setIsOrganizationsLoading(false), 1000);
  };

  const handleFilter = (id) => {
    const tempFilteredOrganizations = filteredOrganizations.filter(
      (item) => item.id !== id
    );
    if (!tempFilteredOrganizations.length) {
      setIsOrganizationsLoading(true);
      setFilteredOrganizations(allOrganization);
      setTimeout(() => setIsOrganizationsLoading(false), 1000);
      setIsQueryExist(false);
      return;
    }

    setFilteredOrganizations(tempFilteredOrganizations);
  };

  useEffect(() => {
    const cleanOldBcaNames = () => {
      let storedBcaNames = JSON.parse(localStorage.getItem("bcaNames"));
      if (!storedBcaNames || !storedBcaNames.length) return;
      storedBcaNames = storedBcaNames.filter(
        (item) => item.created_at >= Date.now() - 1000 * 60 * 30
      );

      localStorage.setItem("bcaNames", JSON.stringify(storedBcaNames));
    };
    cleanOldBcaNames();
  }, []);

  return (
    <>
      {/* <div className="modal-header">
                <Button
                    className="btn-close"
                    variant="circle"
                    color="light"
                    // size="sm"
                    icon={close}
                    onClick={closeModal}
                />
                <div id="app">
                    <div id="wrapper">
                        <h1 className="glitch-2" data-text="Database">
                            Database
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <ul className="date">
                        <li>
                            <b>Name:</b>
                            <span>{authUser?.currentUser?.displayName}</span>
                        </li>
                        <li>
                            <b>Email:</b>
                            <span>{authUser?.currentUser?.email}</span>
                        </li>
                        <li>
                            <b>Organization name:</b>
                            <span>{accountOrganization?.name}</span>
                        </li>
                        <li>
                            <b>Account created:</b>
                            <span>{authUser?.currentUser?.metadata?.creationTime}</span>
                        </li>
                    </ul>
                    {<ServerDown className="accounts-cover" />}
                </div>
            </div> */}
      <div className="modal-body" style={{ padding: "0px 1rem 20px" }}>
        <div className="search-bar-wrapper">
          <h5 className="modal-title display-2">ORGANIZATIONS</h5>
          <Search
            {...{
              variant: "contained",
              className: `accounts-search`,
              isOpen: isSearchOpen,
              setIsOpen: setIsSearchOpen,
              size: "sm",
              color: "purple",
              disabled: isOrganizationsLoading,
              callback: handleFind,
              placeholder: "Filter Address / ID, BCA Name",
            }}
          />
          {isQueryExist &&
            !isOrganizationsLoading &&
            filteredOrganizations.map((item, index) => {
              return (
                <div className="query-badge" key={index}>
                  <div className="query-badge-text">{item.adminName}</div>
                  <Button
                    variant="circle"
                    icon={close}
                    size="sm"
                    color="secondary"
                    onClick={() => handleFilter(item.id)}
                  />
                </div>
              );
            })}
        </div>
        <div className="scroll">
          {isOrganizationsLoading ? (
            <TableSkeleton message="Loading..." icon={textPreloader} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th className="table-th-details text-center">
                    {/* <Popup
                                        position="right"
                                        toggler="?"
                                        content="See preview of the campaign details."
                                    /> */}
                    <div
                      className="details-icon"
                      style={{ margin: "0 auto" }}
                      data-tooltip-id="tooltip-organization-details"
                      data-tooltip-content={`See the members.`}
                    >
                      {eyeSolid}
                    </div>
                  </th>
                  <th style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>
                    #
                  </th>
                  <th style={{ width: 0 }}>Admin Name</th>
                  <th style={{ width: 0 }}>BCA Name</th>
                  <th className="">Admin Email</th>
                  <th>
                    <span
                      className="status-with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        handleOnClick("account_status", null, () => {});
                      }}
                    >
                      Account Status{getSortIcon("account_status")}
                    </span>
                  </th>
                  <th>
                    <span
                      className="with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        handleOnClick("activeStatus", null, () => {});
                      }}
                    >
                      Active Campaign Status{getSortIcon("activeStatus")}
                    </span>
                  </th>
                  <th>
                    <span
                      className="with-sort"
                      onClick={() => {
                        setIsQueryExist(false);
                        handleOnClick("current_balance", null, () => {});
                      }}
                    >
                      Current Balance {getSortIcon("current_balance")}
                    </span>
                  </th>
                  <th className="">Admin ID</th>
                  <th className="">ID</th>
                  <th className="">Actions</th>
                </tr>
              </thead>
              <tbody>
                {!filteredOrganizations.length ? (
                  <tr>
                    <td>
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  filteredOrganizations.map((item, index) => {
                    const color =
                      item.adminID === "3FoFz6dNudSQEJefCOEcortk9mN2"
                        ? "text-purple"
                        : item.adminID === requestedData.userProfileData.userId
                        ? "text-success"
                        : "";
                    return (
                      <AccountsRow
                        key={index}
                        {...{ ...item, item, index }}
                        color={color}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          )}
          <Tooltip
            id="tooltip-organization-details"
            className="custom-tooltip"
          />
        </div>
        <br />
        <br />

        {/* <h6 style={{ marginLeft: "20px" }}>
                    <strong>Organizations amount: </strong>
                    {allOrganization.length}
                </h6> */}
      </div>
      {/* <div className="modal-footer">
                <Button
                    variant="contained"
                    color="light"
                    style={{ marginRight: "10px", minWidth: "150px" }}
                    onClick={closeModal}
                    className="accounts-close-btn"
                >
                    Leave
                    <ServerLeave />
                </Button>
            </div> */}
      <br />
      <br />
    </>
  );
}

const AccountsRow = ({ item, color, index }) => {
  const {
    getAdminResetUserPassword,
    requestedData,
    isLoading: { adminResetUserPasswordIsLoading },
  } = useGlobalContext();

  const { addCommas, addCommasKeepMinus } = useFormatter();
  const [activeDetails, setActiveDetails] = useState({});
  const {
    updateAccountBCANameRequest,
    updateAccountStatusBCARequest,
    errorAlert,
    successAlert,
  } = useGlobalContext();

  const [bcaNameIsLoading, setBcaNameIsLoading] = useState(false);
  const [bcaStatusIsLoading, setBcaStatusIsLoading] = useState(false);
  const [isBcaNameStored, setIsBcaNameStored] = useState(false);

  useEffect(() => {
    const storedBcaNames = JSON.parse(localStorage.getItem("bcaNames"));
    if (!storedBcaNames || !storedBcaNames.length) return;
    const isStored = storedBcaNames.find(
      (bcaItem) => bcaItem.name == item.bcaName
    );
    if (isStored) setIsBcaNameStored(true);
  }, []);

  const handleOnBlur = (e) => {
    updateAccountBCANameRequest({
      bcaName: e.target.value,
      organizationID: item.id,
      setBcaNameIsLoading,
    });
    setIsBcaNameStored(true);

    const updateAccountInStorage = () => {
      const storedBcaNames = JSON.parse(localStorage.getItem("bcaNames")) || [];
      storedBcaNames.push({ name: e.target.name, created_at: Date.now() });

      localStorage.setItem("bcaNames", JSON.stringify(storedBcaNames));
    };
    updateAccountInStorage();
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleStatusChange = (id, account_status) => {
    updateAccountStatusBCARequest({
      organizationID: id,
      account_status: account_status == "Active" ? "Inactive" : "Active",
      setBcaStatusIsLoading,
      setShowModal,
    });
  };

  const { updateAdvertiserBalance, addMemberToOrganization } = useFetch();

  const [open, setOpen] = useState(false);
  const [isModalInviteOpen, setIsModalInivteOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [inviteMember, setInivteMemeber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInivteModalOpen = () => setIsModalInivteOpen(true);
  const handleInivteModalClose = () => setIsModalInivteOpen(false);

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleInviteMemberChange = (e) => {
    setInivteMemeber(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const tempRaw = {
      amount: parseFloat(amount),
      organizationId: item.id,
      uuid: requestedData.userProfileData.userId,
      type: balanceType,
    };
    try {
      const data = await updateAdvertiserBalance(() => {}, tempRaw);
      successAlert(data.res_msg);
      setIsLoading(false);
      console.log(data, "=updateAccountBCAName= request");
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=updateAdvertiserBalance= request error");
      setIsLoading(false);
    }
    handleClose();
  };

  const [balanceType, setBalanceType] = useState("Refund");

  const handleChange = (event) => {
    setBalanceType(event.target.value);
  };

  const addMemberToOrganizationRequest = async (email, id) => {
    setIsLoading((prev) => ({
      ...prev,
      adminResetUserPasswordIsLoading: true,
    }));
    const temRaw = {
      organizationID: id,
      email,
    };
    try {
      const data = await addMemberToOrganization(() => {}, temRaw);
      console.log(data);
      successAlert("Member invited successfully");
      setInivteMemeber("");
      setIsModalInivteOpen(false);
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      setInivteMemeber("");
    }
  };

  return (
    <>
      <tr
        className={`${
          item.current_balance == 0
            ? ""
            : item.current_balance > 0
            ? "bg-success-100"
            : item.current_balance < 0
            ? "bg-danger-100"
            : ""
        }`}
      >
        <td className="table-td-hush">
          <Button
            variant="circle"
            size="sm"
            color="secondary"
            icon={activeDetails.id === item.id ? arrowUp : arrowDown}
            onClick={(e) => {
              e.stopPropagation();
              setActiveDetails(
                activeDetails.id === item.id ? {} : { id: item.id }
              );
            }}
          />{" "}
        </td>
        <td style={{ width: 0, paddingLeft: 2, paddingRight: 2 }}>
          {index + 1}.
        </td>
        <td title={item.name} className={`${color} table-td-name`}>
          <div className="ellipsis"> {item.name}</div>
          <CopyTextButton text={item.name} />
        </td>
        <td
          title={item.bcaName}
          className={`table-td-bca-name ${color} ${
            isBcaNameStored ? "disabled" : ""
          }`}
          style={{ width: 0 }}
        >
          {/* <input type="text" value={bcaName} onChange={handleOnChange} onBlur={handleOnBlur} /> */}
          <Field
            name={item.bcaName}
            type="text"
            labelInfo="change name here"
            value={item.bcaName}
            color="secondary"
            placeholder="e.g. johnDoeCamp"
            required={true}
            size="sm"
            // callback={handleOnChange}
            onBlur={(e) => e.target.value !== item.bcaName && handleOnBlur(e)}
            disabled={bcaNameIsLoading}
          />
        </td>
        <td title={item.adminEmail} className={`${color} table-td-admin-email`}>
          <div className="ellipsis">{item.adminEmail}</div>
          <CopyTextButton text={item.adminEmail} />
        </td>
        <td>
          <span className={`text-inactive`}>Inactive</span>
          <Switch
            checked={item.account_status === "Active"}
            onChange={() => setShowModal(true)}
            size="small"
            color="default"
          />
          <span
            className={` text-${item.account_status === "Active" && "success"}`}
          >
            Active
          </span>
          <CustomModal isOpen={showModal} onClose={closeModal}>
            <div
              style={{
                padding: "20px",
                maxWidth: "400px",
                textAlign: "center",
              }}
            >
              <h2 style={{ marginBottom: "15px" }}>Confirm Status Change</h2>
              <p style={{ textWrap: "wrap" }}>
                You are about to change the status of{" "}
                <strong>{item.name}</strong>.
              </p>
              <p>
                <strong>Current Status:</strong> {item.account_status}
              </p>
              <p>
                <strong>New Status:</strong>{" "}
                {item.account_status == "Active" ? "Inactive" : "Active"}{" "}
                {/* Replace with the new status you'd like to set */}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={closeModal}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#ccc",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() =>
                    handleStatusChange(item.id, item.account_status)
                  }
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#4CAF50",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </CustomModal>
        </td>
        <td className={`text-center`}>
          <span
            className={` text-${
              item.activeStatus ? "success" : "secondary-500"
            }`}
          >
            {item.activeStatus ? "Active" : "Inactive"}
          </span>
        </td>
        <td className={`${color}`}>
          <div style={{ display: "flex", alignItems: "center" }}>
            ${addCommasKeepMinus(item.current_balance) || 0}{" "}
            <AddCircleIcon
              sx={{ color: "#2aab7e", width: "20px", marginLeft: "10px" }}
              onClick={handleOpen}
            />
          </div>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                minHeight: 200,
              }}
            >
              <h2 style={{ margin: 0 }}>Add Balance</h2>
              <br />
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={balanceType}
                  label="Age"
                  onChange={handleChange}
                >
                  <MenuItem value={"Credit"}>Credit</MenuItem>
                  <MenuItem value={"Receipt"}>Receipt</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                fullWidth
                margin="normal"
                label="Enter Amount"
                type="number"
                value={amount}
                onChange={handleInputChange}
                size="small"
              />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ background: "red !important" }}
                  onClick={handleSubmit}
                  disabled={amount <= 0}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Modal>
        </td>
        <td className={`${color}`}>{item.adminID}</td>
        <td className={`${color}`} style={{ width: 0 }}>
          {item.id} <CopyTextButton text={item.id} />
        </td>
        <td className={`${color}`}>
          <Button
            onClick={() => getAdminResetUserPassword(item.adminEmail)}
            variant="outlined"
            color="primary"
            size="sm"
            disabled={adminResetUserPasswordIsLoading}
          >
            {adminResetUserPasswordIsLoading
              ? `Sending...`
              : "Send reset password link"}
          </Button>
        </td>
        <td className={`${color}`}>
          <Button
            variant="outlined"
            color="marketplace"
            size="sm"
            disabled={adminResetUserPasswordIsLoading}
            onClick={handleInivteModalOpen}
          >
            Add Member
          </Button>
          <Modal open={isModalInviteOpen} onClose={handleInivteModalClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                minHeight: 200,
              }}
            >
              <h2 style={{ margin: 0 }}>Add Member</h2>
              <br />
              <TextField
                fullWidth
                margin="normal"
                label="Enter Email"
                type="email"
                value={inviteMember}
                onChange={handleInviteMemberChange}
                size="small"
              />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ background: "red !important" }}
                  onClick={() =>
                    addMemberToOrganizationRequest(inviteMember, item.id)
                  }
                  disabled={inviteMember <= 0}
                >
                  Submit
                </Button>
                {console.log(item, "stoimamtuka")}
              </div>
            </Box>
          </Modal>
        </td>
      </tr>
      {activeDetails.id === item.id && (
        <>
          {item.members.map((subItem, subIndex) => {
            return (
              <tr key={subIndex} className="details-tr">
                <td colSpan={9} className="">
                  {/* <h5 className="details-title" >Organization Members</h5> */}
                  {subItem}
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-user" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

import { useState, useEffect, useRef } from "react";
import CampaignInformation from "../../../components/campaigns-wizard/tabs/CampaignInformation";
import Summary from "../../../components/campaigns-wizard/tabs/Summary";
import Targeting from "../../../components/campaigns-wizard/tabs/Targeting";
import UploadAds from "../../../components/campaigns-wizard/tabs/UploadAds";
import { Button, OptionCard } from "../../../components";
import { v4 as uuidv4 } from "uuid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  IconButton,
  useMediaQuery,
  StepButton,
  Card,
  CardContent,
} from "@mui/material";
import MegaphoneIcon from "@mui/icons-material/Campaign";
import ContentViewsIcon from "@mui/icons-material/Visibility";
import PublicRelationIcon from "@mui/icons-material/Group";
import RegisterIcon from "@mui/icons-material/AppRegistration";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import GrowthIcon from "@mui/icons-material/TrendingUp";
import { useGlobalContext } from "../../../context";
import { EstimatedResults } from "../../../components";
import useCampaignWizardData from "../../../components/campaigns-wizard/useCampaignWizardData";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import localData from "../../../localData";
// import AdUploader from "../UploadKiko";

export default function CreateCampaign({ campaignStatus = "create" }) {
  const navigate = useNavigate();
  const steps =
    campaignStatus === "edit"
      ? ["Details & Targeting"]
      : ["Choose objective", "Details & Targeting"];

  useEffect(() => {
    if (campaignStatus === "edit") {
      const element = document.getElementById("campaign-creation-id");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const {
    campaignState,
    requestedData,
    campaignsState,
    successAlert,
    getLoadCampaignData,
    errorAlert,
    warningAlert,
    campaignWizardStatus,
    selectedObjective,
    setSelectedObjective,
    setCampaignState,
    defaultCampaign,
    setCreatives,
    setCampaignErrors,
    campaignErrors,
    toggleAccordion
  } = useGlobalContext();
  const [activeStep, setActiveStep] = useState(0);
  const [isStepValid, setIsStepValid] = useState(true);
  const [stepValidity, setStepValidity] = useState([true, true, true]);
  const { balance, balancePredicted } =
    requestedData.userProfileData.currentOrganization;

  // useEffect(() => {
  //   if (
  //     campaignStatus === "edit" &&
  //     !campaignState.campaignInfo.campaignName.length
  //   ) {
  //     return navigate("/campaigns");
  //   }
  // });

  useEffect(() => {
    const validateSteps = () => {
      const validations = steps.map((_, stepIndex) => {
        if (stepIndex === 0) {
          return true;
        } else if (stepIndex === 1) {
          const { campaignName, targetURL, budget, maxCPC } =
            campaignState.campaignInfo;
          return (
            campaignName &&
            targetURL &&
            budget &&
            maxCPC &&
            campaignState.creatives &&
            campaignState.creatives.length > 0
          );
        } else if (stepIndex === 2) {
          return true;
        }
        return false;
      });

      setStepValidity(validations);
      setIsStepValid(validations[activeStep]);
    };

    validateSteps();
  }, [activeStep, campaignState]);

  const sectionRefs = useRef([]);

  const [deviceTypeOptions, setDeviceTypeOptions] = useState([
    { title: "Mobile", isSelected: false, percentage: "58.21", id: uuidv4() },
    { title: "Desktop", isSelected: false, percentage: "41.79", id: uuidv4() },
  ]);

  const [operativeSystemOptions, setOperativeSystemOptions] = useState([
    { title: "Android", isSelected: false, percentage: "41.00", id: uuidv4() },
    { title: "Windows", isSelected: false, percentage: "40.00", id: uuidv4() },
    { title: "IOS", isSelected: false, percentage: "17.00", id: uuidv4() },
    { title: "Linux", isSelected: false, percentage: "2.00", id: uuidv4() },
    { title: "macOS", isSelected: false, percentage: "10.00", id: uuidv4() },
  ]);

  const [browserOptions, setBrowserOptions] = useState([
    {
      title: "Google Chrome",
      isSelected: false,
      percentage: "64.75",
      id: uuidv4(),
    },
    { title: "Safari", isSelected: false, percentage: "19.66", id: uuidv4()},
    {
      title: "Microsoft Edge",
      isSelected: false,
      percentage: "4.32",
      id: uuidv4(),
    },
    { title: "Firefox", isSelected: false, percentage: "2.98", id: uuidv4() },
    { title: "Opera", isSelected: false, percentage: "2.40", id: uuidv4()},
    { title: "Other", isSelected: false, percentage: "5.89", id: uuidv4()},
  ]);

  const [estimatedPercentage, setEstimatedPercentage] = useState({
    smallestEstimatePercentage: 0,
    largestEstimatePercentage: 0,
  });

  const [allEstimatedPercentages, setAllEstimatedPercentages] = useState({
    deviceTypeEstimatedPercentage: 0,
    operativeSystemPercentage: 0,
    browserPercentage: 0,
  });

  const {
    categoryOptions,
    setCategoryOptions,
    walletTargetingOptions,
    setWalletTargetingOptions,
    web3AudienceOptions,
    setWeb3AudienceOptions,
    chainTargetingOptions,
    setChainTargetingOptions,
    countriesOptions,
    setCountriesOptions,
  } = useCampaignWizardData();

  const validateStep = (campaignState, requiredFields) => {
    return requiredFields.every((field) => {
      const value = field
        .split(".")
        .reduce((obj, key) => obj[key], campaignState);
      return value && value.toString().trim().length > 0;
    });
  };

  // State to track expanded status of accordions
  const [expandedStates, setExpandedStates] = useState(
    steps.map(() => true) // Initialize all as expanded
  );

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const {
    attachment,
    contentViews,
    growth,
    megaphone,
    publicRelation,
    register,
    logo,
  } = localData.images;

  const handleSubmit = (type) => {
    setSelectedObjective(options);

    // navigate("/campaigns/create")
    // setShowCampaignWizardDrawer("block");
  };

  const [selected, setSelected] = useState(null);

  const handleSelect = (title, id) => {
    setSelected(id);
    setSelectedObjective(title);
    // if (activeStep < steps.length - 1) setActiveStep(activeStep + 1);
  };

  const options = [
    {
      id: "brand-awareness",
      title: "Brand Awareness",
      description: "Reach as many users as possible",
      icon: megaphone,
    },
    {
      id: "website-visits",
      title: "Website Traffic",
      description: "Get the most users to your website",
      icon: contentViews,
    },
    {
      id: "downloads",
      title: "Downloads/Sign Ups",
      description: "Get users to sign up or download your app",
      icon: attachment,
    },
    {
      id: "sales-deposits",
      title: "Sales/Deposits",
      description: "Maximize paid conversions and ROI",
      icon: growth,
    },
  ];

  const renderStepContent = (stepIndex) => {
    // Adjust the index to match the steps array when the first step is removed
    const adjustedIndex = campaignStatus === "edit" ? stepIndex + 1 : stepIndex;

    switch (adjustedIndex) {
      case 0: // Step 0: "Choose objective"
        return (
          <Box
            maxWidth={1000}
            margin="0 auto"
            display="flex"
            flexDirection="column"
            id="campaign-obejctives-id"
          >
            <Grid>
              <h2 className="objective-title">What's your objective?</h2>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              {options.map((option) => (
                <Grid item xs={12} md={3} key={option.id}>
                  <OptionCard
                    onClick={() => handleSelect(option.title, option.id)}
                    icon={option.icon}
                    title={option.title}
                    description={option.description}
                    selected={selected === option.id}
                    borderColor={campaignErrors.campaignObjective && "red"}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case 1: // Step 1: "Campaign Setup"
        return (
          <div>
            <Targeting />
          </div>
        );
      // case 2: // Step 2: "Summary"
      //   return (

      //   );
      default:
        return null;
    }
  };

  const concat = (options) => {
    let concatedValue = 0;
    options.forEach((item) => {
      if (!item.isSelected) return;
      concatedValue +=
        parseFloat(item.size) || parseFloat(item.percentage) || 0;
    });
    return concatedValue;
  };

  const [result, setResult] = useState(0);
  const [estimatedSize, setEstimatedSize] = useState({
    smallestEstimateSize: 0,
    largestEstimateSize: 0,
  });
  const [allEstimatedSizes, setAllEstimatedSizes] = useState({
    categoryEstimatedSize: 0,
    web3AudienceEstimatedSize: 0,
    walletTargetingEstimatedSize: 0,
    chainTargetingEstimatedSize: 0,
    countriesEstimatedSize: 0,
  });

  const [isUnableToEstimate, setIsUnableToEstimate] = useState(false);
  const [isEstimatedSizeLoading, setIsEstimatedSizeLoading] = useState(false);

  const getFilteredSmallestEstimatePercentage = ({
    smallestEstimatePercentage,
  }) => {
    const deviceType = deviceTypeOptions
      .filter((item) => item.isSelected)
      .map((item) => item.title);
    const os = operativeSystemOptions
      .filter((item) => item.isSelected)
      .map((item) => item.title);
    const browser = browserOptions
      .filter((item) => item.isSelected)
      .map((item) => item.title);

    setIsUnableToEstimate((prev) => false);

    if (
      (os.includes("Android") || os.includes("Windows")) &&
      (browser.includes("Safari") || browser.includes("Opera"))
    ) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }
    if (
      os.includes("IOS") &&
      (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))
    ) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }
    if (
      (os.includes("Windows") ||
        os.includes("Linux") ||
        os.includes("macOS")) &&
      deviceType.includes("Mobile")
    ) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }
    if (os.includes("Android") && deviceType.includes("Desktop")) {
      setIsUnableToEstimate((prev) => true);
      return 0;
    }

    // if (
    //     os.length === 1 &&
    //     (os.includes("Android") || os.includes("Windows")) &&
    //     browser.length === 1 &&
    //     (browser.includes("Safari") || browser.includes("Opera"))
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }
    // if (
    //     os.length === 1 &&
    //     os.includes("IOS") &&
    //     browser.length === 1 &&
    //     (browser.includes("Google Chrome") || browser.includes("Microsoft Edge"))
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }
    // if (
    //     os.length === 1 &&
    //     (os.includes("Windows") || os.includes("Linux") || os.includes("macOS")) &&
    //     deviceType.length === 1 &&
    //     deviceType.includes("Mobile")
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }
    // if (
    //     os.length === 1 &&
    //     os.includes("Android") &&
    //     deviceType.length === 1 &&
    //     deviceType.includes("Desktop")
    // ) {
    //     setIsUnableToEstimate((prev)=>true)
    //     return 0;
    // }

    return smallestEstimatePercentage;
  };

  const handleEstimatedSize = () => {
    // let categoryEstimatedSize = 0;
    // let web3AudienceEstimatedSize = 0;
    // let walletTargetingEstimatedSize = 0;
    // let chainTargetingEstimatedSize = 0;
    // let countriesEstimatedSize = 0;

    // categoryOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     categoryEstimatedSize += parseInt(item.size) || 0;
    // });
    // web3AudienceOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     web3AudienceEstimatedSize += parseInt(item.size) || 0;
    // });
    // walletTargetingOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     walletTargetingEstimatedSize += parseInt(item.size) || 0;
    // });
    // chainTargetingOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     chainTargetingEstimatedSize += parseInt(item.size) || 0;
    // });
    // countriesOptions.forEach((item) => {
    //     if (!item.isSelected) return;
    //     countriesEstimatedSize += parseInt(item.size) || 0;
    // });

    //SIZES

    const categoryEstimatedSize = concat(categoryOptions);
    const web3AudienceEstimatedSize = concat(web3AudienceOptions);
    const walletTargetingEstimatedSize = concat(walletTargetingOptions);
    const chainTargetingEstimatedSize = concat(chainTargetingOptions);
    const countriesEstimatedSize = concat(countriesOptions);

    const allSizes = {
      categoryEstimatedSize,
      web3AudienceEstimatedSize,
      walletTargetingEstimatedSize,
      chainTargetingEstimatedSize,
      countriesEstimatedSize,
    };

    const nonZeroSizes = Object.values(allSizes).filter((size) => size !== 0);
    let smallestEstimateSize =
      nonZeroSizes.length > 0 ? Math.min(...nonZeroSizes) : 0;
    let largestEstimateSize =
      nonZeroSizes.length > 0 ? Math.max(...nonZeroSizes) : 0;

    // if (largestEstimateSize < 32300000) largestEstimateSize = 32300000;
    // if (smallestEstimateSize < 29200000 || smallestEstimateSize == largestEstimateSize)
    //     smallestEstimateSize = 29200000;

    const sizes = { smallestEstimateSize, largestEstimateSize };

    setEstimatedSize(sizes);
    setAllEstimatedSizes(allSizes);

    // PERCENTAGES

    const deviceTypeEstimatedPercentage = concat(deviceTypeOptions);
    const operativeSystemPercentage = concat(operativeSystemOptions);
    const browserPercentage = concat(browserOptions);

    const allPercentages = {
      deviceTypeEstimatedPercentage,
      operativeSystemPercentage,
      browserPercentage,
    };

    const nonZeroPercentages = Object.values(allPercentages).filter(
      (percentage) => percentage !== 0
    );
    let smallestEstimatePercentage =
      nonZeroPercentages.length > 0 ? Math.min(...nonZeroPercentages) : 0;
    let largestEstimatePercentage =
      nonZeroPercentages.length > 0 ? Math.max(...nonZeroPercentages) : 0;

    const percentages = {
      smallestEstimatePercentage,
      largestEstimatePercentage,
    };

    setEstimatedPercentage(percentages);
    setAllEstimatedPercentages(allPercentages);

    smallestEstimatePercentage = getFilteredSmallestEstimatePercentage({
      smallestEstimatePercentage,
    });

    let result = smallestEstimateSize;

    if (smallestEstimatePercentage) {
      result = (smallestEstimateSize * smallestEstimatePercentage) / 100;
    }

    setResult(result);
  };

  let timeout = "";

  useEffect(() => {
    if (!isEstimatedSizeLoading) return;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setIsEstimatedSizeLoading(false);
    }, 3000);
  }, [estimatedSize]);

  useEffect(() => {
    // setIsEstimatedSizeLoading(true);

    handleEstimatedSize();
  }, [
    campaignState.campaignInfo.web3AudienceOptions,
    campaignState.campaignInfo.chainTargeting,
    campaignState.campaignInfo.walletTargeting,
    campaignState.campaignInfo.countriesTargeting,
    campaignState.campaignInfo.portfolioBalance,
    campaignState.campaignInfo.competitorSmartContracts,
    campaignState.campaignInfo.deviceType,
    campaignState.campaignInfo.operativeSystem,
    campaignState.campaignInfo.browser,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const { createNewCampaign, editCampaign } = useFetch();

  function filterEmptyProps(obj) {
    const filtered = {};
    for (const key in obj) {
      if (obj[key] !== "" && obj[key] !== null) {
        filtered[key] = obj[key];
      }
    }
    return filtered;
  }

  const getReviveIDByCampaignId = (campaigns, campaignId) => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    return campaign ? campaign.reviveID : null;
  };

  const handleEditCampaign = async () => {
    let hasError = false;

    if (!campaignState.campaignInfo.campaignName.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignName: true,
      }));
      hasError = true;
    }

    if (!campaignState.campaignInfo.targetURL.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignTragetUrl: true,
      }));
      hasError = true;
    }

    if (!campaignState.campaignInfo.budget.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignBudget: true,
      }));
      hasError = true;
    }

    if (!campaignState.campaignInfo.maxCPC.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignMaxCPC: true,
      }));
      hasError = true;
    }

    if (!campaignState.creatives.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignCreatives: true,
      }));
      hasError = true;
    }
    console.log(campaignErrors, "ahaaaTukaSi");
    

    if (hasError) {
      let elementId = "campaign-details-id";

      if (!selectedObjective.length) {
        setActiveStep(0);
      } else if (!campaignState.creatives.length) {
        elementId = "upload-ads-id";
      }

      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    }

    if (balancePredicted.toFixed(0) <= 0) {
      warningAlert("Top up your account balance before launching a campaign.");
      return;
    }

    setIsLoading(true);

    const reviveID = getReviveIDByCampaignId(
      requestedData.campaignData.currentOrganizationCampaigns,
      campaignState.campaignId
    );

    const filteredCampaignState = {
      uuid: campaignState.uuid,
      organizationID: requestedData.userProfileData.currentOrganization.id,
      userName: requestedData.userProfileData.userName,
      revCampaignID: reviveID,
      targetURL: campaignState.campaignInfo.targetURL,
      creatorEmail: campaignState.creatorEmail,
      campaignId: campaignState.campaignId,
      newCampaignInfo: {
        name: campaignState.campaignInfo.campaignName,
        limit_daily_amount: parseFloat(campaignState.campaignInfo.budget.replace(/,/g, '')),
        endDate: campaignState.campaignInfo.endDate,
        // startDate: campaignState.campaignInfo.startDate,
        targetURL: campaignState.campaignInfo.targetURL,
        maxCPC: campaignState.campaignInfo.maxCPC,
        category: campaignState.campaignInfo.category,
        smartContractAddress: campaignState.campaignInfo.smartContractAddress,
        web3Audience: campaignState.campaignInfo.web3Audience,
        chainTargeting: campaignState.campaignInfo.chainTargeting,
        campaignType: campaignState.campaignInfo.campaignType,
        walletTargeting: campaignState.campaignInfo.walletTargeting,
        deviceType: campaignState.campaignInfo.deviceType,
        operativeSystem: campaignState.campaignInfo.operativeSystem,
        browser: campaignState.campaignInfo.browser,
        countriesTargeting: campaignState.campaignInfo.countriesTargeting,
        portfolioBalance: campaignState.campaignInfo.portfolioBalance,
        competitorSmartContracts:
          campaignState.campaignInfo.competitorSmartContracts,
      },
    };

    const isEndDateExist = filteredCampaignState.newCampaignInfo.endDate;
    if (!isEndDateExist) delete filteredCampaignState.newCampaignInfo.endDate;

    const isNewCreativeExist = campaignState.creatives.find(
      (creative) => !creative.id
    );

    if (isNewCreativeExist) {
      filteredCampaignState.creatives = campaignState.creatives.map(
        (creative) => {
          return {
            title: creative.title,
            image: creative.image,
            apiImage: creative.apiImage,
            size: creative.size,
            id: creative.id?.toString() || "new",
            reviveName: creative.name,
            reviveWidth: creative.width,
            reviveHeight: creative.height,
          };
        }
      );
    }

    try {
      const data = await editCampaign(() => {}, filteredCampaignState);
      successAlert(data.res_msg);

      const campaignsStates = JSON.parse(
        localStorage.getItem("campaignsStates")
      );
      getLoadCampaignData(
        requestedData.userProfileData.currentOrganization.id,
        1,
        campaignsStates?.pageSize || 10
      );
      campaignsState.resetCampaignsState();
      const campaignsDrawerClose = document.querySelector(
        ".campaigns-drawer-close"
      );
      if (campaignsDrawerClose) campaignsDrawerClose.click();
    } catch (err) {
      let errorMessage = err?.response?.data?.res_msg;
      if (typeof errorMessage !== "string")
        errorMessage = err?.response?.data?.res_msg?.message;
      errorAlert(errorMessage || "Internal Server Error");
      console.error(err, "=editUser= request error");
    }
    setIsLoading(false);
    navigate("/campaigns");
  };

  const handlecreateCampaign = async () => {
    let hasError = false;

    if (!campaignState.campaignInfo.campaignName.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignName: true,
      }));
      hasError = true;
    }

    if (!campaignState.campaignInfo.targetURL.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignTragetUrl: true,
      }));
      hasError = true;
    }

    if (!campaignState.campaignInfo.budget.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignBudget: true,
      }));
      hasError = true;
    }

    if (!campaignState.campaignInfo.maxCPC.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignMaxCPC: true,
      }));
      hasError = true;
    }

    if (!campaignState.creatives.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignCreatives: true,
      }));
      hasError = true;
    }
    if (!selectedObjective.length) {
      setCampaignErrors((prevState) => ({
        ...prevState,
        campaignObjective: true,
      }));
      hasError = true;
    }

    console.log(hasError, "ahaaaTukaSi");
    

    if (hasError) {
      let elementId = "campaign-details-id";

      if (!selectedObjective.length) {
        setActiveStep(0);
      } else if (!campaignState.creatives.length) {
        elementId = "upload-ads-id";
        toggleAccordion("ads", "open")
      }

      if(!campaignState.campaignInfo.campaignName || !campaignState.campaignInfo.maxCPC || !campaignState.campaignInfo.budget || !campaignState.campaignInfo.targetURL ){
        toggleAccordion("campaignDetails", "open")
        elementId = "campaign-details-id";
      }

      const element = document.getElementById(elementId);
      
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    }

    if (balancePredicted.toFixed(0) <= 0) {
      warningAlert("Top up your account balance before launching a campaign.");
      return;
    }

    setIsLoading(true);
    // const filteredCampaignInfo =  filterEmptyProps(campaignState.campaignInfo)
    // const filteredCampaignState = {...campaignState,campaignInfo:filteredCampaignInfo}

    const { isUTMChecked, ...campaignInfoWithoutUTMChecked } = campaignState.campaignInfo;

    const filteredCampaignState = {
      ...campaignState,
      campaignInfo: {
        ...campaignInfoWithoutUTMChecked,
        targetURL: isUTMChecked
          ? campaignState.campaignInfo.targetURL +
            "/?UTM_Source=Blockchain-Ads&UTM_Medum=Display"
          : campaignState.campaignInfo.targetURL,
        budget: parseFloat(campaignState.campaignInfo.budget.replace(/,/g, ''))
      },
    };

    // filteredCampaignState.campaignInfo = {...filteredCampaignState.campaignInfo}
    const isEndDateExist = filteredCampaignState.campaignInfo.endDate;
    if (!isEndDateExist) delete filteredCampaignState.campaignInfo.endDate;

    const campaignData = filterEmptyProps(campaignState.campaignInfo);

    const [day, month, year] = campaignData.startDate
      ? campaignData.startDate.split("/").map(Number)
      : new Date().toISOString().slice(0, 10);
    const date = new Date(year, month - 1, day); // Months are 0-based in JavaScript Date
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const dd = String(date.getDate()).padStart(2, "0");

    const formattedStartDate = `${yyyy}-${mm}-${dd} 00:00:00`;

    if (isEndDateExist) {
      const [day2, month2, year2] = campaignData.endDate
        ? campaignData.endDate.split("/").map(Number)
        : [0, 0, 2100];
      const date2 = new Date(year2, month2 - 1, day2); // Months are 0-based in JavaScript Date
      // Extract year, month, and day from the Date object
      const yyyy2 = date2.getFullYear();
      const mm2 = String(date2.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
      const dd2 = String(date2.getDate()).padStart(2, "0");

      var formattedendDate = `${yyyy2}-${mm2}-${dd2} 00:00:00`;
    } else {
      formattedendDate = null;
    }

    const isChainTargetingExist =
      filteredCampaignState.campaignInfo.chainTargeting.length;
    // if (!isChainTargetingExist) filteredCampaignState.campaignInfo.chainTargeting = ["BSC"];

    const isCountiesTargetingExist =
      filteredCampaignState.campaignInfo.countriesTargeting.length;
    if (!isCountiesTargetingExist)
      // filtered countries, many countries removed
      filteredCampaignState.campaignInfo.countriesTargeting = [
        "ad",
        "ai",
        "al",
        "am",
        "an",
        "ao",
        "aq",
        "ar",
        "as",
        "at",
        "au",
        "aw",
        "ax",
        "az",
        "ba",
        "bb",
        "be",
        "bg",
        "bh",
        "bi",
        "bl",
        "bm",
        "bn",
        "bo",
        "bq",
        "br",
        "bs",
        "bt",
        "bv",
        "bw",
        "by",
        "ca",
        "cc",
        "cd",
        "cf",
        "cg",
        "ch",
        "ck",
        "cl",
        "cn",
        "co",
        "cr",
        "cu",
        "cv",
        "cw",
        "cx",
        "cz",
        "de",
        "dk",
        "dm",
        "do",
        "dz",
        "ee",
        "eg",
        "eh",
        "er",
        "es",
        "et",
        "fi",
        "fj",
        "fk",
        "fo",
        "fr",
        "ga",
        "gb",
        "gd",
        "ge",
        "gf",
        "gg",
        "gh",
        "gi",
        "gl",
        "gp",
        "gq",
        "gr",
        "gs",
        "gt",
        "gu",
        "gw",
        "gy",
        "hk",
        "hm",
        "hn",
        "hr",
        "ht",
        "hu",
        "id",
        "ie",
        "il",
        "im",
        "in",
        "io",
        "iq",
        "ir",
        "is",
        "it",
        "je",
        "jm",
        "jo",
        "jp",
        "kg",
        "kh",
        "ki",
        "km",
        "kn",
        "kp",
        "kr",
        "kw",
        "ky",
        "kz",
        "la",
        "lb",
        "lc",
        "li",
        "lk",
        "lr",
        "ls",
        "lt",
        "lu",
        "lv",
        "ly",
        "ma",
        "mc",
        "md",
        "me",
        "mf",
        "mg",
        "mh",
        "mk",
        "ml",
        "mm",
        "mn",
        "mo",
        "mp",
        "mq",
        "mr",
        "ms",
        "mt",
        "mu",
        "mv",
        "mw",
        "mx",
        "my",
        "mz",
        "na",
        "nc",
        "ne",
        "nf",
        "ng",
        "ni",
        "nl",
        "no",
        "np",
        "nr",
        "nu",
        "nz",
        "om",
        "pa",
        "pe",
        "pf",
        "pg",
        "ph",
        "pk",
        "pl",
        "pm",
        "pn",
        "pr",
        "ps",
        "pt",
        "pw",
        "py",
        "qa",
        "re",
        "ro",
        "rs",
        "ru",
        "rw",
        "sa",
        "sb",
        "sc",
        "sd",
        "se",
        "sg",
        "sh",
        "si",
        "sj",
        "sk",
        "sl",
        "sm",
        "sn",
        "so",
        "sr",
        "ss",
        "st",
        "sv",
        "sx",
        "sy",
        "sz",
        "tc",
        "td",
        "tf",
        "tg",
        "th",
        "tj",
        "tk",
        "tl",
        "tm",
        "tn",
        "to",
        "tr",
        "tt",
        "tv",
        "tw",
        "tz",
        "ua",
        "ug",
        "um",
        "us",
        "uy",
        "uz",
        "va",
        "vc",
        "ve",
        "vg",
        "vi",
        "vn",
        "vu",
        "wf",
        "ws",
        "ye",
        "yt",
        "za",
        "zm",
        "zw",
      ];
    const organizationID = requestedData.userProfileData.currentOrganization.id;
    try {
      filteredCampaignState.creatives = filteredCampaignState.creatives.map(
        ({ name, width, height, ...rest }) => ({
          ...rest,
          reviveName: name,
          reviveWidth: width,
          reviveHeight: height,
        })
      );

      // const creatives = JSON.parse(localStorage.getItem("creatives"));

      const data = await createNewCampaign(
        () => {},
        filteredCampaignState,
        organizationID
      );
      campaignsState.resetCampaignsState();
      successAlert(data.res_msg);

      const campaignsStates = JSON.parse(
        localStorage.getItem("campaignsStates")
      );
      getLoadCampaignData(
        requestedData.userProfileData.currentOrganization.id,
        1,
        campaignsStates?.pageSize || 10
      );

      navigate("/campaigns");
    } catch (err) {
      let errorMessage = err?.response?.data?.res_msg;
      if (typeof errorMessage !== "string")
        errorMessage = err?.response?.data?.res_msg?.status[0];
      errorAlert(errorMessage || "Internal Server Error");
      console.error(err, "=createNewCampaign= request error");
    }
    setIsLoading(false);
  };

  const isLargeScreen = useMediaQuery("(min-width:1350px)");

  return (
    <div
      className="campaigns-wizard"
      style={{ position: "relative" }}
      id="campaign-creation-id"
    >
      {campaignWizardStatus === "create" &&
        balancePredicted.toFixed(0) <= 0 && (
          <marquee direction="left">
            <div className="alert alert-warning">
              {alert} “Top up your account balance before launching a campaign”.
            </div>
          </marquee>
        )}
      <Box
        sx={{
          position: "sticky",
          zIndex: "10",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "15px 24px",
          borderBottom: "1px solid #e0e0e0",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Stepper
          activeStep={activeStep}
          style={{ maxWidth: "800px", margin: "0 auto" }}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              sx={{
                "& .MuiStepLabel-horizontal": {
                  borderBottom:
                    activeStep === index ? "2px solid #1f8352" : "none",
                  padding: "15px 15px",
                },
              }}
            >
              <StepLabel
                // error={!stepValidity[index]}
                onClick={handleStep(index)}
                StepIconProps={{
                  sx: {
                    color:
                      activeStep === index
                        ? "#1f8352" // Green for active valid step
                        : "grey", // Default color for inactive steps
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <IconButton onClick={() => navigate("/campaigns")} aria-label="Go back">
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        component="section"
        sx={{
          p: 2,
          margin: "0 auto",
          paddingBottom: "6rem",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            maxWidth: isLargeScreen ? "1450px" : "100%",
            margin: isLargeScreen ? "0 auto" : "unset",
          }}
          className="main-conatiner-create-campaing"
        >
          <Grid item xs={12}>
            <Box>{renderStepContent(activeStep)}</Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
          padding: 2,
          textAlign: "end",
          zIndex: 100,
          display: "flex",
          justifyContent: activeStep === 0 ? "flex-end" : "space-between",
        }}
      >
        {activeStep === 0 ? null :  <Button
          variant="contained"
          size="small"
          color="light"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          Back
        </Button> }

        {activeStep === steps.length - 1 ? (
          campaignWizardStatus !== "edit" ? (
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={handlecreateCampaign}
              disabled={
                // balancePredicted.toFixed(0) <= 0 ||
                isLoading
                // ||
                // !campaignState.campaignInfo.campaignName.length ||
                // !campaignState.campaignInfo.targetURL.length ||
                // !campaignState.campaignInfo.maxCPC.length ||
                // !campaignState.campaignInfo.budget.length ||
                // !campaignState.creatives.length
              }
            >
              {isLoading && (
                <div className="create-campaign-loading-spinner">
                  <CircularProgress color="danger" />{" "}
                </div>
              )}
              Launch Campaign
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={handleEditCampaign}
              disabled={
                // balancePredicted.toFixed(0) <= 0 ||
                isLoading
                // !campaignState.campaignInfo.campaignName.length ||
                // !campaignState.campaignInfo.targetURL.length ||
                // !campaignState.campaignInfo.maxCPC.length ||
                // !campaignState.campaignInfo.budget.length ||
                // !campaignState.creatives.length
              }
            >
              {isLoading && (
                <div className="create-campaign-loading-spinner">
                  <CircularProgress color="danger" />{" "}
                </div>
              )}
              Edit Campaign
            </Button>
          )
        ) : (
          <Button
            variant="contained"
            size="small"
            color="success"
            onClick={handleNext}
            // disabled={!isStepValid}
          >
            Next
          </Button>
        )}
      </Box>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import useFormatter from "../../hooks/useFormatter";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import localData from "../../localData";
import { LineSkeleton, Select } from "../../components";
import { useGlobalContext } from "../../context";

export default function EstimatedResults({
    categoryOptions,
    walletTargetingOptions,
    web3AudienceOptions,
    chainTargetingOptions,
    operativeSystem,
    browsers,
    geo,
    deviceType,
    campaignState: { campaignInfo },
    result,
    isLoading,
    error,
}) {
    const {campaignState} = useGlobalContext()
    const { addCommas, formatLargeNumber, abbreviateNumber } = useFormatter();
    const { info } = localData.svgs;

    const operativeSystemPercentages = {
        Android: 41,
        Windows: 40,
        IOS: 17,
        Linux: 2,
        macOS: 10,
    };

    const [estimationRange, setEstimationRange] = useState([
        {
            title: "7 days",
            id: "2",
            isSelected: false
        },
        {
            title: "30 days",
            id: "1",
            isSelected: true
        },
        {
            title: "90 days",
            id: "3",
            isSelected: false
    }])

    const [state, setState] = useState({
        average: {
            CTR1: "0%",
        },
        clicks: {
            lowValue: 0,
            highValue: 0,
        },
        impressions: {
            lowValue: 0,
            highValue: 0,
        },
        conversions: {
            lowValue: 0,
            highValue: 0,
        },
        costPerResult: {
            lowValue: 0,
            highValue: 0,
        },
    });

    console.log(operativeSystem, "operativeSystem");
    

    const totalPercentage = operativeSystem.reduce((sum, os) => {
        return sum + (operativeSystemPercentages[os] || 0);
    }, 0);

    useEffect(() => {
        let CTR1sum = 0;
        let CTR2sum = 0;
        let CVR1sum = 0;
        let CVR2sum = 0;
        
        const selectedItems = [];

        [categoryOptions, walletTargetingOptions, web3AudienceOptions, chainTargetingOptions, geo].forEach(
            (options) => {
                options.forEach((item) => {
                    if (!item.isSelected) return;
        
                    CTR1sum += parseFloat(item.CTR1) || 0;
                    CTR2sum += parseFloat(item.CTR2) || 0;
                    CVR1sum += parseFloat(item.CVR1) || 0;
                    CVR2sum += parseFloat(item.CVR2) || 0;
        
                    selectedItems.push(item);
                });
            }
        );
        const selectedItemsLength = selectedItems.length;

        const CTR1average = CTR1sum / selectedItemsLength;
        const CTR2average = CTR2sum / selectedItemsLength;
        const CVR1average = CVR1sum / selectedItemsLength;
        const CVR2average = CVR2sum / selectedItemsLength;
        
        const selectedEstimation = estimationRange.find(range => range.isSelected);

        const selectedValue = selectedEstimation ? parseInt(selectedEstimation.title, 10) : null;

        let minRange, maxRange;
        
        if (selectedValue === 7) {
            minRange = 5;
            maxRange = 7;
        } else if (selectedValue === 30) {
            minRange = 28;
            maxRange = 31;
        } else if (selectedValue === 90) {
            minRange = 90;
            maxRange = 105;
        } else {
            minRange = null;
            maxRange = null;
        }


        const clicksLowValue = totalPercentage === 0 ? ((parseFloat(campaignInfo.budget) / parseFloat(campaignInfo.maxCPC)) * minRange) : parseFloat(((parseFloat(campaignInfo.budget) / parseFloat(campaignInfo.maxCPC)) * minRange) * (totalPercentage / 100)).toFixed(1);
        const clicksHighValue = totalPercentage === 0 ? ((parseFloat(campaignInfo.budget) / parseFloat(campaignInfo.maxCPC)) * maxRange) : parseFloat(((parseFloat(campaignInfo.budget) / parseFloat(campaignInfo.maxCPC)) * maxRange) * (totalPercentage / 100)).toFixed(1);        

        const impressionsLowValue = Math.round(clicksLowValue / (parseFloat(CTR2sum / 100)))
        const impressionsHighValue = Math.round(clicksHighValue / (parseFloat(CTR1sum / 100)))

        const conversionsLowValue =  Math.round(parseFloat(((CVR1sum / 100) * (clicksLowValue / selectedItemsLength)).toFixed(1))) * 2;
        const conversionsHighValue = Math.round(parseFloat(((CVR2sum / 100) * (clicksHighValue / selectedItemsLength)).toFixed(1))) * 2;        
        
        const costPerResultLowValue = (parseFloat(campaignInfo.budget) * minRange) / conversionsHighValue;
        const costPerResultHighValue = (parseFloat(campaignInfo.budget) * maxRange) / conversionsLowValue;

        setState((prev) => ({
            ...prev,
            average: {
                CTR1: (CTR1average || 0).toFixed(2) + "%",
                CTR2: (CTR2average || 0).toFixed(2) + "%",
                CVR1: (CVR1average || 0).toFixed(2) + "%",
                CVR2: (CVR2average || 0).toFixed(2) + "%",
            },
            clicks: {
                lowValue: abbreviateNumber(clicksLowValue || 0),
                highValue: abbreviateNumber(clicksHighValue || 0),
            },
            impressions: {
                lowValue: abbreviateNumber(impressionsLowValue || 0),
                highValue: abbreviateNumber(impressionsHighValue || 0),
            },
            conversions: {
                lowValue: abbreviateNumber(conversionsLowValue || 0),
                highValue: abbreviateNumber(conversionsHighValue || 0),
            },
            costPerResult: {
                lowValue: "$" + (addCommas(costPerResultLowValue.toFixed(2)) || 0),
                highValue: "$" + (addCommas(costPerResultHighValue.toFixed(2)) || 0),
            },
        }));

        if(selectedItems.length === 0) {
            setState((prev) => ({
                ...prev,
                impressions: {
                    lowValue: "0",
                    highValue: "0",
                },
                conversions: {
                    lowValue: "0",
                    highValue: "0",
                },
                costPerResult: {
                    lowValue: "$0.00",
                    highValue: "$0.00",
                },
            }))
        }
    }, [estimationRange, operativeSystem,campaignInfo, categoryOptions, walletTargetingOptions, web3AudienceOptions, chainTargetingOptions, geo ]);


    return (
        <div className="estimated-results">
       
            {/* <div className="estimated-results-header">
                <div className="estimated-title-conatiner">
                    <h4 className="estimated-results-title">Estimated Results</h4>
                    <div
                        data-tooltip-id="audience-estimate-tooltip"
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup("Estimated audience size")}
                        className="info-popup info-popup-dark"
                    >
                        {info}
                    </div>
                    <Tooltip id="audience-estimate-tooltip" className="custom-tooltip" />
                </div>
                <div className="estimated-select-conatiner">
                    <Select label={""} items={estimationRange} setItems={setEstimationRange} />
                    <Select
                        {...{
                            items: estimationRange,
                            setItems: setEstimationRange,
                            variant: "outlined",
                            color: "secondary",
                            name: "selectDateRAnge",
                        }}
                    />
                </div>
            </div> */}
            <div className="estimated-results-body">
                <h3>Results Estimates</h3>
                <br />
                <div className="wrapper-conatiner">
                    <div className="estimated-result-item-container">
                        <h4 className="title">Target Audience Size:</h4>
                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            {error && !isLoading ? (
                                <p className="error">Unable to estimate</p>
                            ) : (
                               ( result !== 0) ? 
                                <div className="end">{abbreviateNumber(result)}+</div> :  <p className="error">Unable to estimate</p>
                            )}
                        </div>
                    </div>
                    <div className="estimated-result-item-container">
                        <h4 className="title">Daily Budget (USD):</h4>

                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {campaignState.campaignInfo.budget}$</div> 
                        </div>
                    </div>

                    <div className="estimated-result-item-container">
                        <h4 className="title">Clicks:</h4>

                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {state.clicks.lowValue}</div> -
                            <div className="end"> {state.clicks.highValue}</div>
                        </div>
                    </div>
                    <div className="estimated-result-item-container">
                        <h4 className="title">Impressions:</h4>
                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {state.impressions.lowValue}</div> -
                            <div className="end"> {state.impressions.highValue}</div>
                        </div>
                    </div>
                    <div className="estimated-result-item-container">
                        <h4 className="title">Conversions:</h4>
                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {state.conversions.lowValue}</div> -
                            <div className="end"> {state.conversions.highValue}</div>
                        </div>
                    </div>
                    <div className="estimated-result-item-container">
                        <h4 className="title">Cost Per Result:</h4>
                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {state.costPerResult.lowValue}</div> -
                            <div className="end"> {state.costPerResult.highValue}</div>
                        </div>
                    </div>
                    <div className="estimated-result-item-container">
                        <h4 className="title">CTR:</h4>
                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {state.average.CTR1}</div> -
                            <div className="end"> {state.average.CTR2}</div>
                        </div>
                    </div>
                    <div className="estimated-result-item-container">
                        <h4 className="title">CVR:</h4>
                        <div className={`row ${isLoading ? "isLoading" : ""}`}>
                            <LineSkeleton />
                            <div className="start"> {state.average.CVR1}</div> -
                            <div className="end"> {state.average.CVR2}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="estimated-results-footer">
                Projected results are indicative and not a guarantee of performance.
            </div> */}
             <p className="estimated-description">Estimated results are directional estimates and do not guarantee results. Accelerate campaign forecasting is in Beta.</p>
        </div>
    );
}

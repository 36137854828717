import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useFormatter from "../../../../hooks/useFormatter";

const isSmallScreen = window.innerWidth < 768;
let fontSize = isSmallScreen ? 8 : 12;
let leftMargin = isSmallScreen ? -20 : -10;

export default function Example({
  data = [],
  defaultData = [],
  isLoading = false,
  lines = [],
  className = "",
  isTooltipFormatted = false,
  isHover = false,
}) {
  const { formatNumber, formatCurrency, addCommas } = useFormatter();
  const [processedLines, setProcessedLines] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [hideYAxis, setHideYAxis] = useState(false);
  const [showDollarSign, setShowDollarSign] = useState(false);

  useEffect(() => {
    const adjustYAxis = (lines) => {
      const lineArray = Object.keys(lines).map((key) => ({
        ...lines[key],
        key,
      }));
      const activeLines = lineArray.filter((line) => line.isActive);      
      const isAmountSpentSelected = activeLines.some(
        (line) => line.propertyName === "amount_spent"
      );      
      setShowDollarSign(isAmountSpentSelected);

      if (activeLines.length === 1) {
        activeLines[0].isRightAxis = "left";
        setHideYAxis(false);
      } else if (activeLines.length === 2) {
        activeLines[0].isRightAxis = "left";
        activeLines[1].isRightAxis = "right";
        setHideYAxis(false);
      } else if (activeLines.length > 2) {
        activeLines.forEach((line) => {
          line.isRightAxis = "right";
        });
        setHideYAxis(true);
      } else {
        setHideYAxis(false);
      }

      return lineArray;
    };

    setProcessedLines(adjustYAxis(lines));
  }, [lines]);

  useEffect(() => {
    const normalizeData = (data, lines) => {
      const activeCount = Object.keys(lines).filter((key) => lines[key].isActive).length;

      if (activeCount > 2) {
        return data.map((item) => ({
          ...item,
          normalizeAmount_spent: Math.log10(item.amount_spent + 1),
          normalizeClicks: Math.log10(item.clicks + 1),
          normalizeImpressions: Math.log10(item.impressions + 1),
        }));
      }

      return data; 
    };

    setProcessedData(normalizeData(data, lines));
  }, [data, lines]);  
  
  return (
    <div className={`position ${className}`}>
      <div className="position-inner">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={processedData.length && !isLoading ? processedData : defaultData}
            margin={{
              top: 30,
              right: 30, // Space for the right Y-axis
              left: leftMargin,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              dy={15}
              tick={{ fontSize: fontSize }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              axisLine={false}
              tick={{ fontSize: fontSize }}
              tickLine={false}
              tickFormatter={(tick) => {
                const leftAxisLine = processedLines.find(
                  (line) => line.isRightAxis === "left" && line.isActive
                );
                return leftAxisLine?.propertyName === "amount_spent"
                  ? `$${addCommas(tick)}`
                  : addCommas(tick) || "0";
              }}
              dx={-15}
              hide={hideYAxis}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tick={{ fontSize: fontSize }}
              tickLine={false}
              tickFormatter={(tick) => {
                const rightAxisLine = processedLines.find(
                  (line) => line.isRightAxis === "right" && line.isActive
                );
                return rightAxisLine?.propertyName === "amount_spent"
                  ? `$${addCommas(tick)}`
                  : addCommas(tick) || "0";
              }}
              hide={hideYAxis}
            />
            <Tooltip
              formatter={(value, name, props) => {                
                const originalValue = props.payload;                
                if (isTooltipFormatted) {
                  if (name === "CPM")
                    return formatCurrency(originalValue.AllCPM, 2);
                  if (name === "CPC")
                    return formatCurrency(originalValue.AllCPC, 2);
                  // if (name === "CPM") return "-";
                  // if (name === "CPC") return "-";
                }
                if (name === "impressions")
                  return formatNumber(originalValue.impressions + 1);
                if (name === "ad spend")
                  return formatCurrency(originalValue.amount_spent, 2);
                if (name === "clicks")
                  return formatNumber(originalValue.clicks);
                if (name === "CPM") return formatCurrency(originalValue.CPM, 2);
                if (name === "CPC") return formatCurrency(originalValue.CPC, 2);

                if (name === "unknown") return "0";
                return formatNumber(value);
              }}
            />
            {processedLines.map((line, index) => {
              if (!line.isActive) return null;              
              return (
                <Line
                  key={index}
                  dataKey={line.normalizeName}
                  name={line.displayName}
                  yAxisId={line.isRightAxis}
                  type="monotone"
                  strokeWidth="3"
                  stroke={line.color}
                  dot={isHover}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

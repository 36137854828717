import React, { useState, useEffect } from "react";
import { Tabs, Field, Button, CopyTextButton, Modal } from "../../../../../components";
import { useGlobalContext } from "../../../../../context";
import localData from "../../../../../localData";
import { v4 as uuidv4 } from "uuid";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import TrackingHelp from "../../../tracking-help/TrackingHelp";
import Joyride, { STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { Grid, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1f8352', // Change the border color here
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1f8352', // Change the hover border color here
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1f8352', // Change the focused border color here
            },
          },
        },
      },
    },
  });

const { arrowLeft, arrowRight, TrackingPage, BloomingImage, email } = localData.svgs;
const { pixel, preloader, preloaderBig } = localData.images;

export default function CreateConversionTab({ setActiveTab, activeTab, callbackFromParent }) {
    const [showCreateConversionModal, setShowCreateConversionModal] = useState("none");

    const { trackingState, requestedData } = useGlobalContext();

    const { isEditingMode } = trackingState;

    const navigate = useNavigate()
    
    return (
        <div className="create-conversion-tab">
            <CreateConversionJoyride activeTab={activeTab} />
            {/* <h4 className="create-conversion-tab-title">Create conversion</h4> */}

            {/* <p className="install-pixel-tab-description text-style-1">
                Give your conversion a unique and descriptive name
            </p> */}

            <Tabs
                tabsProps={{}}
                tabs={createConversioFormTabs}
                title="Create conversion"
                // indicatorIcon={question}
                className="create-conversion-tabs"
            />
            <br />
            <div className="create-conversion-tab-footer">
                <div className="btn-group">
                    
                    {/* <Button
                        color="pale-dark"
                        // variant="contained"
                        startIcon={arrowLeft}
                        name="back"
                        size="sm"
                        onClick={() => setActiveTab(0)}
                    /> */}
                    <Button
                        color="pale-dark"
                        variant="contained"
                        name={`${"Complete"}`}
                        size="md"
                        onClick={() => navigate("/tracking")}
                        className="complete-btn"
                        style={{background: "#2aab7e"}}
                    />

                    {/* <Modal
                        Child={CreateConversionModal}
                        className="create-conversion-modal tracking-modal modal-dialog-centered"
                        display={showCreateConversionModal}
                        setDisplay={setShowCreateConversionModal}
                        restProps={{ setActiveTab }}
                        Toggler={() => (
                            <Button
                                className="next-step-btn"
                                name="Next"
                                endIcon={arrowRight}
                                size="sm"
                                variant="contained"
                                color="pale-dark"
                                onClick={() => setShowCreateConversionModal("block")}
                            />
                        )}
                    ></Modal> */}
                </div>
            </div>
        </div>
    );
}

const CreateConversionJoyride = ({ activeTab }) => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    Create a conversion script. Choose a recognizable name for the action (e.g., "Form Sign
                    Up").
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Creating Conversion Script",
        },

        {
            content: (
                <h2 className="joyride-subtitle">Click "Generate Script" to view the conversion code.</h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#create-conversion-tutorial-2",
            title: "Generating Conversion Script",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    Ask the developer to place the conversion script on the appropriate conversion trigger
                    (e.g., form sign-ups). Contact support for help.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#create-conversion-tutorial-3",
            title: "Implementing Conversion Script",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    View conversion data on the main dashboard under "Campaigns." If no conversions appear
                    after a few days, double-check the tracking implementation or contact support.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Viewing Conversion Data",
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "createConversionTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("createConversionTutorial")) return;
        if (activeTab == 1) {
            setTimeout(() => {
                setSteps((prev) => ({ ...prev, run: true }));
            }, 500);
        }
    }, [activeTab]);

    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

// const CreateConversionModal = ({ setIsOpen, setActiveTab }) => {
//     const { conversionOptions } = useGlobalContext();
//     const { close } = localData.svgs;
//     const closeModal = () => {
//         setIsOpen(false);
//     };
//     return (
//         <>
//             <div className="modal-header">
//                 <h2 className="modal-title">Confirmation</h2>

//                 <Button
//                     className="btn-close"
//                     variant="circle"
//                     color="dark"
//                     size="sm"
//                     icon={close}
//                     onClick={closeModal}
//                 />
//             </div>
//             <div className="modal-body">
//                 {/* need to confirm
//                 <br />
//                 <br /> */}
//                 <TrackingHelp />
//             </div>
//             <div className="modal-footer">
//                 <div className="btn-group">
//                     <Button
//                         className="cancel-btn"
//                         name="Cancel"
//                         size="sm"
//                         variant="contained"
//                         color="light"
//                         onClick={closeModal}
//                     />
//                     <Button
//                         className="next-step-btn"
//                         name="Continue"
//                         size="sm"
//                         variant="contained"
//                         color="pale-dark"
//                         onClick={(e) => {
//                             closeModal();
//                             setTimeout(() => {
//                                 setActiveTab(2);
//                             }, 500);
//                         }}
//                     />
//                 </div>
//             </div>
//         </>
//     );
// };

const TrackAnEventContent = ({ setActiveTab, activeTab }) => {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        EditConversionPixelRequest,
        conversionOptions,
        conversionOptions: { trackAnEventScriptTag, pixelScriptTag },
        setConversionOptions,
        requestedData,
        requestedData: {
            userProfileData: { currentOrganization },
        },
        trackingState,
        setEditingTagId,
        _conversionTagEmail,
    } = useGlobalContext();

    useEffect(() => {
        setConversionOptions({
            pixelScriptTag: { value: "", blockChainPixelID: "", isLoading: false },
            s2sPixelScriptTag: { value: "", isLoading: false },
            trackAnEventScriptTag: { value: "", tagID: "", apiKey: "", isLoading: false },
            advertiserPixelStats: { value: [], isLoading: false },
        })
    }, [])

    const { isEditingMode, editingTagId, state, setState } = trackingState;

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({
            ...state,
            [name]: value,
        });
    };

    const [items, setItems] = useState([
        { title: "Is", isSelected: false, id: uuidv4() },
        { title: "Contains", isSelected: false, id: uuidv4() },
        { title: "Does not contain", isSelected: false, id: uuidv4() },
    ]);

    const [isEditLoading, setIsEditLoading] = useState(false);

    const navigate = useNavigate()

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (isEditingMode) {
            EditConversionPixelRequest({
                tagId: editingTagId,
                tagName: state.tagName,
                pixelType: pixelType,
                setIsLoading: setIsEditLoading,
            });
        } else {
            CreateConversionPixelRequest({ tagName: state.tagName, pixelType: pixelType });
        }
    };

    const [isSendInstructionsLoading, setIsSendInstructionsLoading] = useState(false);

    const [developerEmail, setDeveloperEmail] = useState("");

    const [pixelType, setPixelType] = useState('');

    const handleChange = (event) => {
        setPixelType(event.target.value);
    };
    

    return (
        <div className="step-1">
            {/* <br /> */}
            {/* <h2 className="display-2">Track an event</h2> */}
            <div className="form-legend ">
                <form action="" onSubmit={handleOnSubmit} className="conversion-form">
                <ThemeProvider theme={theme}>
                    <TextField 
                        required={true}
                        type="text"
                        label="Conversion Tag Name"
                        name="tagName"
                        value={state.tagName}
                        placeholder="Conversion Tag Name"   
                        size="small"                 
                        disabled={isEditingMode}
                        onChange={handleOnChange}
                        color="#ffff"
                        fullWidth
                    />
                    <br />
                    <br />
                    <FormControl fullWidth size="small" >
                        <InputLabel id="demo-select-small-label">Type</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={state.type ? state.type : pixelType}
                            label="Age"
                            onChange={handleChange}
                            fullWidth
                        >
                            <MenuItem value={"1"}>Sale ("Tracks completed purchase events")</MenuItem>
                            <MenuItem value={"2"}>Lead ("Tracks user information submission events, such as form fills")</MenuItem>
                            <MenuItem value={"3"}>Signup ("Tracks user registration events")</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                </ThemeProvider>
                    {/* <Field
                        required={true}
                        type="text"
                        label="Conversions Tag Name"
                        name="tagName"
                        value={state.tagName}
                        color="secondary"
                        placeholder=""
                        // required={true}
                        size="sm"
                        callback={handleOnChange}
                        disabled={isEditingMode}
                    /> */}
                    <br />
                    {!isEditingMode &&  <Button
                        id="create-conversion-tutorial-2"
                        name="Generate script"
                        variant="contained"
                        color={!isEditingMode ? "pale-dark" : "warning"}
                        style={{ width: "100%", background: "#2aab7e" }}
                        icon={
                            conversionOptions.trackAnEventScriptTag.isLoading || isEditLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                        disabled={conversionOptions.trackAnEventScriptTag.isLoading || isEditLoading || isEditingMode}
                    /> }
               
                </form>

                {/* <BloomingImage className="cover" /> */}
            </div>
            <p className="text-style-1">
            Add this script only to elements of your website where you want to track specific users actions. <b>(e.g. sign up button, download button, thank you page, form submission)</b>. 
            <a className="green-title-accordion" href="/support"> Support</a>
            </p>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                <div className="code" id="create-conversion-tutorial-3">
                <div className="row">
                    <div className="label">
                        <img src={pixel} alt="" />
                        pixel
                    </div>
                    <CopyTextButton
                        text={trackAnEventScriptTag.value}
                        disabled={!trackAnEventScriptTag.value}
                        size="medium"
                    />
                </div>

                <div className="script-wrapper" style={{ fontSize: "12px" }}>
                    {trackAnEventScriptTag.isLoading ? (
                        "Loading..."
                    ) : (trackAnEventScriptTag.value || state.tag) ? (
                        <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                            {state.tag ? state.tag : trackAnEventScriptTag.value}                
                        </SyntaxHighlighter>
                    ) : (
                        "Filled up the form above to generate the event script"
                    )}
                </div>
            </div>
                </Grid>
                <Grid item xs={12} md={6}>
                {trackAnEventScriptTag.value && (
                <>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            _conversionTagEmail({
                                setIsLoading: setIsSendInstructionsLoading,
                                conversionName: state.tagName,
                                blockChainPixelID: pixelScriptTag.blockChainPixelID,
                                conversionTagID: trackAnEventScriptTag.tagID,
                                conversionAPIKey: trackAnEventScriptTag.apiKey,
                                developerEmail: developerEmail,
                                userEmail: requestedData.userProfileData.userEmail
                                // callback: ()=>{

                                // }
                            });
                        }}
                        className="instructions-form"
                    >
                        <h4 className="instructions-form-title">{email} Send Detailed Instructions to your Developer                        </h4>
                        <p className="instructions-form-description description">
                            Email your personalized instructions to your developer so they can complete the
                            implementation for you.
                        </p>
                        <div className="wrapper">
                        <ThemeProvider theme={theme}>
                            <TextField 
                                required={true}
                                type="email"
                                label="Your developer email"
                                name="tagName"
                                value={developerEmail}
                                placeholder="Your developer email"   
                                size="small"                 
                                onChange={(e) => setDeveloperEmail(e.target.value)}
                                color="#ffff"
                                fullWidth
                                id="tagName"
                            />
                            <br />
                            </ThemeProvider>
                            <br />
                            {/* <Field
                                required={true}
                                type="email"
                                label="Your developer email"
                                name="tagName"
                                value={developerEmail}
                                color="secondary"
                                placeholder=""
                                // required={true}
                                size="sm"
                                callback={(e) => setDeveloperEmail(e.target.value)}
                            /> */}
                            <Button
                                style={{ width: "100%" }}
                                id="instructions-btn"
                                name="Send Instructions"
                                variant="contained"
                                color="dark"
                                icon={
                                    isSendInstructionsLoading ? (
                                        <span className="endIcon">
                                            <img src={preloaderBig} />
                                        </span>
                                    ) : null
                                }
                                disabled={isSendInstructionsLoading || !state.tagName}
                            />
                        </div>
                    </form>
                </>
            )}
                </Grid>
            </Grid>


            <br />
            <br />

        </div>
    );
};

// const TrackAPageContent = ({ setActiveTab }) => {
//     const {
//         CreatePixelTagRequest,
//         CreateConversionPixelRequest,
//         conversionOptions,
//         conversionOptions: { trackAnEventScriptTag },
//         setConversionOptions,
//         requestedData: {
//             userProfileData: { currentOrganization },
//         },
//     } = useGlobalContext();

//     const [state, setState] = useState({ tagName: "", url: "", contains: "" });

//     const handleOnChange = (e) => {
//         const { name, value } = e.target || e;
//         setState({
//             ...state,
//             [name]: value,
//         });
//     };

//     const [items, setItems] = useState([
//         { title: "Is", isSelected: false, id: uuidv4() },
//         { title: "Contains", isSelected: false, id: uuidv4() },
//         { title: "Does not contain", isSelected: false, id: uuidv4() },
//     ]);

//     const handleOnSubmit = (e) => {
//         e.preventDefault();
//         CreateConversionPixelRequest(state.tagName || "test tagName", "conversion");
//     };

//     return (
//         <div className="step-2">
//             <br />
//             <h2 className="display-2">Track a page</h2>

//             <br />
//             <div className="form-legend">
//                 <form onSubmit={(e) => e.preventDefault()}>
//                     <Field
//                         type="text"
//                         label="Tag Name"
//                         name="tagName"
//                         value={state.tagName}
//                         color="secondary"
//                         placeholder="Converson name"
//                         // required={true}
//                         size="sm"
//                         callback={handleOnChange}
//                     />
//                     <div className="wrapper">
//                         <Select
//                             {...{
//                                 label: "Select",
//                                 items,
//                                 setItems,
//                                 placeholder: "Select",
//                                 variant: "outlined",
//                                 color: "secondary",
//                                 isLoading: false,
//                                 className: "conversion-select",
//                             }}
//                         />
//                         <Field
//                             type="text"
//                             label="URL"
//                             name="conversion-url"
//                             value={state.url}
//                             color="secondary"
//                             placeholder="e.g. https://johndoe.com"
//                             // required={true}
//                             size="sm"
//                             callback={handleOnChange}
//                         />
//                     </div>

//                     <Button
//                         name="Confirm"
//                         variant="contained"
//                         color="pale-dark"
//                         style={{ width: "100%" }}
//                         onClick={handleOnSubmit}
//                     />
//                 </form>

//                 <TrackingPage className="cover" />
//             </div>

//             <p className="text-style-1">
//                 “Ask your website developer to install this conversion tag to fire whenever a user performs a
//                 conversion. You can use a Tag Management solution - like Google Tag Manager - to simplify this
//                 process.
//             </p>

//             <div className="code">
//                 <div className="row">
//                     <div className="label">
//                         <img src={pixel} alt="" />
//                         pixel
//                     </div>
//                     <CopyTextButton
//                         text={trackAnEventScriptTag.value}
//                         disabled={!trackAnEventScriptTag.value}
//                     />
//                 </div>

//                 <div className="script-wrapper" style={{ fontSize: "12px" }}>
//                     {trackAnEventScriptTag.isLoading ? (
//                         "Loading..."
//                     ) : trackAnEventScriptTag.value && trackAnEventScriptTag.type == "conversion" ? (
//                         <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
//                             {trackAnEventScriptTag.value}
//                         </SyntaxHighlighter>
//                     ) : (
//                         "Filled up the form above to generate the conversion script"
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// const Or = () => {
//     return <div>fkdlj</div>;
// };

const createConversioFormTabs = [
    {
        title: "Track an event",
        // startImageIcon: pixel,
        content: <TrackAnEventContent />,
        id: "track-an-event-2983jfosfod",
    },
    // {
    //     title: "or",
    //     content: <Or />,
    //     id: "08feijwoi",
    //     tabDisabled: true,
    // },
    // {
    //     title: "Track a page",
    //     // startImageIcon: pixel,
    //     content: <TrackAPageContent />,
    //     id: "track-a-page-0283fjoij3w",
    // },
];

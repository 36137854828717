import React, { useState, useEffect, useRef } from "react";
import {
    TableSkeleton,
    Select,
    Button,
    Dropdown,
    CampaignDetailsPopup,
    Popup,
    Pagination,
    CampaignsCalendar,
    Calendar,
    Modal,
    Checkbox,
    // Radio,
} from "../../../components";
import { v4 as uuidv4 } from "uuid";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import { useStateContext } from "../../../stateContext.jsx";
import { useCampaignsContext } from "../../../campaignsContext.jsx";
import { auth } from "../../../config/firebase.js";
import useFetch from "../../../hooks/useFetch.js";
import CampaignInfo from "./campaign-info/CampaignInfo.jsx";
import ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
import useFormatter from "../../../hooks/useFormatter.jsx";
import CampaignsStatiscticsCSV from "../statistics-csv/CampaignsStatiscticsCSV.jsx";
import { useNavigate } from "react-router-dom";

let isStatExist = false;

export default function CampaignsTable() {
    const { sortDown, sortUp, sortIcon } = localData.svgs;
    const {
        requestedData,
        requestedData: {
            campaignData: { currentOrganizationCampaigns, dashboardSummaryData },
        },
        getLoadCampaignData,
        isLoading,
        campaignsState,
        // activeCampaign,
        markedCampaigns,
        getSortIcon,
        handleOnClick,
        setMarkedCampaigns,
    } = useGlobalContext();
    const {
        campaignsPage,
        setCampaignsPage,
        campaignsPageSize,
        setCampaignsPageSize,
        campaignsTotalPages,
        setCampaignsTotalPages,
        campaignsItemsPerPage,
        setCampaignsItemsPerPage,
        campaignSelectionRange,
        setCampaignSelectionRange,
        handleCalendar,
    } = campaignsState;

    const { formatNumber, formatCurrency, addCommas } = useFormatter();

    // const { isCampaignsCalendarOpen, setIsCampaignsCalendarOpen, dateRange, selectionRange } =
    //     useCampaignsContext();

    const [organizationId, setOrganizationId] = useState(null);

    const campaignTableRef = useRef(null);

    const handlePagination = (nextPage = 0) => {
        setCampaignsPage(nextPage);
        // campaignTableRef.current.scrollIntoView({
        //     behavior: "smooth",
        //     block: "start",
        // });
    };

    useEffect(() => {
        setCampaignsPageSize(campaignsItemsPerPage.find((item) => item.isSelected).title);
    }, [campaignsItemsPerPage]);

    useEffect(() => {
        if (!organizationId) return;
        getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
    }, [campaignsPage]);

    useEffect(() => {
        if (!organizationId) return;
        getLoadCampaignData(organizationId, 1, campaignsPageSize);

        const campaignsStates = JSON.parse(localStorage.getItem("campaignsStates"));
        localStorage.setItem(
            "campaignsStates",
            JSON.stringify({
                ...campaignsStates,
                pageSize: campaignsPageSize,
            })
        );
    }, [campaignsPageSize]);

    useEffect(() => {
        const id = requestedData.userProfileData.currentOrganization.id;
        if (!id) return;
        setOrganizationId(id);
        // if(requestedData.campaignData.isRequested) return
        // getLoadCampaignData(id, 1, 10);
    }, [requestedData.userProfileData]);

    useEffect(() => {
        const campaigns = requestedData.campaignData.currentOrganizationCampaigns;
        if (!campaigns.length) return;
        const campaignsTotalPages = Math.ceil(requestedData.campaignData.dataSize / campaignsPageSize);
        setCampaignsTotalPages(campaignsTotalPages);
    }, [requestedData.campaignData]);

    const { settings, eyeSolid } = localData.svgs;
    const { textPreloader } = localData.images;

    // const formatDate = (date) => {
    //     const day = date.getDate().toString().padStart(2, "0");
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //     const year = date.getFullYear().toString();
    //     return `${day}/${month}/${year}`;
    // };

    // const handleCalendar = (range) => {
    //     const { startDate, endDate } = range;
    //     const dateFrom = formatDate(startDate);
    //     const dateTill = formatDate(endDate);
    //     localStorage.setItem("selectionRange", JSON.stringify(range));
    //     localStorage.setItem("selectionRangeFormatted", JSON.stringify({ dateFrom, dateTill }));
    //     campaignsPage !== 1
    //         ? setCampaignsPage(1)
    //         : getLoadCampaignData(organizationId, campaignsPage, campaignsPageSize);
    //     setCampaignSelectionRange(range);
    // };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // const [isWithSmartContractAddress, setIsWithSmartContractAddress] = useState(false);

    // useEffect(() => {
    //     const withAddress = activeCampaign?.extraData?.smartContractAddress ? true : false;
    //     setIsWithSmartContractAddress(withAddress);
    // }, [activeCampaign]);

    const handleMarkedCampaigns = (campaign, isMarked) => {
        let tempMarkedCampaigns = [...markedCampaigns];
        if (isMarked) {
            tempMarkedCampaigns = [...tempMarkedCampaigns, campaign];
        } else {
            tempMarkedCampaigns = tempMarkedCampaigns.filter((item) => item.id !== campaign.id);
        }

        setMarkedCampaigns(tempMarkedCampaigns);
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleAllMarkedCampaigns = (isMarked) => {
        let tempMarkedCampaigns = [];
        if (isMarked) tempMarkedCampaigns = [...currentOrganizationCampaigns];
        setMarkedCampaigns(tempMarkedCampaigns);
    };

    useEffect(() => {
        const sameLength = markedCampaigns.length === currentOrganizationCampaigns.length;
        setIsChecked(sameLength);
    }, [markedCampaigns]);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });

    const handleSort = (columnKey) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === columnKey) {
                return {
                    key: columnKey,
                    direction: prevConfig.direction === 'ascending' ? 'descending' : 'ascending',
                };
            } else {
                return { key: columnKey, direction: 'ascending' };
            }
        });
    };
        
    const sortedCampaigns = [...currentOrganizationCampaigns].sort((a, b) => {
        if (!sortConfig.key) return 0;
    
        if (sortConfig.key === 'status') {
            const getStatusPriority = (status) => (status === 'working' ? 1 : status === 'stopped' ? 2 : 3);
    
            const aPriority = getStatusPriority(a.status);
            const bPriority = getStatusPriority(b.status);
    
            if (aPriority < bPriority) return sortConfig.direction === 'ascending' ? -1 : 1;
            if (aPriority > bPriority) return sortConfig.direction === 'ascending' ? 1 : -1;
            return 0;
        }
    
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
    
        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
    });    
    
    const getSortIconSvg = (columnKey) => {
        if (sortConfig.key === columnKey) {
            return sortConfig.direction === 'ascending' ? sortUp : sortDown;
        }
        return sortIcon;
    };
    

    return (
        <div className="campaigns-table-wrapper" ref={campaignTableRef} id="tour-step-2">
            <div className="row">
                <h2 className="display-2">campaigns</h2>

                <CampaignsStatiscticsCSV />
                {/* <Calendar
                    className="reports-calendar"
                    _selectionRange={campaignSelectionRange}
                    callback={handleCalendar}
                /> */}
            </div>
            <br />
            <div className={`scroll shadow bg-white`} style={{ padding: 0 }}>
                {isLoading.campaignDataIsLoading ? (
                    <TableSkeleton message="Loading..." icon={textPreloader} />
                ) : (
                    <table id="campaigns-table" className="table campaigns-table">
                        <thead>
                            <tr className="table-row">
                                <th style={{ width: "0" }}>
                                    <Checkbox
                                        callback={(isMarked) => handleAllMarkedCampaigns(isMarked)}
                                        checked={isChecked}
                                        color="primary"
                                        size="sm"
                                        someChecked={markedCampaigns.length && !isChecked}
                                    />
                                </th>
                                {/* <th style={{ width: 0 }}>
                                    <Radio
                                        disabled={true}
                                        color="dark"
                                        tooltip="Select current blockchain entry."
                                    />
                                </th> */}
                                <th className="table-th-details text-center">
                                    {/* <Popup
                                        position="right"
                                        toggler="?"
                                        content="See preview of the campaign details."
                                    /> */}
                                    <div
                                        className="details-icon"
                                        style={{ margin: "0 auto" }}
                                        data-tooltip-id="tooltip-table-details"
                                        data-tooltip-content={`See preview of the campaign details.`}
                                    >
                                        {eyeSolid}
                                    </div>
                                </th>

                                <th className="table-th-name text-start" style={{ width: "20%" }}>
                                    <span
                                        data-tooltip-id="tooltip-table-name"
                                        data-tooltip-content={`The name of your campaign.`}
                                    >
                                        Name
                                    </span>
                                </th>
                                <th
                                    className="table-th-settings text-center"
                                    // style={{ width: "20%" }}
                                    style={{ width: "0" }}
                                    // title="Activate or Pause your campaign"
                                >
                                    <div
                                        className="settings-icon"
                                        style={{ margin: "0 auto" }}
                                        data-tooltip-id="tooltip-table-settings"
                                        data-tooltip-content={`Activate or Pause your campaign.`}
                                    >
                                        {settings}
                                    </div>
                                </th>

                                <th
                                    className="table-th-status text-center"
                                    onClick={() => handleSort('status')}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-status"
                                        data-tooltip-content={`Sort by campaign status (working or stopped).`}
                                        className="with-sort"
                                    >
                                        Status
                                        <span className="sort-btn">{getSortIconSvg('status')}</span>
                                    </span>
                                </th>

                                <th 
                                   className="table-th-impressions text-center"
                                   onClick={() => handleSort('impressions')}
                                >
                                    <span
                                        data-tooltip-id="tooltip-table-impressions"
                                        data-tooltip-content={`How many times your ads have been seen.`}
                                        className="with-sort"
                                    >
                                        Impressions 
                                        <span className="sort-btn">{getSortIconSvg('impressions')}</span>
                                    </span>
                                </th>
                                <th className="table-th-clicks text-center"  onClick={() => handleSort('clicks')}>
                                    <span
                                        data-tooltip-id="tooltip-table-clicks"
                                        data-tooltip-content={`How many times your ads have been clicked.`}
                                        className="with-sort"
                                    >
                                        Clicks <span className="sort-btn">{getSortIconSvg('clicks')}</span>
                                    </span>
                                </th>
                                <th className="table-th-ctr text-center" onClick={() => handleSort('CTR')}>
                                    <span
                                        data-tooltip-id="tooltip-table-ctr"
                                        data-tooltip-content={` How often your ads are clicked when viewed by Blockchain-Ads users.`}
                                        className="with-sort"
                                    >
                                        CTR <span className="sort-btn">{getSortIconSvg('CTR')}</span>
                                    </span>
                                </th>
                                <th className="table-th-ad-spend text-center" onClick={() => handleSort('amount_spent')}>
                                    <span
                                        data-tooltip-id="tooltip-table-ad-spend"
                                        data-tooltip-content={` How much budget has been spent.`}
                                        className="with-sort"
                                    >
                                        Ad Spend <span className="sort-btn">{getSortIconSvg('amount_spent')}</span>
                                    </span>
                                </th>
                                <th className="table-th-cpc text-center" onClick={() => handleSort('CPC')}>
                                    <span
                                        data-tooltip-id="tooltip-table-cpc"
                                        data-tooltip-content={`How much you pay for each click.`}
                                        className="with-sort"
                                    >
                                        CPC <span className="sort-btn">{getSortIconSvg('CPC')}</span>
                                    </span>
                                </th>
                                <th className="table-th-conversions text-center" style={{ width: 0 }} onClick={() => handleSort('conversions')}>
                                    <span
                                        data-tooltip-id="tooltip-table-conversions"
                                        data-tooltip-content={`Total amount of the conversions.`}
                                        className="with-sort"
                                    >
                                        {
                                            <Dropdown
                                                {...{
                                                    title: "Conversions",
                                                    //    variant: "contained",
                                                    color: "dark",
                                                    className: "conversions-dropdown",
                                                    size: "sm",
                                                    isOpen: isDropdownOpen,
                                                    setIsOpen: setIsDropdownOpen,
                                                }}
                                            >
                                                {requestedData.campaignData.currentOrganizationCampaigns.map(
                                                    (camp, campIndex) => {
                                                        if (camp.pixelConversionStats.length) {
                                                            isStatExist = true;
                                                            return (
                                                                <li key={campIndex}>
                                                                    {/* <span className="stat-number">
                                                                        {campIndex + 1}.
                                                                    </span> */}
                                                                    <span className="stat-text">
                                                                        {
                                                                            camp.pixelConversionStats[0]
                                                                                ?.tagName
                                                                        }
                                                                    </span>
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                )}
                                                {!isStatExist && (
                                                    <li>
                                                        <span className="stat-text">Empty</span>
                                                    </li>
                                                )}
                                            </Dropdown>
                                        }
                                         <span className="sort-btn">{getSortIconSvg('conversions')}</span> 
                                    </span>
                                    <Tooltip id="tooltip-table-conversions" className="custom-tooltip" />
                                </th>
                                {/* {isWithSmartContractAddress && ( */}
                                <>
                                    <th className="table-th-txn-value text-center" onClick={() => handleSort('transactionValue')}>
                                        <span
                                            data-tooltip-id="tooltip-table-txn-value"
                                            data-tooltip-content={`Total amount of the transaction.`}
                                            className="with-sort"
                                        >
                                            Txn Value  <span className="sort-btn">{getSortIconSvg('transactionValue')}</span> 
                                        </span>
                                        <Tooltip id="tooltip-table-txn-value" className="custom-tooltip" />
                                    </th>
                                    <th className="table-th-transactions text-center" onClick={() => handleSort('transactions')}>
                                    <span className="with-sort">
                                        Transactions <span className="sort-btn">{getSortIconSvg('transactions')}</span> 
                                    </span>
                                    </th>
                                </>
                                {/* )} */}
                            </tr>
                        </thead>

                        <tbody>
                            
                            {!sortedCampaigns.length === 0 ? (
                                <tr style={{ pointerEvents: "none" }}>
                                    <td colSpan="14">
                                        <div className="no-data">No Data To Show!</div>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {sortedCampaigns.map(
                                        (item, index) => (
                                            <CampaignRow
                                                key={index}
                                                item={item}
                                                index={index}
                                                handleMarkedCampaigns={handleMarkedCampaigns}
                                            />
                                        )
                                    )}
                                    <tr className="table-tr-totals">
                                        <td></td>
                                        <td></td>
                                        <td className=" table-td-name table-td-name-totals">
                                            <strong>Total</strong>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className=" text-center">
                                            {addCommas(dashboardSummaryData.totalImpressions) || 0}
                                        </td>
                                        <td className=" text-center">
                                            {addCommas(dashboardSummaryData.totalClicks) || 0}
                                        </td>
                                        <td className=" text-center">
                                            {/*{(0.000).toFixed(2)}% */}
                                            {dashboardSummaryData?.CTR?.toFixed(2)}%
                                        </td>
                                        <td className=" text-center">
                                            {formatCurrency(dashboardSummaryData.totalAdSpend, 2)}
                                        </td>
                                        <td className=" text-center">
                                            {/* <strong>{formatCurrency(0, 2)}</strong> */}
                                            {formatCurrency(dashboardSummaryData.CPC, 2)}
                                        </td>
                                        <td className="text-center">
                                            {addCommas(
                                                parseFloat(dashboardSummaryData.conversions?.toFixed(2))
                                            ) || 0}
                                        </td>
                                        {/* {isWithSmartContractAddress && ( */}
                                        <>
                                            <td className="text-center">
                                                {requestedData.campaignData.currentOrganizationCampaigns.find(
                                                    (item) => item.extraData.smartContractAddress
                                                )
                                                    ? addCommas(
                                                          parseFloat(
                                                              dashboardSummaryData.transactionValue?.toFixed(
                                                                  2
                                                              )
                                                          )
                                                      ) || 0
                                                    : "-"}
                                            </td>
                                            <td className="text-center">
                                                {requestedData.campaignData.currentOrganizationCampaigns.find(
                                                    (item) => item.extraData.smartContractAddress
                                                )
                                                    ? addCommas(
                                                          parseFloat(
                                                              dashboardSummaryData.transactions?.toFixed(2)
                                                          )
                                                      ) || 0
                                                    : "-"}
                                            </td>
                                        </>
                                        {/* )} */}
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                )}
                {!isLoading.campaignDataIsLoading &&
                    !requestedData.campaignData.currentOrganizationCampaigns.length && (
                        <div className="no-data-cover"></div>
                    )}
            </div>
            {!requestedData.campaignData.currentOrganizationCampaigns.length ? (
                ""
            ) : (
                <div className={`pagination-wrapper  ${isLoading.campaignDataIsLoading ? "on-hold" : ""}`}>
                    <Pagination
                        callback={handlePagination}
                        currentPage={campaignsPage}
                        totalPages={campaignsTotalPages}
                        isLoading={isLoading.campaignDataIsLoading}
                    />
                    <div className="wrapper">
                        <p>items per page</p>
                        <Select
                            {...{
                                items: campaignsItemsPerPage,
                                setItems: setCampaignsItemsPerPage,
                                placeholder: "select 1",
                                variant: "outlined",
                                color: "dark",
                                size: "sm",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

// ===========================================================================================================
// const CAMPAIGN_STATUS = Object.freeze({
//     "Draft": 1,
//     "Moderation": 2,
//     "Rejected": 3,
//     "Ready": 4,
//     "Test run": 5,
//     "Working": 6,
//     "Paused": 7,
//     "Stopped": 8,
//     "Completed": 9,
//   });

// const showStopIcon = ["Working",'Paused · Daily Budget', "Moderation"];
// const showPlayIcon = [ "Stopped", "Draft", "Ready", "Test run"];

const showStopBtn = [6, 7, 2];
const showPlayBtn = [8, 1, 4, 5];

const CampaignRow = ({ item, index, handleMarkedCampaigns }) => {
    const {
        showCampaingWizardDrawer,
        setShowCampaignWizardDrawer,
        campaignWizardStatus,
        setCampaignWizardStatus,
        errorAlert,
        successAlert,
        campaignState,
        setCampaignState,
        requestedData,
        // activeCampaign,
        // setActiveCampaign,

        activeInfoId,
        setActiveInfoId,
        isDarkModeEnabled,
        getLoadCampaignData,
        setCreatives,
        campaignsState,
        conversionOptions: { advertiserPixelStats },
        markedCampaigns,
    } = useGlobalContext();
    const { campaignTabs, setCampaignTabs } = campaignsState;
    const { arrowDown, arrowUp, settings, play, stop, penV2, trashV2, close, info, copy } = localData.svgs;
    const { removeAlias, formatDate, addCommas, formatNumber, formatCurrency } = useFormatter();

    // const [isPaused, setIsPaused] = useState(false);
    const [campaignDetails, setCampaignDetails] = useState({
        isStopped: false,
        status: "unknown",
        statusNumber: null,
    });

    const [campaignBtns, setCampaignBtns] = useState({
        playBtn: {
            isReadOnly: true,
            message: "",
            isPlayBtnShown: false,
        },
        stopBtn: {
            isReadOnly: true,
            message: "",
            isStopBtnShown: false,
        },
        editBtn: {
            isReadOnly: true,
            message: "",
        },
        deleteBtn: {
            isReadOnly: true,
            message: "",
        },
        copyBtn: {
            isReadOnly: true,
            message: ""
        }
    });
    const [itemStatus,setitemStatus] = useState('');
    
    useEffect(() => {
        const isEditBtnShown = true;
        const isEditBtnReadOnly = [].includes(campaignDetails.statusNumber);

        const isDeleteBtnShown = true;
        const isDeleteBtnReadOnly = [1].includes(campaignDetails.statusNumber);

        const isStopBtnShown = showStopBtn.includes(campaignDetails.statusNumber);
        const isStopBtnReadOnly = false;

        const isPlayBtnShown = showPlayBtn.includes(campaignDetails.statusNumber);
        const isPlayBtnReadOnly = false;

        // const isLowBalance = requestedData.userProfileData.currentOrganization.balance < 330;
        const isLowBalance = false;

        const message = (
            <span>
                Can't be modified <br /> due to it's {item.status} status
            </span>
        );

        setCampaignBtns({
            ...campaignBtns,

            playBtn: {
                isReadOnly: isPlayBtnReadOnly || isLowBalance,
                message: isPlayBtnReadOnly ? message : "Insufficient balance",
                isPlayBtnShown,
            },
            stopBtn: {
                isReadOnly: isStopBtnReadOnly || isLowBalance,
                message: isStopBtnReadOnly ? message : "Insufficient balance",
                isStopBtnShown,
            },
            editBtn: {
                isReadOnly: isEditBtnReadOnly,
                message: message,
                isEditBtnShown,
            },
            deleteBtn: {
                isReadOnly: isDeleteBtnReadOnly,
                message: message,
                isDeleteBtnShown,
            },
        });

    }, [campaignDetails]);

    useEffect(() => {
        setitemStatus(item.status);
        setCampaignDetails((prev) => ({
            ...prev,
            isStopped: item.isPaused,
            status: item.status,
            statusNumber: item.statusNumber,
        }));
    }, []);

    // FETCH
    const [campaignInfoData, setCampaignInfoData] = useState({});
    const [isPlayPauseLoading, setIsPlayPauseLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    
    const { playOrPauseCampaign, deleteCampaign, sumTransactionQuantities } = useFetch();

    // PLAY OR PAUSE CAMPAIGN
    const getPlayOrPauseCampaign = async ({ campaignId, toPlayCampaign, campaignName, isRevive,  reviveID }) => {
        // if (!auth.currentUser) return;
        setIsPlayPauseLoading(true);

        const organizationID = requestedData.userProfileData.currentOrganization.id;
        const userEmail = requestedData.userProfileData.userEmail;

        const temRaw = {
            uuid: auth.currentUser.uid,
            userName: requestedData.userProfileData.userName,
            organizationID,
            adminEmail: userEmail,
            campaignId,
            campaignName,
            toPlayCampaign,
            isRevive: isRevive,
            reviveID
        };
        try {
            const data = await playOrPauseCampaign(() => {}, temRaw);
            const { isPaused, status = "unknown", statusNumber = null } = data.res_data;
            setCampaignDetails((prev) => ({
                ...prev,
                isStopped: isPaused,
                status: status.toLowerCase(),
                statusNumber,
            }));
            // getLoadCampaignData(requestedData.userProfileData.currentOrganization.id, 1, 15);
            setitemStatus(data.res_data.status.toLowerCase());
            console.log(data, "=playOrPauseCampaign= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=playOrPauseCampaign= request error");
        }
        setIsPlayPauseLoading(false);
    };

    const handleDeleteCampaign = async (campaign) => {
        setIsDeleteLoading(true);

        const temRaw = {
            uuid: auth.currentUser.uid,
            userName: requestedData.userProfileData.userName,
            campaignName: campaign.name,
            organizationID: requestedData.userProfileData.currentOrganization.id,
            campaignId: campaign.id,
            reviveID: campaign.reviveID
        };
        try {
            const data = await deleteCampaign(() => {}, temRaw);
            successAlert("Campaign deleted successfully");
            getLoadCampaignData(requestedData.userProfileData.currentOrganization.id, 1, 15);
            console.log(data, "=deleteCampaign= request");
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=deleteCampaign= request error");
        }
        setIsDeleteLoading(false);
    };

    const [displayDeleteModal, setDisplayDeleteModal] = useState("none");

    

    const DeleteCampaignModal = ({ setIsOpen }) => {
        const closeModal = () => {
            setIsOpen(false);
        };
        return (
            <>
                <div className="modal-header">
                    <Button
                        className="btn-close"
                        variant="circle"
                        color="dark"
                        size="sm"
                        icon={close}
                        onClick={closeModal}
                    />
                </div>
                <div className="modal-header" style={{ padding: "0px 1rem 20px" }}>
                    <h5 className="modal-title">Delete this campaign?</h5>
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="light"
                        style={{ marginRight: "10px" }}
                        onClick={closeModal}
                        name="cancel"
                        size="sm"
                    />
                    <Button
                        variant="contained"
                        color="danger"
                        onClick={() => {
                            closeModal();
                            handleDeleteCampaign(item);
                        }}
                        name="Confirm"
                        size="sm"
                    />
                </div>
            </>
        );
    };

    const [txnValue, setTxnValue] = useState("");
    const [txnValueIsLoading, setTxnValueIsLoading] = useState(false);

    // const getTxnValue = async ({ smartContractAddress = "" }) => {
    //     setTxnValueIsLoading((prev) => true);
    //     const endDate = new Date(); // today
    //     const startDate = new Date(endDate);
    //     startDate.setDate(endDate.getDate() - 6); // 7 days ago

    //     const selectionRangeFormatted = JSON.parse(localStorage.getItem("selectionRangeFormatted")) || {
    //         dateFrom: formatDate(startDate),
    //         dateTill: formatDate(endDate),
    //     };
    //     const tempRaw = {
    //         // uuid: auth.currentUser.uid,
    //         // chainNetwork,
    //         // smartContractAddress,
    //         // page,
    //         // pageSize,
    //         // order: "desc",
    //         smartContractAddress: [smartContractAddress],
    //         startDate: selectionRangeFormatted.dateFrom,
    //         endDate: selectionRangeFormatted.dateTill,
    //     };
    //     try {
    //         const data = await sumTransactionQuantities(() => {}, tempRaw);
    //         // console.log(data, "=sumTransactionQuantities= request");

    //         setTxnValue(data);
    //     } catch (err) {
    //         errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
    //         console.error(err, "=sumTransactionQuantities= request error");
    //     }
    //     setTxnValueIsLoading((prev) => false);
    // };

    // useEffect(() => {
    //     getTxnValue({ smartContractAddress: item.extraData.smartContractAddress });
    // }, []);

    // const [isWithSmartContractAddress, setIsWithSmartContractAddress] = useState(false);

    // useEffect(() => {
    //     const withAddress = activeCampaign?.extraData?.smartContractAddress ? true : false;
    //     setIsWithSmartContractAddress(withAddress);
    // }, [activeCampaign]);

    const [isMarked, setIsMarked] = useState(false);

    useEffect(() => {
        const marked = markedCampaigns.find((markedItem) => markedItem.id === item.id);
        setIsMarked(marked ? true : false);
    }, [markedCampaigns]);


    const navigate = useNavigate()

    console.log(item.creatives, "holaa mamacit");
    

    return (
        <>
            <tr
                className={`table-row ${isMarked ? "active" : ""} ${isDarkModeEnabled ? "dark-mode" : ""}`}
                onClick={() => {
                    // setActiveCampaign(item);
                    handleMarkedCampaigns(item, true);
                }}
            >
                <td>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            style={{ width: "0", marginLeft: 0 }}
                            callback={(isMarked) => handleMarkedCampaigns(item, isMarked)}
                            checked={isMarked}
                            color="primary"
                            size="sm"
                        />
                    </div>
                </td>
                {/* <td style={{ width: 0 }}>
                    <Radio
                        checked={activeCampaign?.id == item.id}
                        onClick={() => setActiveCampaign(item)}
                        // disabled={!item.extraData.smartContractAddress}
                        color={!item.extraData.smartContractAddress ? "secondary" : ""}
                        tooltip={!item.extraData.smartContractAddress ? "No record found." : ""}
                    />
                </td> */}
                <td className="table-td-hush">
                    <Button
                        variant="circle"
                        size="sm"
                        color="secondary"
                        // icon={activeRow === index ? eyeSlash : eye}
                        icon={activeInfoId === item.id ? arrowUp : arrowDown}
                        onClick={(e) => {
                            e.stopPropagation();
                            // setActiveRow(activeRow === index ? null : index);
                            setActiveInfoId(activeInfoId === item.id ? null : item.id);
                        }}
                    />
                </td>
                <td className="table-td-name text-start">
                    <span>{removeAlias(item.name)}</span>
                </td>
                <td className="table-td-settings text-center">
                    <div onClick={(e) => e.stopPropagation()} className="table-td-settings-wrapper">
                        <div
                            className={`play-pause-btn-wrapper  ${
                                isPlayPauseLoading ? "on-hold spin-loading" : ""
                            }`}
                        >
                            {campaignBtns.playBtn.isPlayBtnShown && (
                                <div
                                    data-tooltip-id="play-btn-tooltip"
                                    data-tooltip-html={
                                        campaignBtns.playBtn.isReadOnly
                                            ? ReactDOMServer.renderToStaticMarkup(
                                                  campaignBtns.playBtn.message
                                              )
                                            : ""
                                    }
                                >
                                    <Button
                                        tooltipContent={"Play"}
                                        variant="circle"
                                        color={"success"}
                                        size="sm"
                                        icon={play}
                                        onClick={() => {
                                            // getPlayOrPauseCampaign(item.id, true);
                                            getPlayOrPauseCampaign({
                                                campaignId: item.id,
                                                campaignName: removeAlias(item.name),
                                                toPlayCampaign: true,
                                                isRevive: item.isRevive,
                                                reviveID: item.reviveID
                                            });
                                        }}
                                        className="play-pause"
                                        disabled={campaignBtns.playBtn.isReadOnly}
                                    />
                                    <Tooltip id="play-btn-tooltip" className="custom-tooltip" />
                                </div>
                            )}
                            {campaignBtns.stopBtn.isStopBtnShown && (
                                <div
                                    className="play-pause-btn-wrapper-inner"
                                    data-tooltip-id="stop-btn-tooltip"
                                    data-tooltip-html={
                                        campaignBtns.stopBtn.isReadOnly
                                            ? ReactDOMServer.renderToStaticMarkup(
                                                  campaignBtns.stopBtn.message
                                              )
                                            : ""
                                    }
                                >
                                    {/* <>
                                        <div
                                            data-tooltip-id={`warning-tooltip-stop-play-pause`}
                                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                <p>Lorem Ipsum is simply dummy text of the<br/> printing and typesetting industry.</p>
                                            )}
                                            className="info-popup info-popup-warning"
                                        >
                                            {info}
                                        </div>
                                        <Tooltip
                                            id={`warning-tooltip-stop-play-pause`}
                                            className="custom-tooltip warning"
                                        />
                                    </> */}
                                    <Button
                                        tooltipContent={`Stop`}
                                        // tooltipBottomContent={
                                        //     <div>
                                        //         Lorem Ipsum is simply dummy text of the <br /> printing and
                                        //         typesetting industry.
                                        //     </div>
                                        // }

                                        variant="circle"
                                        color={"danger"}
                                        size="sm"
                                        icon={stop}
                                        onClick={() => {
                                            // getPlayOrPauseCampaign(item.id, false);
                                            getPlayOrPauseCampaign({
                                                campaignId: item.id,
                                                campaignName: removeAlias(item.name),
                                                toPlayCampaign: false,
                                                isRevive: item.isRevive,
                                                reviveID: item.reviveID
                                            });
                                        }}
                                        className="play-pause"
                                        disabled={campaignBtns.stopBtn.isReadOnly}
                                    />
                                    <Tooltip id="stop-btn-tooltip" className="custom-tooltip" />
                                </div>
                            )}
                        </div>
                        <div
                            className={`edit-btn-wrapper ${false ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="edit-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.editBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.editBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Edit"}
                                variant="circle"
                                // color="secondary"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={penV2}
                                onClick={() => {
                                    setCampaignState({
                                        ...campaignState,
                                        campaignId: item.id,
                                        creatorEmail: requestedData.userProfileData.userEmail,
                                        campaignInfo: {
                                            ...campaignState.campaignInfo,
                                            campaignName: item.name,
                                            targetURL: item.extraData.targetURL,
                                            smartContractAddress: item.extraData.smartContractAddress,
                                            campaignType: item.extraData.campaignType || [],
                                            // budget:
                                            //     item.total_amount >= 360
                                            //         ? item.total_amount.toString()
                                            //         : "360",
                                            portfolioBalance: item.extraData.portfolioBalance,
                                            competitorSmartContracts: item.extraData.competitorSmartContracts,
                                            budget:  item.total_amount.toString(),
                                            maxCPC: item.max_cpc,
                                            startDate: item.creation_date,
                                            endDate: item.endDate || item.end_date || "",
                                            // endDate: '',
                                            category: item.extraData.category || [],
                                            web3Audience: item.extraData.web3Audience || [],
                                            // chainTargeting: item.extraData.chainTargeting || [],
                                            chainTargeting: Array.isArray(item.extraData.chainTargeting)
                                                ? item.extraData.chainTargeting
                                                : [],
                                            walletTargeting: item.extraData.walletTargeting || [],
                                            deviceType: item.extraData.deviceType || [],
                                            operativeSystem: item.extraData.operativeSystem || [],
                                            browser: item.extraData.browser || [],
                                            countriesTargeting: item.extraData.countriesTargeting || [
                                                "ad",
                                                "af",
                                                "ag",
                                                "ai",
                                                "al",
                                                "am",
                                                "an",
                                                "ao",
                                                "aq",
                                                "ar",
                                                "as",
                                                "at",
                                                "au",
                                                "aw",
                                                "ax",
                                                "az",
                                                "ba",
                                                "bb",
                                                "bd",
                                                "be",
                                                "bf",
                                                "bg",
                                                "bh",
                                                "bi",
                                                "bj",
                                                "bl",
                                                "bm",
                                                "bn",
                                                "bo",
                                                "bq",
                                                "br",
                                                "bs",
                                                "bt",
                                                "bv",
                                                "bw",
                                                "by",
                                                "bz",
                                                "ca",
                                                "cc",
                                                "cd",
                                                "cf",
                                                "cg",
                                                "ch",
                                                "ci",
                                                "ck",
                                                "cl",
                                                "cm",
                                                "cn",
                                                "co",
                                                "cr",
                                                "cu",
                                                "cv",
                                                "cw",
                                                "cx",
                                                "cy",
                                                "cz",
                                                "de",
                                                "dj",
                                                "dk",
                                                "dm",
                                                "do",
                                                "dz",
                                                "ec",
                                                "ee",
                                                "eg",
                                                "eh",
                                                "er",
                                                "es",
                                                "et",
                                                "fi",
                                                "fj",
                                                "fk",
                                                "fm",
                                                "fo",
                                                "fr",
                                                "ga",
                                                "gb",
                                                "gd",
                                                "ge",
                                                "gf",
                                                "gg",
                                                "gh",
                                                "gi",
                                                "gl",
                                                "gm",
                                                "gn",
                                                "gp",
                                                "gq",
                                                "gr",
                                                "gs",
                                                "gt",
                                                "gu",
                                                "gw",
                                                "gy",
                                                "hk",
                                                "hm",
                                                "hn",
                                                "hr",
                                                "ht",
                                                "hu",
                                                "id",
                                                "ie",
                                                "il",
                                                "im",
                                                "in",
                                                "io",
                                                "iq",
                                                "ir",
                                                "is",
                                                "it",
                                                "je",
                                                "jm",
                                                "jo",
                                                "jp",
                                                "ke",
                                                "kg",
                                                "kh",
                                                "ki",
                                                "km",
                                                "kn",
                                                "kp",
                                                "kr",
                                                "kw",
                                                "ky",
                                                "kz",
                                                "la",
                                                "lb",
                                                "lc",
                                                "li",
                                                "lk",
                                                "lr",
                                                "ls",
                                                "lt",
                                                "lu",
                                                "lv",
                                                "ly",
                                                "ma",
                                                "mc",
                                                "md",
                                                "me",
                                                "mf",
                                                "mg",
                                                "mh",
                                                "mk",
                                                "ml",
                                                "mm",
                                                "mn",
                                                "mo",
                                                "mp",
                                                "mq",
                                                "mr",
                                                "ms",
                                                "mt",
                                                "mu",
                                                "mv",
                                                "mw",
                                                "mx",
                                                "my",
                                                "mz",
                                                "na",
                                                "nc",
                                                "ne",
                                                "nf",
                                                "ng",
                                                "ni",
                                                "nl",
                                                "no",
                                                "np",
                                                "nr",
                                                "nu",
                                                "nz",
                                                "om",
                                                "pa",
                                                "pe",
                                                "pf",
                                                "pg",
                                                "ph",
                                                "pk",
                                                "pl",
                                                "pm",
                                                "pn",
                                                "pr",
                                                "ps",
                                                "pt",
                                                "pw",
                                                "py",
                                                "qa",
                                                "re",
                                                "ro",
                                                "rs",
                                                "ru",
                                                "rw",
                                                "sa",
                                                "sb",
                                                "sc",
                                                "sd",
                                                "se",
                                                "sg",
                                                "sh",
                                                "si",
                                                "sj",
                                                "sk",
                                                "sl",
                                                "sm",
                                                "sn",
                                                "so",
                                                "sr",
                                                "ss",
                                                "st",
                                                "sv",
                                                "sx",
                                                "sy",
                                                "sz",
                                                "tc",
                                                "td",
                                                "tf",
                                                "tg",
                                                "th",
                                                "tj",
                                                "tk",
                                                "tl",
                                                "tm",
                                                "tn",
                                                "to",
                                                "tr",
                                                "tt",
                                                "tv",
                                                "tw",
                                                "tz",
                                                "ua",
                                                "ug",
                                                "um",
                                                "us",
                                                "uy",
                                                "uz",
                                                "va",
                                                "vc",
                                                "ve",
                                                "vg",
                                                "vi",
                                                "vn",
                                                "vu",
                                                "wf",
                                                "ws",
                                                "ye",
                                                "yt",
                                                "za",
                                                "zm",
                                                "zw",
                                            ],
                                        },
                                        // creatives: item.creatives,
                                    });
                                    setCreatives(
                                        item.creatives.map((item) => {
                                            return {
                                                ...item,
                                                // apiImage: item.image,
                                                // editId: item.id,
                                                readOnly: true,
                                            };
                                        })
                                    );
                                    setCampaignWizardStatus("edit");
                                    // setShowCampaignWizardDrawer("block");
                                    navigate("/campaigns/edit")
                                    setCampaignTabs({
                                        ...campaignTabs,
                                        // marked: [...campaignTabs.marked, 1, 2, 3],
                                        // disabled: [...campaignTabs.disabled, 2],
                                        // clickable: [...campaignTabs.clickable, 1, 3],

                                        marked: [...campaignTabs.marked, 1, 2, 3],
                                        // disabled: [...campaignTabs.disabled, 2],
                                        clickable: [...campaignTabs.clickable, 1, 2, 3],
                                    });
                                }}
                                disabled={campaignBtns.editBtn.isReadOnly}
                            />
                            {campaignBtns.editBtn.isReadOnly && (
                                <Tooltip id="edit-btn-wrapper-tooltip" className="custom-tooltip" />
                            )}
                        </div>
                        {/* <div
                            className={`delete-btn-wrapper ${isDeleteLoading ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="delete-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.deleteBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.deleteBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Copy Campaign"}
                                variant="circle"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={copy}
                                onClick={() => {
                                    setCampaignState({
                                        ...campaignState,
                                        campaignId: item.id,
                                        creatorEmail: requestedData.userProfileData.userEmail,
                                        campaignInfo: {
                                            ...campaignState.campaignInfo,
                                            campaignName: item.name,
                                            targetURL: item.extraData.targetURL,
                                            smartContractAddress: item.extraData.smartContractAddress,
                                            campaignType: item.extraData.campaignType || [],
                                            portfolioBalance: item.extraData.portfolioBalance,
                                            competitorSmartContracts: item.extraData.competitorSmartContracts,
                                            budget:  item.total_amount.toString(),
                                            maxCPC: item.max_cpc,
                                            startDate: item.creation_date,
                                            endDate: item.endDate || item.end_date || "",
                                            category: item.extraData.category || [],
                                            web3Audience: item.extraData.web3Audience || [],
                                            chainTargeting: Array.isArray(item.extraData.chainTargeting)
                                                ? item.extraData.chainTargeting
                                                : [],
                                            walletTargeting: item.extraData.walletTargeting || [],
                                            deviceType: item.extraData.deviceType || [],
                                            operativeSystem: item.extraData.operativeSystem || [],
                                            browser: item.extraData.browser || [],
                                            countriesTargeting: item.extraData.countriesTargeting || [
                                                "ad",
                                                "af",
                                                "ag",
                                                "ai",
                                                "al",
                                                "am",
                                                "an",
                                                "ao",
                                                "aq",
                                                "ar",
                                                "as",
                                                "at",
                                                "au",
                                                "aw",
                                                "ax",
                                                "az",
                                                "ba",
                                                "bb",
                                                "bd",
                                                "be",
                                                "bf",
                                                "bg",
                                                "bh",
                                                "bi",
                                                "bj",
                                                "bl",
                                                "bm",
                                                "bn",
                                                "bo",
                                                "bq",
                                                "br",
                                                "bs",
                                                "bt",
                                                "bv",
                                                "bw",
                                                "by",
                                                "bz",
                                                "ca",
                                                "cc",
                                                "cd",
                                                "cf",
                                                "cg",
                                                "ch",
                                                "ci",
                                                "ck",
                                                "cl",
                                                "cm",
                                                "cn",
                                                "co",
                                                "cr",
                                                "cu",
                                                "cv",
                                                "cw",
                                                "cx",
                                                "cy",
                                                "cz",
                                                "de",
                                                "dj",
                                                "dk",
                                                "dm",
                                                "do",
                                                "dz",
                                                "ec",
                                                "ee",
                                                "eg",
                                                "eh",
                                                "er",
                                                "es",
                                                "et",
                                                "fi",
                                                "fj",
                                                "fk",
                                                "fm",
                                                "fo",
                                                "fr",
                                                "ga",
                                                "gb",
                                                "gd",
                                                "ge",
                                                "gf",
                                                "gg",
                                                "gh",
                                                "gi",
                                                "gl",
                                                "gm",
                                                "gn",
                                                "gp",
                                                "gq",
                                                "gr",
                                                "gs",
                                                "gt",
                                                "gu",
                                                "gw",
                                                "gy",
                                                "hk",
                                                "hm",
                                                "hn",
                                                "hr",
                                                "ht",
                                                "hu",
                                                "id",
                                                "ie",
                                                "il",
                                                "im",
                                                "in",
                                                "io",
                                                "iq",
                                                "ir",
                                                "is",
                                                "it",
                                                "je",
                                                "jm",
                                                "jo",
                                                "jp",
                                                "ke",
                                                "kg",
                                                "kh",
                                                "ki",
                                                "km",
                                                "kn",
                                                "kp",
                                                "kr",
                                                "kw",
                                                "ky",
                                                "kz",
                                                "la",
                                                "lb",
                                                "lc",
                                                "li",
                                                "lk",
                                                "lr",
                                                "ls",
                                                "lt",
                                                "lu",
                                                "lv",
                                                "ly",
                                                "ma",
                                                "mc",
                                                "md",
                                                "me",
                                                "mf",
                                                "mg",
                                                "mh",
                                                "mk",
                                                "ml",
                                                "mm",
                                                "mn",
                                                "mo",
                                                "mp",
                                                "mq",
                                                "mr",
                                                "ms",
                                                "mt",
                                                "mu",
                                                "mv",
                                                "mw",
                                                "mx",
                                                "my",
                                                "mz",
                                                "na",
                                                "nc",
                                                "ne",
                                                "nf",
                                                "ng",
                                                "ni",
                                                "nl",
                                                "no",
                                                "np",
                                                "nr",
                                                "nu",
                                                "nz",
                                                "om",
                                                "pa",
                                                "pe",
                                                "pf",
                                                "pg",
                                                "ph",
                                                "pk",
                                                "pl",
                                                "pm",
                                                "pn",
                                                "pr",
                                                "ps",
                                                "pt",
                                                "pw",
                                                "py",
                                                "qa",
                                                "re",
                                                "ro",
                                                "rs",
                                                "ru",
                                                "rw",
                                                "sa",
                                                "sb",
                                                "sc",
                                                "sd",
                                                "se",
                                                "sg",
                                                "sh",
                                                "si",
                                                "sj",
                                                "sk",
                                                "sl",
                                                "sm",
                                                "sn",
                                                "so",
                                                "sr",
                                                "ss",
                                                "st",
                                                "sv",
                                                "sx",
                                                "sy",
                                                "sz",
                                                "tc",
                                                "td",
                                                "tf",
                                                "tg",
                                                "th",
                                                "tj",
                                                "tk",
                                                "tl",
                                                "tm",
                                                "tn",
                                                "to",
                                                "tr",
                                                "tt",
                                                "tv",
                                                "tw",
                                                "tz",
                                                "ua",
                                                "ug",
                                                "um",
                                                "us",
                                                "uy",
                                                "uz",
                                                "va",
                                                "vc",
                                                "ve",
                                                "vg",
                                                "vi",
                                                "vn",
                                                "vu",
                                                "wf",
                                                "ws",
                                                "ye",
                                                "yt",
                                                "za",
                                                "zm",
                                                "zw",
                                            ],
                                        },
                                    });
                                    setCreatives(
                                        item.creatives.map((item) => {
                                            return {
                                                ...item,
                                                // apiImage: item.image,
                                                // editId: item.id,
                                                readOnly: true,
                                            };
                                        })
                                    );
                                    navigate("/campaigns/create")
                                    setCampaignWizardStatus("copy")
                                
                                }}
                                disabled={campaignBtns.deleteBtn.isReadOnly}
                            />
                        </div> */}
                        
                        <div
                            className={`delete-btn-wrapper ${isDeleteLoading ? "on-hold spin-loading" : ""}`}
                            data-tooltip-id="delete-btn-wrapper-tooltip"
                            data-tooltip-html={
                                campaignBtns.deleteBtn.isReadOnly
                                    ? ReactDOMServer.renderToStaticMarkup(campaignBtns.deleteBtn.message)
                                    : ""
                            }
                        >
                            <Button
                                tooltipContent={"Delete"}
                                variant="circle"
                                // color="secondary"
                                color={isDarkModeEnabled ? "light" : "secondary"}
                                size="sm"
                                icon={trashV2}
                                // disabled={isDeleteLoading}
                                // onClick={() => handleDeleteCampaign(item.id)}
                                onClick={() => {
                                    setDisplayDeleteModal("block");
                                }}
                                disabled={campaignBtns.deleteBtn.isReadOnly}
                            />
                            {campaignBtns.deleteBtn.isReadOnly && (
                                <Tooltip id="delete-btn-wrapper-tooltip" className="custom-tooltip" />
                            )}
                            <Modal
                                Toggler={() => {}}
                                Child={DeleteCampaignModal}
                                // modalCloseCallback={modalCloseCallback}
                                display={displayDeleteModal}
                                setDisplay={setDisplayDeleteModal}
                                className="modal-dialog-centered modal-sm"
                            />
                        </div>
                    </div>
                </td>
                <td className="table-td-status text-center">
                    <div
                        data-tooltip-id="status-tooltip"
                        data-tooltip-html={
                            itemStatus === "Paused · Daily Budget"
                                ? ReactDOMServer.renderToStaticMarkup(
                                      <span>Daily budget limit reached.</span>
                                  )
                                : ""
                        }
                        className={`status ${
                            itemStatus === "Paused · Daily Budget"
                                ? "paused-daily-budget"
                                : itemStatus
                        }`}
                    >
                        {/* {item.status} */}
                        {itemStatus == "Paused · Daily Budget"
                            ? "working"
                            : itemStatus}
                    </div>
                    <Tooltip id="status-tooltip" className="custom-tooltip" />
                </td>
                <td className="table-td-impressions text-center">{addCommas(Math.floor(item?.impressions)) || 0}</td>
                <td className="table-td-clicks text-center">{addCommas(item.clicks) || 0}</td>
                <td className="table-td-ctr text-center">{item.CTR.toFixed(2)}%</td>
                <td className="table-td-ad-spend text-center">{formatCurrency(item.amount_spent, 2)}</td>
                <td className="table-td-cpc text-center">{formatCurrency(item.CPC, 2)}</td>
                <td className="table-td-conversion text-center">
                    {/* <span>
                        {advertiserPixelStats.isLoading
                            ? "Loading..."
                            : advertiserPixelStats.value.length
                            ? advertiserPixelStats.value.find(statItem=>statItem.bca_ID == item.id)?.conversionCount || 0
                            : 0}
                    </span> */}
                    {/* {item.pixelConversionStats[0]?.conversionCount || 0} */}
                    {addCommas(parseFloat(item.conversions?.toFixed(2))) || 0}
                </td>
                {/* {isWithSmartContractAddress && ( */}
                <>
                    <td className="table-td-txn-value text-center">
                        {item.extraData.smartContractAddress
                            ? addCommas(parseFloat(item.transactionValue?.toFixed(2))) || 0
                            : "-"}
                    </td>
                    <td className="table-td-transactions text-center">
                        {item.extraData.smartContractAddress
                            ? addCommas(parseFloat(item.transactions?.toFixed(2))) || 0
                            : "-"}
                    </td>
                </>
                {/* )} */}
            </tr>
            {activeInfoId === item.id && (
                <tr className="campaign-details-tr">
                    <td colSpan={14} className="campaign-info-td">
                        <CampaignInfo data={item} isLoading={false} />
                    </td>
                </tr>
            )}
        </>
    );
};

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import localData from "../../localData";
import { Button } from "../../components";
import useFormatter from "../useFormatter";
import { PRProducts } from "../../utils/PRproducts";
import { PRProductsCrypto } from "../../utils/PRProductsCrypto";

const {
  avatar,
  user,
  cryptoPotato,
  coinTelegraph,
  coinDesk,
  thedefiant,
  bitcoinLogo,
  bitcoin,
  beInCrypto,
  decryptLogoPng,
  techTimes,
  uToday,
  hackernoon,
  dappRadar,
  bloomberg,
  theDailyHodl,
  startupFortune,
  techBoolean,
  publish0x,
  benzinga,
  yahoo,
  tapChi,
  CoincoDex,
  gritDaily,
  cryptoNews,
  bscNews,
} = localData.images;

const fetchedData = [
  {
    id: "featured-1-103803391c1d",
    packageName: "Featured 1",
    packageShortName: "featured 1",
    packageType: "Enterprise",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "70000",
    category: "crypto",
    isFeatured: true,
    extraSource: ["+120 Additional Placements"],
    totalReach: "250M+",
    products: [...PRProducts],
    included: [
      "PR Guaranteed on Cointelegraph, Bitcoin.com and BeInCrypto",
      "PR Published on up to 125 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
      "125 Guaranteed Press Releases on leading news crypto websites (cryptonews.com, cryptopotato.com, cryptoslate.com, etc)",
      "Twitter Post done by Bitcoin.com (2.5M Followers Verified account)",
      "Facebook Post done by Bitcoin.com (300K Followers)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
      "PR writing upon request",
    ],
  },
  //     {
  //     id: "featured-2-103803391c1d",
  //     packageName: "Featured 2",
  //     packageShortName: "featured 2",
  //     packageType: "Best Seller",
  //     isInShoppingCart: false,
  //     isInWishlist: false,
  //     cover: "",
  //     totalPrice: "50000",
  //     category: "crypto",
  //     isFeatured: true,
  //     extraSource: ["+90 Additional Placements"],
  //     totalReach: "Publication potential reach: 1.75B+",
  //     products: [
  //         { media: "Cointelegraph", logo: coinTelegraph },
  //         { media: "CoinDesk", logo: coinDesk },
  //         { media: "Bitcoin.com", logo: bitcoin },
  //         { media: "Decrypt", logo: decryptLogoPng },
  //         { media: "Cryptopotato", logo: cryptoPotato },
  //         { media: "thedefiant.io", logo: thedefiant },
  //     ],
  //     included: [
  //         "PR Guaranteed on Cointelegraph, Bitcoin.com and BeInCrypto",
  //         "PR Published on up to 125 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
  //         "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
  //         "125 Guaranteed Press Releases on leading news crypto websites (cryptonews.com, cryptopotato.com, cryptoslate.com, etc)",
  //         "Twitter Post done by Bitcoin.com (2.5M Followers Verified account)",
  //         "Facebook Post done by Bitcoin.com (300K Followers)",
  //         "Your Project Featured on the homepage of all news outlets",
  //         "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
  //         "Up to 2 images per article",
  //         "Transparent and detailed reporting",
  //         "PR writing upon request",
  //     ],
  // },
  {
    id: "featured-3-103803391c1d",
    packageName: "Featured 3",
    packageShortName: "featured 3",
    packageType: "Premium",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "50000",
    isFeatured: true,
    category: "crypto",
    extraSource: ["+60 Additional Placements"],
    totalReach: "140M+",
    products: [
      { media: "Cointelegraph Lite", logo: coinTelegraph },
      { media: "BeInCrypto", logo: beInCrypto },
      {
        media: "Bitcoin.com", logo: bitcoinLogo
      },
      {
        media: "Decrypt",
      },
      {
        media: "Cryptopotato",
      },
      {
        media: "thedefiant.io",
      },
      {
        media: "coingape.com",
      },
      {
        media: "newsbtc.com",
      },
      {
        media: "cryptodaily.co.uk",
      },
      {
        media: "cryptoslate.com",
      },
      {
        media: "dailyhodl.com",
      },
      {
        media: "bitcoinist.com",
      },
      {
        media: "coinpedia.org",
      },
      {
        media: "blockonomi.com",
      },
      {
        media: "coinedition.com",
      },
      {
        media: "cryptowisser.com",
      },
      {
        media: "coincheckup.com",
      },
      {
        media: "cryptonewsflash.com",
      },
      {
        media: "cryptonews.net",
      },
      {
        media: "bravenewcoin.com",
      },
      {
        media: "coinspeaker.com",
      },
      {
        media: "analyticsinsight.net",
      },
      {
        media: "thecryptobasic.com",
      },
      {
        media: "cryptomode.com",
      },
      {
        media: "thecryptotime.com",
      },
      {
        media: "bitcoininsider.org",
      },
      {
        media: "cripplly.com",
      },
      {
        media: "cryptoate.com",
      },
      {
        media: "cryptocurrencyfinancial.org",
      },
      {
        media: "cryptomanias.org",
      },
      {
        media: "cryptopost.us",
      },
      {
        media: "cryptopress.uk",
      },
      {
        media: "cryptoprint.co",
      },
      {
        media: "cryptoroof.org",
      },
      {
        media: "cryptoscrollen.com",
      },
      {
        media: "cryptothrive.news",
      },
      {
        media: "cryptowires.net",
      },
      {
        media: "defilust.com",
      },
      {
        media: "easyfinancetips.com",
      },
      {
        media: "energizium.com",
      },
      {
        media: "financialspeaks.com",
      },
      {
        media: "geekocoin.net",
      },
      {
        media: "kryptoscroll.com",
      },
      {
        media: "nftscreen.co",
      },
      {
        media: "pentaxcoin.com",
      },
      {
        media: "thecoinstudy.com",
      },
      {
        media: "thefinanceglobe.com",
      },
      {
        media: "therobusthealth.com",
      },
      {
        media: "topwealthyways.com",
      },
      {
        media: "zecripto.com",
      },
      {
        media: "blockpost.xyz",
      },
      {
        media: "blockreach.net",
      },
      {
        media: "coinblast.co",
      },
      {
        media: "coinjet.info",
      },
      {
        media: "coinkindle.org",
      },
      {
        media: "thebuzzuniverse.com",
      },
      {
        media: "wealthpills.com",
      },
      {
        media: "bestknownfinance.com",
      },
      {
        media: "newsflurry.com",
      },
      {
        media: "ulaska.com",
      },
      {
        media: "greencrestcapital.com",
      },
      {
        media: "cryptosisnews.com",
      },
      {
        media: "defijournal.net",
      },
      {
        media: "techbunch.co",
      },
      {
        media: "techexplore.co",
      },
      {
        media: "techfluxnews.com",
      },
      {
        media: "techshelf.net",
      },
      {
        media: "techbeam.co",
      },
      {
        media: "techvibe9.com",
      },
      {
        media: "pressbase.co",
      },
      {
        media: "dubaiuncovered.com",
      },
      {
        media: "futuretechdubai.com",
      },
      {
        media: "dubaigeek9.com",
      },
      {
        media: "bscbuzz.com",
      },
      {
        media: "blockscooop.com",
      },
      {
        media: "cryptobulletin.net",
      },
    ],
    included: [
      "PR Guaranteed on Cointelegraph",
      "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "PR Guaranteed on Mainstream Media + Yahoo + Benzinga + MarketWatch",
      "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
      "PR writing upon request",
    ],
  },
  {
    id: "featured-4-103803391c1d",
    packageName: "Featured 4",
    packageShortName: "featured 4",
    packageType: "Advanced",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "20000",
    category: "crypto",
    isFeatured: true,
    extraSource: ["+35 Additional Placements"],
    totalReach: "50M+",
    products: [
      { media: "Bitcoin", logo: bitcoin },
      {
        media: "Decrypt", logo: decryptLogoPng
      },
      {
        media: "Cryptopotato",
      },
      {
        media: "thedefiant.io",
      },
      {
        media: " + 35 Additional Placements",
      },
      {
        media: "coingape.com",
      },
      {
        media: "newsbtc.com",
      },
      {
        media: "cryptodaily.co.uk",
      },
      {
        media: "cryptoslate.com",
      },
      {
        media: "thefinanceglobe.com",
      },
      {
        media: "therobusthealth.com",
      },
      {
        media: "topwealthyways.com",
      },
      {
        media: "zecripto.com",
      },
      {
        media: "blockpost.xyz",
      },
      {
        media: "blockreach.net",
      },
      {
        media: "coinblast.co",
      },
      {
        media: "coinjet.info",
      },
      {
        media: "coinkindle.org",
      },
      {
        media: "thebuzzuniverse.com",
      },
      {
        media: "wealthpills.com",
      },
      {
        media: "bestknownfinance.com",
      },
      {
        media: "newsflurry.com",
      },
      {
        media: "ulaska.com",
      },
      {
        media: "greencrestcapital.com",
      },
      {
        media: "cryptosisnews.com",
      },
      {
        media: "defijournal.net",
      },
      {
        media: "techbunch.co",
      },
      {
        media: "techexplore.co",
      },
      {
        media: "techfluxnews.com",
      },
      {
        media: "techshelf.net",
      },
      {
        media: "techbeam.co",
      },
      {
        media: "techvibe9.com",
      },
      {
        media: "pressbase.co",
      },
      {
        media: "dubaiuncovered.com",
      },
      {
        media: "futuretechdubai.com",
      },
      {
        media: "dubaigeek9.com",
      },
      {
        media: "bscbuzz.com",
      },
      {
        media: "blockscooop.com",
      },
      {
        media: "cryptobulletin.net",
      },
    ],
    included: [
      "    PR Guaranteed on Cointelegraph                                                                                                                                                                                                                                                                                                                                                                  ",
      "PR Published on up to 35 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
    ],
  },
  {
    id: "featured-5-103803391c1d",
    packageName: "Featured 5",
    packageShortName: "featured 5",
    packageType: "Enterprise",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "45000",
    category: "casino",
    isFeatured: true,
    extraSource: ["+125 Crypto Websites"],
    totalReach: "175M+",
    products: [
      { media: "Tapchibitcoin", logo: tapChi },
      { media: "CoincoDex", logo: CoincoDex },
      { media: "Gritdaily", logo: gritDaily },
      { media: "CryptoNews", logo: cryptoNews },
      {
        "media": "Cryptonewsflash"
    },
    {
        "media": "BSC News"
    },
    {
        "media": "BlockchainMagazine"
    },
    {
        "media": " + 100 additional placements"
    },
    {
        "media": "Cryptowisser"
    },
    {
        "media": "Blockchain.news"
    },
    {
        "media": "alexablockchain"
    },
    {
        "media": "Digital Coin Price"
    },
    {
        "media": "Telegaon"
    },
    {
        "media": "Coinwire"
    },
    {
        "media": "Timesofcasino"
    },
    {
        "media": "Tintucbitcoin"
    },
    {
        "media": "Coincu"
    },
    {
        "media": "WalletInvestor"
    },
    {
        "media": "Bitcoinik"
    },
    {
        "media": "0xzx"
    },
    {
        "media": "TheCoinRepublic"
    },
    {
        "media": "ETHNews"
    },
    {
        "media": "Coinguitar"
    },
    {
        "media": "virlan"
    },
    {
        "media": "Bitcoininsider"
    },
    {
        "media": "Use the Bitcoin"
    },
    {
        "media": "BitcoinNews.ch"
    },
    {
        "media": "TokenTroop"
    },
    {
        "media": "NFT News Today"
    },
    {
        "media": "Coinscreed"
    },
    {
        "media": "TheCoinrise"
    },
    {
        "media": "Crypto Economy"
    },
    {
        "media": "CoinChapter"
    },
    {
        "media": "Coinworldstory"
    },
    {
        "media": "Thecryptoupdates.com"
    },
    {
        "media": "Theblockopedia.com"
    },
    {
        "media": "Prices.org"
    },
    {
        "media": "TheMarketPeriodical"
    },
    {
        "media": "Visionary Finance"
    },
    {
        "media": "CryptoBrowser"
    },
    {
        "media": "AirdropsMob"
    },
    {
        "media": "Coinfunda"
    },
    {
        "media": "blockcrux.com"
    },
    {
        "media": "Coingabbar"
    },
    {
        "media": "Vietnamese Insider"
    },
    {
        "media": "Cryptodnes"
    },
    {
        "media": "Timestabloid"
    },
    {
        "media": "topcom"
    },
    {
        "media": "defidraft.com"
    },
    {
        "media": "Kriptoup"
    },
    {
        "media": "ulaska.com"
    },
    {
        "media": "blockscroll.org"
    },
    {
        "media": "coincript.com"
    },
    {
        "media": "coinmes.com"
    },
    {
        "media": "cripplly.com"
    },
    {
        "media": "cryptoate.com"
    },
    {
        "media": "cryptocurrencyfinancial.org"
    },
    {
        "media": "geekocoin.net"
    },
    {
        "media": "kryptoscroll.com"
    },
    {
        "media": "pentaxcoin.com"
    },
    {
        "media": "newsflurry.com"
    },
    {
        "media": "altpoint.co"
    },
    {
        "media": "bitcrux.net"
    },
    {
        "media": "bitscoop.net"
    },
    {
        "media": "blockcruck.com"
    },
    {
        "media": "blockhubs.co"
    },
    {
        "media": "blocknow.net"
    },
    {
        "media": "blockorn.co"
    },
    {
        "media": "blockphere.com"
    },
    {
        "media": "blockpost.xyz "
    },
    {
        "media": "blockreach.net "
    },
    {
        "media": "blockspy.org"
    },
    {
        "media": "blockstory.co"
    },
    {
        "media": "btccrux.com"
    },
    {
        "media": "btcdaily.org"
    },
    {
        "media": "btcinsider.net"
    },
    {
        "media": "btcjournal.co"
    },
    {
        "media": "btcsonic.xyz"
    },
    {
        "media": "btcstory.xyz"
    },
    {
        "media": "coinblast.co "
    },
    {
        "media": "coinjet.info "
    },
    {
        "media": "coinnewspan.com"
    },
    {
        "media": "coinnoble.com"
    },
    {
        "media": "coinolly.com"
    },
    {
        "media": "cryptopost.us"
    },
    {
        "media": "cryptopress.uk"
    },
    {
        "media": "cryptoprint.co"
    },
    {
        "media": "cryptoscrollen.com"
    },
    {
        "media": "cryptowires.net"
    },
    {
        "media": "defilust.com"
    },
    {
        "media": "nftscreen.co"
    },
    {
        "media": "defijournal.net"
    },
    {
        "media": "coinkindle.org "
    },
    {
        "media": "coinspit.co"
    },
    {
        "media": "cryptomanias.org"
    },
    {
        "media": "cryptoroof.org"
    },
    {
        "media": "cryptothrive.news"
    },
    {
        "media": "thebuzzuniverse.com"
    },
    {
        "media": "thecoinstudy.com"
    },
    {
        "media": "therobusthealth.com"
    }
    ],
    included: [
      "PR Guaranteed on Cointelegraph, Bitcoin.com and BeInCrypto",
      "PR Published on up to 125 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
      "125 Guaranteed Press Releases on leading news crypto websites (cryptonews.com, cryptopotato.com, cryptoslate.com, etc)",
      "Twitter Post done by Bitcoin.com (2.5M Followers Verified account)",
      "Facebook Post done by Bitcoin.com (300K Followers)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
      "PR writing upon request",
    ],
  },
  {
    id: "featured-6-103803391c1d",
    packageName: "Featured 6",
    packageShortName: "featured 6",
    packageType: "Premium",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "30000",
    isFeatured: true,
    category: "casino",
    extraSource: ["+75 Crypto Websites"],
    totalReach: "100M+",
    products: [
      { media: "Gritdaily", logo: gritDaily },
      { media: "Crypto.news", logo: cryptoNews },
      {
        "media": "Cryptonewsflash"
    },
    {
        "media": "BSC News"
    },
    {
        "media": "BlockchainMagazine"
    },
    {
        "media": " + 80 Additinal Placements"
    },
    {
        "media": "TheCoinRepublic"
    },
    {
        "media": "ETHNews"
    },
    {
        "media": "Coinguitar"
    },
    {
        "media": "virlan"
    },
    {
        "media": "Bitcoininsider"
    },
    {
        "media": "Use the Bitcoin"
    },
    {
        "media": "BitcoinNews.ch"
    },
    {
        "media": "TokenTroop"
    },
    {
        "media": "NFT News Today"
    },
    {
        "media": "Coinscreed"
    },
    {
        "media": "TheCoinrise"
    },
    {
        "media": "coincript.com"
    },
    {
        "media": "coinmes.com"
    },
    {
        "media": "cripplly.com"
    },
    {
        "media": "cryptoate.com"
    },
    {
        "media": "cryptocurrencyfinancial.org"
    },
    {
        "media": "geekocoin.net"
    },
    {
        "media": "kryptoscroll.com"
    },
    {
        "media": "pentaxcoin.com"
    },
    {
        "media": "newsflurry.com"
    },
    {
        "media": "altpoint.co"
    },
    {
        "media": "bitcrux.net"
    },
    {
        "media": "bitscoop.net"
    },
    {
        "media": "blockcruck.com"
    },
    {
        "media": "blockhubs.co"
    },
    {
        "media": "blocknow.net"
    },
    {
        "media": "blockorn.co"
    },
    {
        "media": "blockphere.com"
    },
    {
        "media": "blockpost.xyz "
    },
    {
        "media": "blockreach.net "
    },
    {
        "media": "blockspy.org"
    },
    {
        "media": "blockstory.co"
    },
    {
        "media": "btccrux.com"
    },
    {
        "media": "btcdaily.org"
    },
    {
        "media": "btcinsider.net"
    },
    {
        "media": "btcjournal.co"
    },
    {
        "media": "btcsonic.xyz"
    },
    {
        "media": "btcstory.xyz"
    },
    {
        "media": "coinblast.co "
    },
    {
        "media": "coinjet.info "
    },
    {
        "media": "coinnewspan.com"
    },
    {
        "media": "coinnoble.com"
    },
    {
        "media": "coinolly.com"
    },
    {
        "media": "cryptopost.us"
    },
    {
        "media": "cryptopress.uk"
    },
    {
        "media": "cryptoprint.co"
    },
    {
        "media": "cryptoscrollen.com"
    },
    {
        "media": "cryptowires.net"
    },
    {
        "media": "defilust.com"
    },
    {
        "media": "nftscreen.co"
    },
    {
        "media": "defijournal.net"
    },
    {
        "media": "coinkindle.org "
    },
    {
        "media": "coinspit.co"
    },
    {
        "media": "cryptomanias.org"
    },
    {
        "media": "cryptoroof.org"
    },
    {
        "media": "cryptothrive.news"
    },
    {
        "media": "thebuzzuniverse.com"
    },
    {
        "media": "thecoinstudy.com"
    },
    {
        "media": "therobusthealth.com"
    },
    {
        "media": "topwealthyways.com"
    },
    {
        "media": "wealthpills.com"
    },
    {
        "media": "zecripto.com"
    },
    {
        "media": "easyfinancetips.com"
    },
    {
        "media": "financialspeaks.com"
    },
    {
        "media": "thefinanceglobe.com"
    },
    {
        "media": "bestknownfinance.com"
    },
    {
        "media": "greencrestcapital.com"
    },
    {
        "media": "cryptosisnews.com"
    },
    {
        "media": "techbunch.co"
    },
    {
        "media": "techexplore.co"
    },
    {
        "media": "techfluxnews.com"
    },
    {
        "media": "techshelf.net"
    },
    {
        "media": "techbeam.co"
    },
    {
        "media": "techvibe9.com"
    },
    {
        "media": "pressbase.co"
    },
    {
        "media": "dubaiuncovered.com"
    },
    {
        "media": "futuretechdubai.com"
    },
    {
        "media": "dubaigeek9.com"
    },
    {
        "media": "BSCBuzz.com"
    },
    {
        "media": "blockscooop.com"
    },
    {
        "media": "Cryptobulletin.net"
    }
    ],
    included: [
      "PR Guaranteed on Cointelegraph",
      "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "PR Guaranteed on Mainstream Media + Yahoo + Benzinga + MarketWatch",
      "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
      "PR writing upon request",
    ],
  },
  {
    id: "featured-7-103803391c1d",
    packageName: "Featured 7",
    packageShortName: "featured 7",
    packageType: "Advanced",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "18000",
    category: "casino",
    isFeatured: true,
    extraSource: ["+35 Crypto Websites"],
    totalReach: "30M+",
    products: [{ media: "BSC News", logo: bscNews },
        {
            "media": "BlockchainMagazine"
        },
        {
            "media": "Cryptowisser"
        },
        {
            "media": "Blockchain.news"
        },
        {
            "media": "alexablockchain"
        },
        {
            "media": " + Additional Websites"
        },
        {
            "media": "Coinscreed"
        },
        {
            "media": "TheCoinrise"
        },
        {
            "media": "Crypto Economy"
        },
        {
            "media": "CoinChapter"
        },
        {
            "media": "Coinworldstory"
        },
        {
            "media": "Thecryptoupdates.com"
        },
        {
            "media": "Theblockopedia.com"
        },
        {
            "media": "Prices.org"
        },
        {
            "media": "TheMarketPeriodical"
        },
        {
            "media": "Visionary Finance"
        },
        {
            "media": "CryptoBrowser"
        },
        {
            "media": "AirdropsMob"
        },
        {
            "media": "Coinfunda"
        },
        {
            "media": "blockcrux.com"
        },
        {
            "media": "Coingabbar"
        },
        {
            "media": "Vietnamese Insider"
        },
        {
            "media": "Cryptodnes"
        },
        {
            "media": "Timestabloid"
        },
        {
            "media": "topcom"
        },
        {
            "media": "defidraft.com"
        },
        {
            "media": "Kriptoup"
        },
        {
            "media": "ulaska.com"
        },
        {
            "media": "blockscroll.org"
        },
        {
            "media": "coincript.com"
        },
        {
            "media": "coinmes.com"
        },
        {
            "media": "cripplly.com"
        },
        {
            "media": "cryptoate.com"
        },
        {
            "media": "cryptocurrencyfinancial.org"
        },
        {
            "media": "geekocoin.net"
        },
        {
            "media": "kryptoscroll.com"
        },
        {
            "media": "pentaxcoin.com"
        }
    ],
    included: [
      "    PR Guaranteed on Cointelegraph                                                                                                                                                                                                                                                                                                                                                                  ",
      "PR Published on up to 35 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
    ],
  },
  {
    id: "featured-8-103803391c1d",
    packageName: "Featured 8",
    packageShortName: "featured 8",
    packageType: "Enterprise Gaming",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "50000",
    category: "gaming",
    isFeatured: true,
    extraSource: ["+125 Crypto Websites"],
    totalReach: "Publication potential reach: 1.75B+",
    products: [
      { media: "Cointelegraph", logo: coinTelegraph },
      { media: "Bitcoin.com", logo: bitcoin },
      { media: "BeInCrypto", logo: beInCrypto },
    ],
    included: [
      "PR Guaranteed on Cointelegraph, Bitcoin.com and BeInCrypto",
      "PR Published on up to 125 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
      "125 Guaranteed Press Releases on leading news crypto websites (cryptonews.com, cryptopotato.com, cryptoslate.com, etc)",
      "Twitter Post done by Bitcoin.com (2.5M Followers Verified account)",
      "Facebook Post done by Bitcoin.com (300K Followers)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
      "PR writing upon request",
    ],
  },
  {
    id: "featured-9-103803391c1d",
    packageName: "Featured 9",
    packageShortName: "featured 9",
    packageType: "Advanced Gaming",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "40000",
    isFeatured: true,
    category: "gaming",
    extraSource: ["+75 Crypto Websites"],
    totalReach: "Publication potential reach: 690M+",
    products: [
      { media: "Cointelegraph", logo: coinTelegraph },
      { media: "BeInCrypto", logo: beInCrypto },
    ],
    included: [
      "PR Guaranteed on Cointelegraph",
      "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "PR Guaranteed on Mainstream Media + Yahoo + Benzinga + MarketWatch",
      "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to aproval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
      "PR writing upon request",
    ],
  },
  {
    id: "featured-10-103803391c1d",
    packageName: "Featured 10",
    packageShortName: "featured 10",
    packageType: "Basic Gaming",
    isInShoppingCart: false,
    isInWishlist: false,
    cover: "",
    totalPrice: "20000",
    category: "gaming",
    isFeatured: true,
    extraSource: ["+35 Crypto Websites"],
    totalReach: "Publication potential reach: 310M+",
    products: [{ media: "Cointelegraph", logo: coinTelegraph }],
    included: [
      "    PR Guaranteed on Cointelegraph                                                                                                                                                                                                                                                                                                                                                                  ",
      "PR Published on up to 35 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
      "Your Project Featured on the homepage of all news outlets",
      "We will also request the website owners to make socials posts (not guaranteed, but some will do it)",
      "Up to 2 images per article",
      "Transparent and detailed reporting",
    ],
  },
  // {
  //     id: "featured-4-103803391c1d",
  //     packageName: "Featured 4",
  //     packageShortName: "featured 4",
  //     packageType: "Silver",
  //     isInShoppingCart: false,
  //     isInWishlist: false,
  //     cover: "",
  //     totalPrice: "10000",
  //     isFeatured: true,
  //     extraSource: ["+75 Crypto Websites", "+Mainstream Media PR (BusinessInsider, TradingView)"],
  //     totalReach: "Publication potential reach: 500M+ readers",
  //     products: [],
  //     included: [
  //         "Press Release submission or written by us",
  //         "PR Published on up to 75 Crypto Leading Sites (several examples: beincrypto.com, u.today, cryptopotato.com, cryptoslate.com, finbold.com, crypto.news, crypto-news-flash.com, cryptoweisser.com, coinmarketcap.com, thebittimes.com, usethebitcoin.com, coinspeaker.com, zycrypto.com, cryptobriefing.com, coincodex.com, coinmarketcal.com, mpost.io, coinjournal.net, cryptoadventure.com, thecryptonews.com, dailycoin.com, barchart.com, cryptoglobe.com, decentralised.news, cryptorus.com, etc)",
  //         "Guaranteed Publishing on up to 30 Big Mainstream Media News Sites (several examples: BusinessInsider, Reuters Terminal (Subject to approval) TradingView (Subject to approval), Yahoo Finance + 12 More Yahoo Versions, APNews, MorningStar, MarketWatch, Benzinga, InvestorObserver, StreetInsider, DigitalJournal, etc.)",
  //         "PR Guaranteed on 50 Smaller Crypto Sites (cryptounfolded.com, cryptopress.news, cryptonews.direct, etc)",
  //         "Article featured on crypto websites homepages",
  //         "Social Media posts included for some websites as a bonus",
  //         "SEO link-building targeted to growth of intent-driven traffic",
  //         "Transparent and detailed reporting",
  //     ],
  // },
  // {
  //     id: "featured-5-103803391c1d",
  //     packageName: "Featured 5",
  //     packageShortName: "featured 5",
  //     packageType: "Asia",
  //     isInShoppingCart: false,
  //     isInWishlist: false,
  //     cover: "",
  //     totalPrice: "25500",
  //     isFeatured: true,
  //     extraSource: ["+25 Crypto Websites"],
  //     totalReach: "Publication potential reach: 500M+ readers",
  //     products: [],
  //     included: [
  //         "Press Release (PR) posted on Premium Japan Media              ",
  //         "Japanese journalist will write/translate the article",
  //         "PR Guaranteed on Coinpost (nr. 1 for Crypto in Japan)",
  //         "PR Guaranteed on Cointelegraph Japan",
  //         "Press Release (PR) posted on Korean Media",
  //         "Korean journalist will write/translate the article",
  //         "Published on 130+ Korean Sites",
  //         "PR Guaranteed on Naver.com",
  //         "PR Guaranteed on Cobak.co.kr",
  //         "PR Guaranteed on Coinreaders.com",
  //         "Press Release (PR) posted on Chinese Media",
  //         "Chinese journalist will write/translate the article",
  //         "Published on 130+ Chinese Sites",
  //         "Baidu Guaranteed",
  //         "SEO link-building targeted to growth of intent-driven traffic",
  //         "Transparent and detailed reporting",
  //     ],
  // },
  {
    id: "tech-startup-package-1-103803391c1d",
    packageName: "Tech Startups - Package 1",
    packageShortName: "tech 1",
    packageType: "Tech package 1",
    isInShoppingCart: false,
    isInWishlist: false,
    isFeatured: false,
    totalPrice: 0,
    extraSource: [],
    discount: "",
    totalReach: "Publication potential reach: Over 602 millions",
    description:
      "Some publications editorial teams might take between 5 to 8 days at their discretion.",
    content:
      "Additionally, your Press Release will be featured in more than 200 publications within the syndicate.",
    content2: "Distribution will be within 24-72h",
    products: PRProducts,
    included: ["Guaranteed Feature on TechTimes"],
    category: "crypto",
  },
  {
    id: "tech-startup-package-2-103803391c1d",
    packageName: "Tech Startups - Package 2",
    packageShortName: "tech 2",
    packageType: "Tech package 2",
    isInShoppingCart: false,
    isInWishlist: false,
    isFeatured: false,
    totalPrice: 0,
    extraSource: [],
    discount: "",
    totalReach: "Publication potential reach: Over 350 Milion",
    description:
      "Some Publication editorail team might take between 5-8 days at there discretion",
    content:
      "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
    content2: "Distrubution will be within 24-72h",
    products: PRProductsCrypto,
    included: ["Feature or Interview on TechTimes"],
    category: "casino",
  },
  {
    id: "crypto-package-1-103803391c1d",
    packageName: "Crypto - Package 1",
    packageShortName: "crypto 1",
    packageType: "Crypto package 1",
    isInShoppingCart: false,
    isInWishlist: false,
    isFeatured: false,
    totalPrice: 0,
    totalReach: "Publication potential reach: Over 6 Milion",
    extraSource: [],
    discount: "",
    description:
      "Your crypto project will benefit from significant exposure across leading crypto publications, enhancing visibility and engagement within the crypto community.",
    description:
      "Some Publication editorail team might take between 5-8 days at there discretion",
    content:
      "Additionally your Press Release will be featured in more than 200 publications withing the syndicate.",
    content2: "Distrubution will be within 24-72h",

    products: [
      {
        id: "hackernoon-8h9i0j1k-2345",
        media: "Hackernoon",
        format: "Interview on Hackernoon",
        monthlyTraffic: "",
        reach: "2900000",
        turnaroundTime: "",
        duration: "1-2 weeks",
        price: 1300,
        logo: hackernoon,
        isSelected: false,
      },
      {
        id: "publish0x-9i0j1k2l-3456",
        media: "Publish0x",
        format: "Feature or Interview on Publish0x",
        monthlyTraffic: "",
        reach: "1900000",
        turnaroundTime: "3-5 business days",
        duration: "",
        price: 3500,
        isSelected: false,
      },
      {
        id: "the-crypto-updates-0j1k2l3m-4567",
        media: "The Crypto Updates",
        format: "Feature on The Crypto Updates",
        monthlyTraffic: "",
        reach: "1300000",
        turnaroundTime: "",
        duration: "1-1.5 weeks",
        price: 10000,
        isSelected: false,
      },
      {
        id: "crypto-mode-1k2l3m4n-5678",
        media: "Crypto Mode",
        format: "Feature or Interview on Crypto Mode",
        monthlyTraffic: "",
        reach: "14400",
        turnaroundTime: "5-8 business days",
        duration: "",
        price: 15000,
        isSelected: false,
      },
    ],
    included: ["Feature on U.Today"],
    category: "gaming",
  },
];

export default function useMarketplaceState() {
  const [activeMarketplaceDetails, setActiveMarketplaceDetails] = useState({});
  const [displayMarketplaceDetailsModal, setDisplayMarketplaceDetailsModal] =
    useState("none");

  const [publications, setPublications] = useState(fetchedData);
  const [activePublictions, setActivePublication] = useState(fetchedData);

  const { addCommas } = useFormatter();

  useEffect(() => {
    if (!Object.keys(activeMarketplaceDetails).length) return;
    setDisplayMarketplaceDetailsModal("block");
  }, [activeMarketplaceDetails]);

  function MarketplaceCardDetails({ setIsOpen }) {
    const {
      packageType,
      totalPrice,
      format,
      discount,
      products,
      included,
      extraSource,
      description,
      content,
      content2,
      totalReach,
    } = activeMarketplaceDetails;

    const closeModal = () => {
      setIsOpen(false);
    };
    const { close } = localData.svgs;
    return (
      <>
        {totalPrice === 0 ? (
          <div>
            <p style={{ textAlign: "center" }}>
              Please select at least one package to view what's included.
            </p>
          </div>
        ) : (
          <>
            <div className="modal-header">
              <div className="wrapper">
                <h2 className="display-2 modal-title">{packageType}</h2>
                {totalReach && (
                  <p className="total-reach text-style-1">{totalReach}</p>
                )}
              </div>
              <p className="description">{format}</p>
              <br />
              <br />
              <Button
                className="btn-close"
                variant="circle"
                color="dark"
                size="sm"
                icon={close}
                onClick={closeModal}
              />

              <div className="products">
                {!products.length
                  ? ""
                  : products.slice(0, 6).map((item, index) => {
                      if (!item.logo) return;
                      return (
                        <div className="product" key={index}>
                          {/* <h6 className="product-title">{item.title}</h6> */}
                          {item.logo && (
                            <img
                              className="product-logo"
                              src={item.logo}
                              alt=""
                            />
                          )}
                        </div>
                      );
                    })}
                {!extraSource.length
                  ? ""
                  : extraSource.map((item, index) => {
                      return (
                        <div className="product" key={index}>
                          <h6 className="product-title">{item}</h6>
                        </div>
                      );
                    })}
              </div>
              {/* {discount && (
                        <div className="discount-badge">Publication potential reach: {discount}</div>
                    )} */}
              <br />
              <br />
            </div>
            <div className="modal-body">
              <div className="row row-1">
                {!products || !products.length ? (
                  ""
                ) : (
                  <div className="col-left">
                    <p className="">What's included</p>
                    <br />
                    <ul className="included">
                      {products.map((item, index) => {
                        return (
                          <li className="included-item" key={index}>
                            <p className="included-title text-style-1">
                              {" "}
                              {item.media}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {(content || content2) && (
                  <div className="col-right">
                    {content && (
                      <p className="content-1 text-style-1">{content}</p>
                    )}
                    <br />

                    {content2 && (
                      <p className="content-2 text-style-1">{content2}</p>
                    )}
                  </div>
                )}
              </div>
              <br />
              <br />
              <br />

              <div className="price">${addCommas(totalPrice) || 0}</div>
              <br />
              {description && (
                <p className="modal-description text-style-1">{description}</p>
              )}
            </div>
            <div className="modal-footer">
              <Button
                className="marketplace-details-close-btn"
                color="dark"
                onClick={closeModal}
              >
                Close {close}
              </Button>
            </div>
          </>
        )}
      </>
    );
  }

  return {
    activeMarketplaceDetails,
    setActiveMarketplaceDetails,
    displayMarketplaceDetailsModal,
    setDisplayMarketplaceDetailsModal,
    publications,
    setPublications,
    MarketplaceCardDetails,
    activePublictions,
    setActivePublication,
    fetchedData
  };
}

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import localData from "../../localData.js";
import { useGlobalContext } from "../../context.js";
import { Button, Select, InfoPopup, Modal, Accounts } from "../";
import { v4 as uuidv4 } from "uuid";
import { auth } from "../../config/firebase.js";
import useFetch from "../../hooks/useFetch.js";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { default as ReactSelect } from "react-select";

const adNetwork = ["/", "/campaigns", "/analytics"];
const marketplace = ["/additional-products/chat", "/additional-products/pr"];
const supports = ["/support"];

export default function Sidebar({
  isPublic = false,
  showHamburgerDrawer = false,
  setShowHamburgerDrawer,
}) {
  const {
    bar,
    campaignsIcon,
    goalsIcon,
    analyticsIcon,
    prIcon,
    supportIcon,
    askAIicon,
    analytics,
    strategist,
    arrowRight,
    support,
    ecoProduct,
    cube,
    trendUp,
    arrowUpRightDots,
    arrowsLeftRight,
    info,
    moneyBillTransfer,
    angleRight,
    arrowDown,
  } = localData.svgs;
  const { logo, logoSm, databaseIcon } = localData.images;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const location = useLocation();
  const {
    showSidebar,
    setShowSidebar,
    errorAlert,
    requestedData: { userProfileData },
    getLoadUserProfile,
    requestedData,
    setShowProfileDrawer,
    showProfileDrawer
  } = useGlobalContext();

  const { switchOrganization } = useFetch();
  const [isLoading, setIsLoading] = useState();

  // switchOrganization
  const getSwitchOrganization = async (id) => {
    if (!auth.currentUser) return;
    // setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
    setIsLoading(true);
    const temRaw = {
      uuid: auth.currentUser.uid,
      organizationID: id,
    };
    try {
      const data = await switchOrganization(() => {}, temRaw);
      console.log(data, "=switchOrganization= request");
      getLoadUserProfile();
    } catch (err) {
      errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
      console.error(err, "=switchOrganization= request error");
    }
    // filterAllOrganization();
    setIsLoading(false);
  };

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [previousItem, setPreviousItem] = useState("");

  const filterAllOrganization = () => {
    // setIsLoading(true);

    let array = userProfileData.allOrganization.map((item, index) => {
      return {
        title: item.name || "---Not Exist---",
        isSelected: item.id === userProfileData.currentOrganization.id,
        id: item.id,
        adminID: item.adminID,
        color:
          item.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
            ? // ? "purple"
              "rgb(150, 45, 255)"
            : item.adminID === userProfileData.userId
            ? // ? "success"
              "rgb(42, 171, 126)"
            : "",
      };
    });

    array.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });

    setItems(array);

    let tempFilteredItems = array.map((item) => {
      if (item.id === userProfileData.currentOrganization.id) {
        setSelectedItem({
          ...item,
          value: item.title + " - value",
          label: item.title,
        });
      }
      return {
        ...item,
        value: item.title + " - value",
        label: item.title,
      };
    });
    setFilteredItems(tempFilteredItems);
  };

  useEffect(() => {
    if (!userProfileData.allOrganization.length) return;
    filterAllOrganization();
  }, [userProfileData]);

  useEffect(() => {
    if (!items.length) return;
    const user = items.find((item) => item.isSelected);

    if (!user) return;
    const id = user.id;
    if (id == userProfileData.currentOrganization.id) return;
    getSwitchOrganization(id);
  }, [items]);

  const [displayAccountsModal, setDisplayAccountsModal] = useState("none");

  const [isPRMenuOpen, setIsPRMenuOpen] = useState(false);

  const clickWrapper = useRef(null);
  useEffect(() => {
    let handler = (e) =>
      //   !clickWrapper.current.contains(e.target) && setIsPRMenuOpen(false);

      document.addEventListener("click", handler);
    return () => document.removeEventListener("click", handler);
  });

  const handleChange = async (selectedOption) => {
    setPreviousItem(selectedItem);
    setSelectedItem({ label: "loading..." });
    try {
      await getSwitchOrganization(selectedOption.id);
      setSelectedItem(selectedOption);
    } catch (err) {
      setSelectedItem(previousItem);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#ccc",
      // height: '35px',
      // minHeight: 'auto',
      borderColor: "rgba(255,255,255,.5)",
      outline: "none",
      backgroundColor: "transparent",
      color: "#fff",
      fontWeight: "400",
      "&:hover": {
        borderColor: "rgba(255,255,255,.8)",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : state.data.color || "#fff", // Use option-specific color
      backgroundColor: state.isSelected ? "rgba(0,0,0,0.1)" : "#fff",
      // color:
      //     state.data.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
      //         ? "purple"
      //         : state.data.adminID === userProfileData.userId
      //         ? "green"
      //         : "",
      color: state.data.color,
      padding: "7px 15px",
      fontWeight: 500,
      "&:hover": {
        backgroundColor: !state.isSelected && "rgba(0,0,0,0.05)",
        color: state.data.color || "#000",
      },
    }),
    // menu: (provided) => ({
    //     ...provided,
    //     scrollbarWidth: 'thin', // For Firefox
    // }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px",
      overflowY: "auto",
      scrollbarWidth: "thin",
      borderTopLeftRadius: "5px",
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        // color:
        //     state.data.id === "a76afb14-1f2f-4518-949e-96994c057cf1"
        //         ? "purple"
        //         : state.data.adminID === userProfileData.userId
        //         ? "green"
        //         : "",
        color: state.data.color,
        // padding: '7px 15px',
      };
    },
  };

  useEffect(() => {
    if (!showHamburgerDrawer) {
      setIsCollapsed(false);
    }
  }, [showHamburgerDrawer]);

  const handleMouseEnter = () => {
    setIsCollapsed(false);
  };

  const handleMouseLeave = () => {
    console.log(displayAccountsModal);
    if (displayAccountsModal == "block") return;
    setIsCollapsed(true);
    if (showHamburgerDrawer) {
      return setIsCollapsed(false);
    }
  };

  return (
    <>
      <aside
        className={`sidebar ${
          marketplace.includes(location.pathname) ? "sidebar-marketplace" : ""
        }
      ${adNetwork.includes(location.pathname) ? "sidebar-ad-network" : ""}
      ${supports.includes(location.pathname) ? "sidebar-support" : ""}`}
        id="sidebar"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <div
                    className={`marketplace-badge ${
                        location.pathname.includes("/additional-products") ? "active" : ""
                    }`}
                >
                    M
                </div> */}
        <div className="sidebar-inner">
          {/* <div className={`sidebar-header  ${isCollapsed ? "sidebar-header-sm" : ""}`}>
                        <img className={`sidebar-logo ${isCollapsed ? "hidden" : ""}`} src={logo} />
                        <img className="sidebar-logo sidebar-logo-sm" src={logoSm} />
                    </div> */}
          <div className="sidebar-body">
            <div
              data-tooltip-id="sidebar-ad-network-tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                "Sign up first."
              )}
            >
              <ul className="sidebar-list">
                <Link
                  to="/campaigns"
                  className={`sidebar-link custom-sidebar-link ${
                    location.pathname == "/campaigns" ||
                    location.pathname == "/"
                      ? "active"
                      : ""
                  } ${isPublic ? "disabled" : ""}`}
                  onClick={() => {
                    if (showHamburgerDrawer) {
                      setShowHamburgerDrawer(false);
                    }
                    window.innerWidth < 992 && setShowSidebar(false);
                  }}
                  style={{display: "flex", flexDirection: "column", justifyContent: 'center'}}
                >
                  {campaignsIcon}
                  <div className="d-flex">
                  <p className="item-title"> Campaigns </p>
                  {/* <div
                    data-tooltip-id="sidebar-campaigns-tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      "View your campaigns’ performance and launch new campaigns."
                    )}
                    className={`info-popup`}
                  >
                    {info}
                  </div> */}
                  </div>

                  <Tooltip
                    id="sidebar-campaigns-tooltip"
                    className={`custom-tooltip sidebar-tooltip`}
                  />
                </Link>
                <Link
                  id="login-tutorial-6"
                  to="/tracking"
                  className={`sidebar-link custom-sidebar-link ${
                    location.pathname == "/tracking" ? "active" : ""
                  }${isPublic ? "disabled" : ""}`}
                  onClick={() => {
                    if (showHamburgerDrawer) {
                      setShowHamburgerDrawer(false);
                    }
                    window.innerWidth < 992 && setShowSidebar(false);
                  }}
                >
                  {goalsIcon}
                  <div className="d-flex">
                  <p className="item-title">Goals</p>
                  {/* <div
                    data-tooltip-id="sidebar-campaigns-tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      "Install Blockchain-Ads Pixel and track events from your website."
                    )}
                    className={`info-popup`}
                  >
                    {info}
                  </div> */}
                  </div>

                  <Tooltip
                    id="sidebar-campaigns-tooltip"
                    className="custom-tooltip sidebar-tooltip"
                  />
                </Link>

                <Link
                  id="login-tutorial-7"
                  to="/analytics"
                  className={`sidebar-link custom-sidebar-link ${
                    location.pathname == "/analytics" ? "active" : ""
                  }${isPublic ? "disabled" : ""}`}
                  onClick={() => {
                    if (showHamburgerDrawer) {
                      setShowHamburgerDrawer(false);
                    }
                    window.innerWidth < 992 && setShowSidebar(false);
                  }}
                >
                  {analyticsIcon}
                  <div className="d-flex">
                  <p className="item-title"> Analytics </p>
                  {/* <div
                    data-tooltip-id="sidebar-analytics-tooltip"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      "Connect your Google Analytics 4 Accounts to visualize your data here."
                    )}
                    className={`info-popup`}
                  >
                    {info}
                  </div> */}
                  </div>
       
                  <Tooltip
                    id="sidebar-analytics-tooltip"
                    className="custom-tooltip sidebar-tooltip"
                  />
                </Link>
              </ul>
            </div>
            <div className="add-ons-divider">
            <div
              className={`list-wrapper marketplace-wrapper ${
                location.pathname.includes("/additional-products")
                  ? "list-wrapper-active"
                  : ""
              }  ${isPublic ? "disabled" : ""}`}
              ref={clickWrapper}
              data-tooltip-id="sidebar-marketplace-tooltip"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                "Sign up first."
              )}
            >
              <h4
                className={`sidebar-text`}
                onClick={() => setIsPRMenuOpen(!isPRMenuOpen)}
              >
                Add-ons
                <span className="endIcon">{arrowDown}</span>
              </h4>
              <ul
                className={`sidebar-list ${
                  isPRMenuOpen ? "show" : ""
                }`}
              >
                {/* <div
                                    className={`sup-text ${
                                        activeProductsMenu == "marketing" ? "active" : ""
                                    }`}
                                    onClick={() =>
                                        setActiveProductsMenu(
                                            activeProductsMenu == "marketing" ? "" : "marketing"
                                        )
                                    }
                                >
                                    Marketing
                                    {<div className="icon">{angleRight}</div>}
                                </div> */}

                <div
                  className={`bar-divider`}
                  // className={`bar-divider  ${
                  //     activeProductsMenu == "marketing" ? "active" : ""
                  // }`}
                >
                  <Link
                    to="/additional-products/pr"
                    className={`sidebar-link custom-sidebar-link ${
                      location.pathname == "/additional-products/pr"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      if (showHamburgerDrawer) {
                        setShowHamburgerDrawer(false);
                      }
                      window.innerWidth < 992 && setShowSidebar(false);
                    }}
                  >
                    {prIcon}
                    <div className="d-flex">
                    <p className="item-title">PR</p>
                    {/* <div
                      data-tooltip-id="sidebar-pr-tooltip"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        "Boost visibility and reach top media."
                      )}
                      className={`info-popup`}
                    >
                      {info}
                    </div> */}
                    </div>
            
                    <Tooltip
                      id="sidebar-pr-tooltip"
                      className="custom-tooltip sidebar-tooltip"
                    />
                  </Link>
                </div>

                {/* <div
                                    className={`sup-text ${activeProductsMenu == "ai-tools" ? "active" : ""}`}
                                    onClick={() =>
                                        setActiveProductsMenu(
                                            activeProductsMenu == "ai-tools" ? "" : "ai-tools"
                                        )
                                    }
                                >
                                    AI Tools
                                    {<div className="icon">{angleRight}</div>}
                                </div> */}
                <div
                  className={`bar-divider`}
                  // className={`bar-divider  ${
                  //     activeProductsMenu == "ai-tools" ? "active" : ""
                  // }`}
                >
                  {/* <Link
                    to="/chat"
                    className={`sidebar-link disabled custom-sidebar-link${
                      location.pathname == "/trending" ? "active" : ""
                    }`}
                    onClick={() => {
                      if (showHamburgerDrawer) {
                        setShowHamburgerDrawer(false);
                      }
                      window.innerWidth < 992 && setShowSidebar(false);
                    }}
                  >
                    {trendUp}
                    <div className="d-flex">
                    <p>Trending on CMC/CG</p>
                    <InfoPopup
                      content="Trending on CMC/CG."
                      position="left"
                      className={`info-popup`}
                    />
                    </div>
                  </Link> */}

                  {/* <Link
                    to="/additional-products/ask-ai"
                    className={`sidebar-link  custom-sidebar-link ${
                      location.pathname == "/additional-products/ask-ai"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      if (showHamburgerDrawer) {
                        setShowHamburgerDrawer(false);
                      }
                      window.innerWidth < 992 && setShowSidebar(false);
                    }}
                  >
                    {askAIicon}
                    <div className="d-flex">
                    <p className="item-title">Ask AI</p>
                    <div
                      data-tooltip-id="sidebar-ai-tooltip"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        "AI assistance for successful marketing strategy and setup."
                      )}
                      className={`info-popup`}
                    >
                      {info}
                    </div>
                    </div>

        
                    <Tooltip
                      id="sidebar-ai-tooltip"
                      className="custom-tooltip sidebar-tooltip"
                    />
                  </Link> */}
                </div>
              </ul>
            </div>
            </div>

            
            <br />
            <div className="admin-container">
            {requestedData.userProfileData.currentOrganization.admin === "Blockchain-Ads Team" && (
                  <Link
                    to="/admin-panel"
                    className={`sidebar-link custom-sidebar-link  ${
                      location.pathname == "/admin-panel" 
                        ? "active"
                        : ""
                    } ${isPublic ? "disabled" : ""}`}
                    onClick={() => {
                      if (showHamburgerDrawer) {
                        setShowHamburgerDrawer(false);
                      }
                      window.innerWidth < 992 && setShowSidebar(false);
                    }}
                  >
                    <AdminPanelSettingsIcon />
                    <div className="d-flex">
                    <p className="item-title" style={{textAlign: "center"}}> Admin Panel </p>
                    </div>
                    
                    {/* <div
                      data-tooltip-id="sidebar-add-account-tooltip"
                      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        "Admin Panel"
                      )}
                      className={`info-popup`}
                    >
                      {info}
                    </div> */}
                    <Tooltip
                      id="sidebar-add-account-tooltip"
                      className={`custom-tooltip sidebar-tooltip`}
                    />
                  </Link>
                )}
          </div>
          </div>
          <div className="sidebar-footer">
          <div style={{ padding: "5px 0", marginTop: "1rem" }}>
              <ul className="">
                <Link
                  to="/support"
                  className={`sidebar-link custom-sidebar-link small-icon custom-sidebar-link-support ${
                    location.pathname == "/support" ? "active" : ""
                  }`}
                  onClick={() => {
                    if (showHamburgerDrawer) {
                      setShowHamburgerDrawer(false);
                    }
                    window.innerWidth < 992 && setShowSidebar(false);
                  }}
                >
                  <span className="custom-support-icon">{supportIcon}</span>
                  
                  <div className="d-flex">
                  <p className="item-title"> Support </p>
                  </div>
                  
                  {/* <Tooltip
                    id="sidebar-support-tooltip"
                    className={`custom-tooltip sidebar-tooltip`}
                  /> */}
                </Link>
              </ul>
            </div>
            <div style={{ padding: "5px 0", marginTop: "1rem" }}>
              <ul className="sidebar-list" style={{ marginTop: 0 }}>
                <p
                  className={`sidebar-link custom-sidebar-link small-icon`}
                  onClick={() => 
                    setShowProfileDrawer("block")
                  }
                  style={{cursor: "pointer"}}
                >
                  <SettingsOutlinedIcon style={{width: "32px !important"}}/>
                  <div className="d-flex">
                  <p className="item-title"> Settings </p>
                  </div>
                  
                  {/* <Tooltip
                    id="sidebar-support-tooltip"
                    className={`custom-tooltip sidebar-tooltip`}
                  /> */}
                </p>
              </ul>
            </div>
          </div>
            {/* <div className="sidebar-footer">
              {requestedData.userProfileData.currentOrganization.admin ===
                "Blockchain-Ads Team" && (
                <Modal
                  Toggler={() => (
                    <Button
                      name="Accounts"
                      variant="contained"
                      color="purple"
                      className="accounts-btn"
                      endImageIcon={databaseIcon}
                      onClick={() => setDisplayAccountsModal("block")}
                    />
                  )}
                  Child={Accounts}
                  // modalCloseCallback={modalCloseCallback}
                  display={displayAccountsModal}
                  setDisplay={setDisplayAccountsModal}
                  className="modal-dialog-centered modal-fullscreen modal-dialog-scrollable accounts"
                />
              )}
            </div> */}

          {/* <div className={`collapse-menu ${isCollapsed ? "collapsed" : ""}`}>
            <p onClick={() => setIsCollapsed(!isCollapsed)}>
              {isCollapsed ? ">" : "<"}
            </p>
          </div> */}
        </div>
      </aside>

      <div
        className={`sidebar-backdrop ${showSidebar ? "show" : ""}`}
        onClick={() => setShowSidebar(false)}
      ></div>
    </>
  );
}

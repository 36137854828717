import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { Grid , Typography} from "@mui/material";
import localData from "../../localData";
import { Calendar, Loading } from "../../components";
import useFormatter from "../../hooks/useFormatter";
import CircularProgress from '@mui/material/CircularProgress';

const PDFTemplate = ({ selectedPayments }) => {
    const styles = StyleSheet.create({
      page: { padding: 40, fontSize: 10, color: "#333" },
      header: { marginBottom: 20, flexDirection: "row", justifyContent: "space-between" },
      logo: { maxWidth: "175px", height: "100%" },
      companyInfo: { textAlign: "right" },
      section: { marginBottom: 20 },
      title: { fontSize: 18, color: "#4CAF50", marginBottom: 10 },
      table: { display: "table", width: "auto", marginBottom: 20 },
      tableRow: { flexDirection: "row", borderBottom: "1px solid #e0e0e0", paddingVertical: 5 },
      tableHeader: { fontSize: 12, fontWeight: "bold", color: "#4CAF50" },
      tableCell: { flex: 1, textAlign: "left", paddingHorizontal: 5 },
      total: { marginTop: 10, fontSize: 12, fontWeight: "bold", textAlign: "right" },
      footer: { marginTop: 40, textAlign: "center", fontSize: 10, color: "#aaa" },
    });
  
    const totalAmount = selectedPayments.reduce((sum, payment) => sum + (payment.amount || 0), 0);
    const { logoDark } = localData.images;
  
    return (
      <Document>
        <Page style={styles.page}>
          {/* Header Section */}
          <View style={styles.header}>
            <Image src={logoDark} style={styles.logo} />
            <View style={styles.companyInfo}>
              <Text>Blockchain-Ads Labs LLC</Text>
              <Text>Suite 305, Griffith Corporate Centre</Text>
              <Text>Beachmonth, Kingston, St. Vincent and the Grenadines</Text>
            </View>
          </View>
  
          {/* Title Section */}
          <View style={styles.section}>
            <Text style={styles.title}>Receipt</Text>
            <Text>Payment Summary</Text>
          </View>
  
          {/* Table Section */}
          <View style={styles.table}>
            <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
              <Text style={[styles.tableCell, styles.tableHeader]}>Date</Text>
              <Text style={[styles.tableCell, styles.tableHeader]}>Amount</Text>
            </View>
            {selectedPayments.map((payment, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCell}>
                  {payment.date?._seconds
                    ? new Date(payment.date._seconds * 1000).toLocaleDateString()
                    : "N/A"}
                </Text>
                <Text style={styles.tableCell}>${payment.amount || "0.00"}</Text>
              </View>
            ))}
          </View>
  
          <Text style={styles.total}>Total: ${totalAmount.toFixed(2)}</Text>
  
          <Text style={styles.footer}>Thank you for your payment!</Text>
        </Page>
      </Document>
    );
  };
  

const SinglePDFTemplate = ({ payment }) => {
  const styles = StyleSheet.create({
    page: { padding: 40, fontSize: 10, color: "#333" },
    header: { marginBottom: 20, flexDirection: "row", justifyContent: "space-between" },
    logo: { maxWidth: "175px", height: "100%" },
    companyInfo: { textAlign: "right" },
    section: { marginBottom: 20 },
    title: { fontSize: 18, color: "#4CAF50", marginBottom: 10 },
    billTo: { marginBottom: 20 },
    table: { display: "table", width: "auto", marginBottom: 20 },
    tableRow: { flexDirection: "row", borderBottom: "1px solid #e0e0e0", paddingVertical: 5 },
    tableHeader: { fontSize: 12, fontWeight: "bold", color: "#4CAF50" },
    tableCell: { flex: 1, textAlign: "left", paddingHorizontal: 5 },
    total: { marginTop: 10, fontSize: 12, fontWeight: "bold", textAlign: "right" },
    footer: { marginTop: 40, textAlign: "center", fontSize: 10, color: "#aaa" },
  });

  const { logo, logoDark } = localData.images;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Image src={logoDark} style={styles.logo} />
          <View style={styles.companyInfo}>
            <Text>Blockchain-Ads Labs LLC</Text>
            <Text>Suite 305, Griffith Corporate Centre</Text>
            <Text>Beachmonth, Kingston, St. Vincent and the Grenadines</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Receipt</Text>
          <Text>
            Date:{" "}
            {payment.date?._seconds
              ? new Date(payment.date._seconds * 1000).toLocaleDateString()
              : "N/A"}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: "#f0f0f0" }]}>
            <Text style={[styles.tableCell, styles.tableHeader]}>Date</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Amount</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>
              {payment.date?._seconds
                ? new Date(payment.date._seconds * 1000).toLocaleDateString()
                : "N/A"}
            </Text>
            <Text style={styles.tableCell}>${payment.amount || "0.00"}</Text>
          </View>
        </View>
        <Text style={styles.total}>Total: ${payment.amount || "0.00"}</Text>
        <Text style={styles.footer}>Thank you for your payment!</Text>
      </Page>
    </Document>
  );
};

export default function BillingPage (){
  const {requestedData, isLoading } = useGlobalContext();
  
  const paymentHistory = requestedData?.userProfileData.payment_history || [];
  console.log(paymentHistory, "paymentHistory");
  
  const [loading, setLoading] = useState(false)

  const [selectedPayments, setSelectedPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredPayments, setFilteredPayments] = useState(paymentHistory);

  const { formatDate } = useFormatter();

  const handleSelect = (payment) => {
    setSelectedPayments((prev) =>
      prev.some((p) => p === payment)
        ? prev.filter((p) => p !== payment)
        : [...prev, payment]
    );
  };

  const isSelected = (payment) => selectedPayments.includes(payment);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const endDate = new Date(); // today
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 6); // 7 days ago

  const dateRange = {
    endDate: endDate,
    startDate: startDate
  }
  useEffect(() => {
    handleDateFilter(dateRange)
  }, [requestedData])


  const handleDateFilter = (range) => {
    setLoading(true);

    const { startDate, endDate } = range;
  
    // Parse and normalize dates to compare them accurately
    const normalizedStartDate = new Date(new Date(startDate).setHours(0, 0, 0, 0));
    const normalizedEndDate = new Date(new Date(endDate).setHours(23, 59, 59, 999));
  
    const filtered = paymentHistory.filter((payment) => {
      if (payment.date?._seconds) {
        const paymentDate = new Date(payment.date._seconds * 1000);
        return paymentDate >= normalizedStartDate && paymentDate <= normalizedEndDate;
      }
      return false;
    });
  
    setFilteredPayments(filtered);
    setLoading(false);
  };
  
  const displayedPayments = filteredPayments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const defaultStartDate = "2025-01-01";
  const defaultEndDate = new Date().toISOString().split("T")[0];

  return (
    <div style={{ padding: "2rem" }}>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Calendar
          className="reports-calendar"
          callback={(range) => handleDateFilter(range)}
          defaultRange={{ startDate: defaultStartDate, endDate: defaultEndDate }}
        />
        <PDFDownloadLink
          document={<PDFTemplate selectedPayments={selectedPayments} />}
          fileName="selected-payments-summary.pdf"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ background: "#1f8352" }}
            disabled={selectedPayments.length === 0}
          >
            DOWNLOAD SELECTED
          </Button>
        </PDFDownloadLink>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedPayments.length > 0 &&
                    selectedPayments.length < filteredPayments.length
                  }
                  checked={selectedPayments.length === filteredPayments.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedPayments(filteredPayments);
                    } else {
                      setSelectedPayments([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell>Issue Date</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading.userProfileIsLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : displayedPayments.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1">
                    No data available for the selected date range.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              displayedPayments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(payment)}
                      onChange={() => handleSelect(payment)}
                    />
                  </TableCell>
                  <TableCell>
                    {payment.date?._seconds
                      ? new Date(payment.date._seconds * 1000).toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>Receipt</TableCell>
                  <TableCell>${payment.amount || "0.00"}</TableCell>
                  <TableCell>
                    <PDFDownloadLink
                      document={<SinglePDFTemplate payment={payment} />}
                      fileName={`receipt-${index + 1}.pdf`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button variant="text" sx={{ color: "#1f8352" }}>
                        Download
                      </Button>
                    </PDFDownloadLink>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredPayments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};
import React, { useState, useEffect, useRef } from "react";
import localData from "../../../../localData";
import {
  Button,
  Field,
  Calendar,
  Modal,
  Checkbox,
  Loading,
} from "../../../../components";
import { useGlobalContext } from "../../../../context";
import emailjs from "@emailjs/browser";
import useConvertKit from "../../../../hooks/useConvertKit";
import { Link, useNavigate } from "react-router-dom";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useFetch from "../../../../hooks/useFetch";
import useFormatter from "../../../../hooks/useFormatter";

const endDate = new Date(); // today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
  startDate,
  endDate,
  key: "selection",
};

export default function OrderModal({ setIsOpen, packages }) {
    const { addCommas } = useFormatter();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 2))
        .toISOString()
        .slice(0, 10)
);

  const handleOnChange = (e) => {
    const { name, value } = e.target || e;
    setState({
      ...state,
      [name]:
        name === "startDate" || name === "endDate"
          ? formatDate(value)
          : name === "budget" || name === "maxCPC"
          ? (value)
          : // : name === "smartContractAddress"
            // ? checkIsBlockchainAddress(value)
            value,
    });
  };

  const handleImageUpload = (images) => {
    setUploadedImages(images);
  };

  const { close } = localData.svgs;
  const { preloaderBig } = localData.images;

  const {
    successAlert,
    errorAlert,
    requestedData,
    marketplaceState,
    getLoadUserProfile,
  } = useGlobalContext();

  console.log(requestedData, "requestedData");

  const { publications, activePublictions, setActivePublication, fetchedData } = marketplaceState;

  const [shoppingCartItems, setShoppingCartItems] = useState([]);
  useEffect(() => {
    const tempShoppingCartItems = publications.filter(
      (item) => item.isInShoppingCart && item
    );
    setShoppingCartItems(tempShoppingCartItems);
  }, [publications]);

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const { palceOrderForPR } = useFetch();

  const navigate = useNavigate();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    
    const dataPR = {
      organizationID: requestedData.userProfileData.currentOrganization.id,
      vertical: packages[0].category,
      packageIDs: packages[0].packageName === "Tech Startups - Package 1"
      ? packages[0].products.map((product) => product.id) 
      : [packages[0].id],
      packageNames: packages[0].packageName === "Tech Startups - Package 1"
      ? packages[0].products.map((product) => product.media)
      : [packages[0].packageType],
      totalPrice: parseFloat(packages[0].totalPrice),
      prTitle: state.pr_title,
      image: uploadedImages,
      document: [state.doccument],
      contactEmail: state.media_contact_email,
      companyAddress: state.company_city_country,
      websiteURL: state.press_release_url,
      startDate: startDate,
    };

    console.log(dataPR, "dataPRdataPR");
    
    try {
      
      const data = await palceOrderForPR(() => {}, dataPR);
      setIsLoading(false)
      successAlert(data.res_msg);
      navigate("/additional-products/pr");
      getLoadUserProfile();
      setActivePublication(fetchedData)
      
    } catch (err) {
      let errorMessage = err?.response?.data?.res_msg;
      errorAlert(errorMessage || "Internal Server Error");
    }
  };

  const [state, setState] = useState({
    pr_title: "",
    media_contact_email: "",
    company_city_country: "",
    press_release_url: "",
    doccument: "",
    // uploaded_image: ""
  });

  const onChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    console.log(state, " jj");
  };

  const [options, setOptions] = useState({
    // question_1_answear: "",
    question_2_answear: "",
  });

  const onClick = (e) => {
    setOptions({
      ...options,
      [e.target.name]: e.target.value,
    });
  };

  const [selectionRange, setSelectionRange] = useState(SELECTIONRANGE);

  const handleCalendar = (_selectionRange) => {
    console.log(_selectionRange);
  };

  const submitButtonRef = useRef(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState("none");

  const ConfirmationModal = ({ setIsOpen, setActiveTab }) => {
    const { conversionOptions } = useGlobalContext();
    const { close } = localData.svgs;
    const closeModal = () => {
      setIsOpen(false);
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckbox = (target) => {
      setIsChecked(target);
    };

    return (
      <>
        <div onClick={(e) => e.stopPropagation()}>
          <ConfirmationModalJoyride />
        </div>
        <div className="modal-header">
          <h2 className="modal-title">Important Notice</h2>

          <Button
            className="btn-close"
            variant="circle"
            color="dark"
            size="sm"
            icon={close}
            onClick={(e) => {
              closeModal();
            }}
            type="button"
          />
        </div>
        <div className="modal-body">
          <p className="">
            By submitting this Press Release, you acknowledge that the
            submission{" "}
            <strong>is final and cannot be edited once submitted.</strong>
          </p>
          <br />
          <p className="">
            Blockchain-Ads Distribution network does not
            <br /> provide editorial services.
          </p>
          <br />
          <br />
          <Checkbox
            callback={handleCheckbox}
            checked={isChecked}
            color="marketplace"
            label="I ensure the content is accurate and complete."
            size="sm"
          />
        </div>
        <div className="modal-footer">
          <div className="btn-group">
            <Button
              className="cancel-btn"
              name="Cancel"
              size="sm"
              variant="contained"
              color="light"
              onClick={(e) => {
                // e.preventDefault();
                closeModal();
              }}
              type="button"
            />
            <Button
              id="pr-notice-modal-tutorial-2"
              className="next-step-btn"
              name="confirm"
              size="sm"
              variant="contained"
              color="marketplace"
              // onClick={(e) => {
              //     // closeModal();
              //     // submitButtonRef.current.click();
              //     handleSubmit()
              // }}
              disabled={!isChecked}
              icon={
                isLoading ? (
                  <span className="endIcon">
                    <img src={preloaderBig} />
                  </span>
                ) : null
              }
            />
          </div>
        </div>
      </>
    );
  };

  const { balance, balancePredicted } =
    requestedData.userProfileData.currentOrganization;

  return (
    <div className="payment-component">
      {!isLoading ? (
        <>
          <div className="modal-header">
            <h2 className="modal-title">Order Submission</h2>

            <Button
              className="btn-close"
              variant="circle"
              color="dark"
              size="sm"
              icon={close}
              onClick={closeModal}
            />
          </div>

          {balancePredicted.toFixed(0) <=
            parseFloat(packages[0].totalPrice) && (
            <marquee direction="left">
              <div className="alert alert-warning">
                {alert} “Top up your account balance!”.
              </div>
            </marquee>
          )}

          <div className="modal-body">
            <br />
            <br />

            <form
              action="#/"
              method="POST"
              onSubmit={handleSubmit}
              className="form"
            >
              <h2 className="form-title display-2">Add release details</h2>
              <br />

              <p className="form-label">PR title</p>
              <Field
                type="text"
                name="pr_title"
                value={state.pr_title}
                color="secondary"
                required={true}
                size="sm"
                callback={onChange}
              />
              <p className="form-label">Doccument URL</p>
              <Field
                type="text"
                name="doccument"
                value={state.doccument}
                color="secondary"
                required={true}
                size="sm"
                callback={onChange}
              />
              <br />
              <div className="hidden-options">
                {/* <div className="order-upload-wrapper">
                            <FileUpload />
                        </div> */}
                <hr />
                <div className="order-upload-wrapper">
                  <UploadImage onUpload={handleImageUpload} />
                </div>
              </div>
              <br />

              <h2 className="form-title display-2">Company details</h2>
              <br />

              <p className="form-label">
                When do you want your release published?
              </p>
              <div className="row">
                <Field
                  type="text"
                  name="question_2_answear"
                  value="Scheduled time"
                  color="secondary"
                  size="sm"
                  callback={() => {}}
                  onClick={onClick}
                  readOnly={true}
                  className={`form-option ${
                    options.question_2_answear == "Scheduled time"
                      ? "active"
                      : ""
                  }`}
                />

                <Field
                  type="text"
                  name="question_2_answear"
                  value="Immediately"
                  color="secondary"
                  size="sm"
                  callback={() => {}}
                  onClick={onClick}
                  readOnly={true}
                  className={`form-option ${
                    options.question_2_answear == "Immediately" ? "active" : ""
                  }`}
                />
              </div>
              {options.question_2_answear == "Scheduled time" && (
                <div className="hidden-options">
                    <div className="order-upload-wrapper" style={{width: "100%", maxWidth: "250px"}}>
                    <Field
                        dataGuide="campaign-information-tutorial-7"
                        className="date"
                        type="date"
                        // label="Start Date"
                        name="startDate"
                        value={startDate}
                        color="secondary"
                        required={true}
                        size="sm"
                        // endIcon={date}
                        callback={(e) => {
                          handleCalendar(e);
                          setStartDate(e.target.value);
                        }}
                      />
                    </div>
                </div>
              )}
              

              <p className="form-label" style={{marginTop: "20px"}}>Contact email</p>
              <Field
                type="email"
                name="media_contact_email"
                value={state.media_contact_email}
                color="secondary"
                required={true}
                size="sm"
                callback={onChange}
              />
              <p className="form-label" style={{marginTop: "10px"}}>Company Address (Country and City)</p>
              <Field
                type="text"
                name="company_city_country"
                value={state.company_city_country}
                color="secondary"
                size="sm"
                callback={onChange}
              />
              <p className="form-label" style={{marginTop: "10px"}}>Website URL</p>
              <Field
                type="text"
                name="press_release_url"
                value={state.press_release_url}
                color="secondary"
                // placeholder="e.g. https://johndoe.com"
                size="sm"
                callback={onChange}
              />

              <br />
              <div style={{ display: "none" }}>
                <input
                  type="text"
                  name="from_name"
                  value={requestedData.userProfileData.userName}
                  onChange={() => {}}
                />
                <input
                  type="text"
                  name="from_email"
                  value={requestedData.userProfileData.userEmail}
                  onChange={() => {}}
                />
                <input
                  type="text"
                  name="packages_amount"
                  value={shoppingCartItems.length}
                  onChange={() => {}}
                />
                <input
                  type="text"
                  name="first_name"
                  value="john"
                  onChange={() => {}}
                />
                {shoppingCartItems.length &&
                  shoppingCartItems.map((item, index) => {
                    return (
                      <input
                        key={index}
                        type="text"
                        name={`package_id_${index + 1}`}
                        value={item.id}
                        onChange={() => {}}
                      />
                    );
                  })}

                <textarea
                  name="message"
                  value="text here"
                  onChange={() => {}}
                ></textarea>

                {/* <input
                            type="text"
                            name="options_question_1_answear"
                            value={options.question_1_answear}
                            onChange={() => {}}
                        /> */}
                <input
                  type="text"
                  name="options_question_2_answear"
                  value={options.question_2_answear}
                  onChange={() => {}}
                />
              </div>
              <div className="btn-group">
                <Button
                  name="Cancel"
                  onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                  }}
                  className="balance-btn"
                  color="dark"
                />
                <Button
                  variant="outlined"
                  name="Submit"
                  // icon={
                  //     isLoading ? (
                  //         <span className="endIcon">
                  //             <img src={preloaderBig} />
                  //         </span>
                  //     ) : null
                  // }
                  disabled={balancePredicted.toFixed(0) <= parseFloat(packages[0].totalPrice)}
                  className="balance-btn"
                  color="marketplace"
                />
              </div>
              <Modal
                Child={ConfirmationModal}
                className="pr-confirmation-modal modal-dialog-centered"
                display={showConfirmationModal}
                setDisplay={setShowConfirmationModal}
                Toggler={() => {}}
                // Toggler={() => (
                //     <Button
                //         disabled={false}
                //         className="pr-order-submit-btn"
                //         name="Submit"
                //         variant="outlined"
                //         color="marketplace"
                //         onClick={() => setShowConfirmationModal("block")}
                //     />
                // )}
              />
            </form>
          </div>
          <br />
          <br />
          <br />
          <br />

          <div className="modal-footer"></div>
        </>
      ) : (
        <Loading className="extended" />
      )}
    </div>
  );
}
const UploadImage = ({ onUpload }) => {
  const input = useRef(null);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const base64Images = [];
    const previews = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64Data = reader.result;
        base64Images.push(base64Data);
        previews.push({ name: file.name, url: base64Data });

        if (base64Images.length === files.length) {
          setImagePreviews((prev) => [...prev, ...previews]);
          onUpload((prev) => [...prev, ...base64Images]); // Pass the base64 images back to the parent
        }
      };
    });
  };

  const removeImage = (index) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    onUpload((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="order-upload-image">
      <label htmlFor="">
        <input
          type="file"
          className="file-input"
          onChange={handleFileChange}
          ref={input}
          multiple // Allow multiple file selection
          style={{ display: "none" }}
        />
        <Button
          name="Upload Image(s)"
          color="secondary"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            input.current.click();
          }}
          style={{background: "#1f8352", color: "#ffff"}}
        />
      </label>

      <div className="image-previews">
        {imagePreviews.map((image, index) => (
          <div key={index} className="image-preview">
            <img src={image.url} alt={image.name} className="preview-img" />
            <button className="remove-btn" onClick={() => removeImage(index)}>
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const FileUpload = () => {
  const inputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const { docx } = localData.images;

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setFileName(file.name);

        const reader = new FileReader();
        reader.onload = () => {
          console.log(reader.result);
        };
        reader.readAsArrayBuffer(file);
      } else {
        alert("Please upload a .docx file.");
      }
    }
  };

  return (
    <div className="order-upload-docx">
      <label htmlFor="">
        <input
          type="file"
          className="file-input"
          onChange={handleFileChange}
          ref={inputRef}
          style={{ display: "none" }}
        />
        <Button
          name="upload document"
          size="sm"
          onClick={handleClick}
          color="secondary"
        >
          Upload Document
        </Button>
      </label>
      {fileName && (
        <>
          <p>Uploaded File: {fileName}</p>
          <img src={docx} alt="" />
        </>
      )}
    </div>
  );
};

const ConfirmationModalJoyride = () => {
  // const [stepIndex, setStepIndex] = useState(0);
  // const handleJoyrideCallback = (data) => {
  //     const { status, type, index } = data;
  //     // console.log("Joyride event:", { status, type, index });

  //     if (type === ACTIONS.STEP_AFTER && index === 0) {
  //         // Skip the first step by moving to the next step
  //         console.log("Skipping the first step");
  //         setStepIndex(1); // Move to the next step (index 1)
  //     }

  //     if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //         // console.log("Tour is finished or skipped");
  //         // setRun(false); // Stop the tour
  //     }
  //     if(index == 0){

  //     }
  //     console.log(index, " index");
  // };

  const defaultSteps = [
    {
      content: (
        <h2 className="joyride-subtitle">
          Check your details before payment to ensure a smooth process.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Review Before Payment",
    },
    {
      content: (
        <h2 className="joyride-subtitle">Click here to submit payment.</h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "auto",
      target: "#pr-notice-modal-tutorial-2",
      title: "Submitting Payment",
    },
    {
      content: (
        <h2 className="joyride-subtitle">
          After successful payment, the order will be processed. The team will
          reach out to confirm delivery, which typically takes 24-72 hours.
        </h2>
      ),
      locale: { skip: <strong>Skip</strong> },
      placement: "center",
      target: "body",
      title: "Order Processing and Confirmation",
    },
  ];

  const [{ run, steps }, setSteps] = useState({
    run: false,
    steps: defaultSteps,
  });

  const handleJoyrideCallback = (data) => {
    const { status, type, index } = data;

    if (status === "ready" || status === "finished") {
      let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
      if (!guideTutorials) return;
      guideTutorials = guideTutorials.filter(
        (item) => item !== "prNoticeModalTutorial"
      );
      localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
    }
  };

  useEffect(() => {
    const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
    if (!guideTutorials || !guideTutorials.includes("prNoticeModalTutorial"))
      return;
    setTimeout(() => {
      setSteps((prev) => ({ ...prev, run: true }));
    }, 500);
  }, []);
  return (
    <Joyride
      // stepIndex={1}
      continuous
      callback={handleJoyrideCallback}
      run={run}
      steps={steps}
      // hideBackButton
      // scrollToFirstStep
      showSkipButton
      showProgress
      disableBeacon
      // disableScrolling={true}
      // disableScrollParentFix={true}
      scrollOffset={200}
      locale={{
        last: "Complete",
      }}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

import React, { useEffect, useState } from "react";
import { Navbar, Tabs, Button, Calendar } from "../../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import { Link, useNavigate } from "react-router-dom";
import useFormatter from "../../../hooks/useFormatter";
import Audiences from "./tabs/Audiences";
import Ads from "./tabs/Ads";
import Geo from "./tabs/Geo";
import { useParams } from "react-router-dom";
import StatisticsCSV from "../statistics-csv/StatisticsCSV.jsx";
import Alert from '@mui/material/Alert';

export default function Campaign({ data }) {
    const {
        campaignsState,
        // getLoadCampaignStatistics,
        getLoadCampaignAudienceStatistics,
        getLoadCampaignGeoOrAdsStatistics,
        requestedData,
        isLoading,
        timeRemaining,
        setRequestedData,
        warningAlert
    } = useGlobalContext();
    const { campaignSelectionRange, setCampaignSelectionRange, handleCalendar } = campaignsState; 
    const { fade } = useGlobalContext().animations;
    const { plus, arrowRightExtended, checkbox, arrowLeft } = localData.svgs;
    const { analysis, analysis2 } = localData.images;

    const { formatDate } = useFormatter();
    const [activeCampaign, setActiveCampaign] = useState({});
    const [activeTab, setActiveTab] = useState("");

    const defaultData = {
            startDate: "2024-12-27T16:58:07.386Z",
            endDate: "2025-01-03T16:58:07.386Z",
            key: "selection"
    }

    const params = useParams();

    useEffect(() => {
        const campaigns = requestedData.campaignData.currentOrganizationCampaigns;
        if (!campaigns.length) return;
        const campaign = campaigns.find((item) => item.id == params.id) || {};
        setActiveCampaign(campaign);
    }, [requestedData]);

    const sendRequest = () => {
        if (!activeTab) return;
        const id = parseInt(params.id);
        let groupBy = "zone_id";
        const tab = activeTab.toLowerCase();
        if (tab === "audience"){
            return getLoadCampaignAudienceStatistics(id);
        }
        if (tab === "ads") {
            getLoadCampaignGeoOrAdsStatistics(id, "ads")
        }
        if (tab === "geo") {
            getLoadCampaignGeoOrAdsStatistics(id, "geo")
        }        
    };

    useEffect(() => {
        if (!requestedData.userProfileData.currentOrganization.id) return;
        sendRequest();
    }, [activeTab, requestedData.userProfileData.currentOrganization.id]);

    const DEFAULTTABS = [
        {
            startIcon: null,
            title: "Audience",
            content: <Audiences />,
        },
        {
            startIcon: null,
            title: "Ads",
            content: <Ads />,
        },
        {
            startIcon: null,
            title: "Geo",
            content: <Geo />,
        },
    ];

    const [campaignTabs, setCampaignTabs] = useState(DEFAULTTABS);

    // useEffect(() => {
    //     const tempCampaignTabs = [
    //         {
    //             startIcon: null,
    //             title: "Zones",
    //             content: (
    //                 <Zones
    //                     data={requestedData.campaignStatistics.items}
    //                     isLoading={isLoading.campaignStatisticsLoading}
    //                 />
    //             ),
    //         },
    //         {
    //             startIcon: null,
    //             title: "Creatives",
    //             content: (
    //                 <Zones
    //                     data={requestedData.campaignStatistics.items}
    //                     isLoading={isLoading.campaignStatisticsLoading}
    //                 />
    //             ),
    //         },
    //         {
    //             startIcon: null,
    //             title: "targeting",
    //             content: (
    //                 <Zones
    //                     data={requestedData.campaignStatistics.items}
    //                     isLoading={isLoading.campaignStatisticsLoading}
    //                 />
    //             ),
    //         },
    //     ];
    //     setCampaignTabs(tempCampaignTabs);
    // }, [requestedData.campaignStatistics,isLoading.campaignStatisticsLoading]);

    return (
        <motion.main {...fade} className="main campaign-page">
            <div className="link-wrapper bg-white">
                <Link to="/campaigns">
                    <Button name="Back" size="sm" startIcon={arrowLeft} />
                </Link>
                <img src={isLoading.campaignStatisticsLoading ? analysis2 : analysis} alt="" />
            </div>
            <br />
            <br />
            <div className="row">
                <h1 className="campaign-title display-2">{activeCampaign.name}</h1>
                <div
                    className={`status ${
                        activeCampaign.status === "Paused · Daily Budget"
                            ? "paused-daily-budget"
                            : activeCampaign.status?.toLowerCase()
                    }`}
                >
                    {/* {activeCampaign.status || "unknown"} */}
                    {(activeCampaign.status == "Paused · Daily Budget" ? "working" : activeCampaign.status) ||
                        "unknown"}
                </div>
            </div>
            <br />
            <Alert variant="outlined" severity="info">
            Detailed Statistics are available from the 27.12.2024
            </Alert>
            <br />
            <div className={`right-bar-wrapper `}>
                {/* ${isLoading.campaignStatisticsLoading || timeRemaining > 0 ? "on-hold" : ""} */}
                <StatisticsCSV />
                <Calendar
                    className="reports-calendar"
                    _selectionRange={
                        new Date(campaignSelectionRange?.startDate) < new Date("2024-12-27T00:00:00.000Z")
                            ? defaultData
                            : campaignSelectionRange
                    }
                    callback={(range) => {
                        handleCalendar(range);
                        sendRequest();
                        
                    }}
                    disabled={isLoading.campaignStatisticsLoading}
                    isMinDate={true}
                />
            </div>
            <br />
            <Tabs
                tabs={campaignTabs}
                className="campaign-tabs"
                callback={(tab) => {
                    setActiveTab(tab);
                    // setIsLoading((prev) => ({ ...prev, blockchainDataIsLoading: true }));
                    // setActiveNetwork(activeNetwork);
                }}
                isLoading={isLoading.campaignStatisticsLoading || timeRemaining > 0}
                loadingMessage={
                    !isLoading.campaignStatisticsLoading && `Time left ${timeRemaining}s until next request`
                }
                tabsProps={{
                    activeTab,
                }}
            />
            {/* <h4>We're working hard to bring you the best possible user experience on this page. Stay tuned!</h4> */}
        </motion.main>
    );
}